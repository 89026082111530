import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { TimeoutNotifyModalComponent } from './dialog-modals/timeout-notify-modal/timeout-notify-modal.component';
import { MatDialog } from '@angular/material/dialog';
// import { clearTimeout, setTimeout } from 'worker-timers';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isIframe = false;
  loggedIn = false;
  timeC = null;

  lastPing?: Date = null;

  constructor(private authService: MsalService, public dialog: MatDialog, private idle: Idle, private keepalive: Keepalive) {}

  ngOnInit() {
    var that = this;
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0 
    if ( this.loggedIn ) { //Checks whether user is logged in or not
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount) {
        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
      }
    } else {
      this.authService.loginRedirect()
    }

    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.displayNotifyModal();
    });

    // sets an idle timeout of 28 minutes (1680 seconds)
    this.idle.setIdle(1675);
    // sets a timeout period of 5 seconds. after 30 minutes of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

  }

  reset() {
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.watch();
    // sets an idle timeout of 28 minutes (1680 seconds)
    this.idle.setIdle(1675);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
  }


  logout() {
    this.authService.logout();
  }

  displayNotifyModal() {

    const dialogRef = this.dialog.open(TimeoutNotifyModalComponent, {
      width: "500px",
      disableClose: true,
      data: {message: ''},
      panelClass: 'popupModal',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(action => {
      if (!action) {
        this.logout();
      }
      else {
        this.reset();
      }
    });
  }
}
