<div style="position: relative;">
    <app-loading-spinner *ngIf="loadSpinner"></app-loading-spinner>
    <button mat-icon-button class="modal-close-button" style="position: absolute;" (click)="close()">
        <mat-icon>close</mat-icon>
    </button> 

    <div (drop)="dropHandler($event);" (dragover)="dragOverHandler($event);" style="display: flex; align-items: center; flex-direction: column; font: normal normal bold 20px/30px Roboto;">
        <div [formGroup]="form" style="width: 75%; display: flex; align-items: center; flex-direction: column;">
            <mat-icon style="color: var(--accent-menuButtonInactive); font-size: 100px; width: 100px; height: 100px">cloud_upload</mat-icon>
            <div>
                <div style="padding-bottom: 20px;">DRAG AND DROP DENIAL (PDF) HERE</div>
            </div>
            <div style="padding-bottom: 41px">or</div>
            <div *ngIf="showError" style="color: red;">{{errorMessage}}</div>
            <div style="display: flex; align-items: center; padding-bottom: 17px;">
                <mat-form-field class="form-field-no-bottom pdf-modal-file-input" style="width: 371px; font-size: 11px;">
                    <input #fileInput matInput disabled=true [value]="this.fileToUpload?.name">
                </mat-form-field>
                <input #fileInputHidden type="file" hidden="true" (change)="change($event)" accept=".pdf">
                <button mat-raised-button color="accent" style="width: 150px" (click)="fileInputHidden.click()" [disabled]="this.missing1500">Select File</button>
            </div>

            <div style="padding-bottom: 17px;">
                <mat-radio-group formControlName="bundleRiskOption" class="era-type-radio-group">
                    <mat-radio-button value=1>Bundle at Risk</mat-radio-button>
                    <mat-radio-button value=2>Bundle not at Risk</mat-radio-button>
                </mat-radio-group>
            </div>
            <div>
                <div style="display: flex; align-items: center; padding-bottom: 17px;">
                    <span style="padding-right:20px">Note</span>
                    <mat-form-field class="form-field-no-bottom pdf-modal-file-input" style="width: 371px; font-size: 11px;">
                        <mat-label><i>Type note here (optional)</i></mat-label>
                        <input matInput formControlName="note" maxlength="30"/>
                    </mat-form-field>
                    
                </div>
            </div>


            <div style="display: flex; width: 400px; margin-top: 60px; margin-bottom: 11px; justify-content: space-between;">
                <button mat-button style="width: 122px" (click)="close()">Cancel</button>
                <button mat-raised-button [disabled]="this.missing1500 || !this.fileToUpload || this.fileToUpload.name == '' || this.form.get('bundleRiskOption')?.value == null || !form.valid" color="accent" style="width: 122px" (click)="submit()">Submit</button>
            </div>

            <div style="width: 100%;">
                <div>Selected Episodes</div>
                <div style="max-height: 130px; overflow-y: auto; display: flex; flex-wrap: wrap; flex-direction: column;">
                    <div *ngFor="let episode of selectedEpisodes;" [ngStyle]="{'color': !episode.has1500 ? 'red' : 'auto'}" style="font: normal normal normal 14px/22px Roboto; padding-right: 25px;">
                        - {{episode.EpisodeMaster_ID}}: {{episode.SurgeonLastName}} - {{episode.PatLastName}} {{!episode.has1500 ? ' | Has no claim' : ''}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>