import { EpisodeNote } from "./EpisodeNote"


export interface FileManagerDTO {
    count: number,
    fileManagerRows: FileManagerRow[]
}

export interface FileManagerRow {
    Id: number,
    UploadDate: Date,
    FileType: number,
    Amount: number
    CheckNumber: string,
    PayerOrder_ID: number,
    PatientIDs: string,
    AssociatedEpisode: string,
}

export enum FileManagerFileTypeEnum {
    Claim = 0,
    ACH = 10,
    ERA = 20,
    Denial = 30,
    Corrected_Claim = 1,
    OP_Note = 2,
    Secondary_Insurance = 3,
    Other = 4,
    Secondary_Claim = 40,
    Zero_Dollar_Remit = 45
}