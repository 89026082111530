<div class="position-relative">
    <div>
        <button mat-icon-button class="modal-close-button close-icon" (click)="close(false)">
            <mat-icon>close</mat-icon>
        </button> 
    </div>
    <div>
        <div>
            <h2 mat-dialog-title style="margin-top: 0px;">
                File Delete Confirmation
            </h2>
        </div>
        <div>
            Are you sure you want to delete this file?
        </div>

        <div class="option-buttons">
            <button mat-raised-button class="width-122px" color="accent" (click)="close(true)">Confirm</button>
            <button mat-raised-button class="width-122px margin-left-10px" (click)="close(false)">Cancel</button>
        </div>
         
    </div>
</div>