import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AddAchModalComponent } from './add-ach-modal/add-ach-modal.component';
import { translatePayerOrderToText } from 'src/app/json-mappings/payerOrder';
import { debounce } from 'ts-debounce';
import { SanitizeMedicareIdInput } from '../utility/medicareId';
import { Practice } from '../NewDomain/Practice';
import { ApiService } from '../api.service';
import { Subscription } from 'rxjs';
import { FileManagerDataSource } from './FileManagerDataSource';
import { FileManagerERASelectionModel } from './FileManagerERASelectionModel';
import { UserAD } from '../NewDomain/UserAD';
import { FileManagerQuery } from './FileManagerQuery';
import { FileManagerFileTypeEnum, FileManagerRow } from '../NewDomain/FileManagerRow';
import { ExportDataWarningModalComponent } from '../dialog-modals/export-data-warning-modal/export-data-warning-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

export const filesRoute = 'files'

@Component({
  selector: 'app-files-page',
  templateUrl: './files-page.component.html',
  styleUrls: ['./files-page.component.css']
})
export class FilesPageComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<FileManagerRow>;
  @ViewChild("fileListWindow") fileListWindow!: ElementRef;

  practices: Practice[] = [];
  dataSource = new FileManagerDataSource(this.api);
  loadSpinner = 0
  displayedColumns: string[] = ['select', 'FileType', 'UploadDate', 'Amount', 'CheckNumber', 'PayerOrder', 'PatientIDs', 'AssociatedEpisode'];
  errorMessage = ""
  patientIdValue:string = ""
  fileManagerRowSubscription: Subscription|undefined = undefined
  fileManagerERASelectionModel:FileManagerERASelectionModel = new FileManagerERASelectionModel()
  user:UserAD = new UserAD()
  fileManagerQuery:FileManagerQuery = {}
  routerSubscription:Subscription|undefined = undefined

  public get FileManagerFileTypeEnum() {
    return FileManagerFileTypeEnum; 
  }

  get isValidator(): boolean{
    return this.user.ValidatorFlag;
  }

  constructor(private api:ApiService, public dialog: MatDialog, public activeRouter:ActivatedRoute, private ref: ChangeDetectorRef, private router: Router,
     private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.loadSpinner++
    this.api.getPractices(
      (res) => {
        this.practices = res
        this.loadSpinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.loadSpinner--
    })

    this.loadSpinner++;

    this.api.getUserADSelf()
    .subscribe({
      next: user => {
        this.user = user;
        this.loadSpinner--;
      },
      error: err => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      }
    });

    this.fileManagerRowSubscription = this.dataSource.fileManagerRowSubject.subscribe(
      (fileManagerRows:FileManagerRow[]) => {
        //Need to update the selection model because the newly retrieved values may be different than what's currently stored in the selection model
        this.fileManagerERASelectionModel.updateSelectionModelWithFileManagerRows(fileManagerRows)
      }
    )
  }

  loadFileManagerRows(scrollToTop:boolean) {
    if (scrollToTop)
      this.fileListWindow.nativeElement.scrollTop = 0 //Scroll list back to top
      
    this.fileManagerQuery.limit = this.paginator.pageSize
    this.fileManagerQuery.offset = this.paginator.pageIndex * this.paginator.pageSize
    this.dataSource.loadFileManagerRows(this.fileManagerQuery)
  }

  ngOnDestroy() {
    this.fileManagerRowSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.loadFileManagerRows(true)

    this.routerSubscription = this.router.events.subscribe((event:any) => {
      //If user hits browser back button (popstate) or if user uses the back button on the angular pages, which redirects it directly back to this page
      if (event instanceof NavigationStart && event.url == '/' + filesRoute) {
        this.loadFileManagerRows(false)
      }
    });
  }

  openAddACHModal(){
    
    if (this.fileManagerERASelectionModel.hasAnySelected()) { 
      const dialogRef = this.dialog.open(AddAchModalComponent, {
        width: "864px",
        disableClose: true,
        data: this.fileManagerERASelectionModel.getSelected(),
        panelClass: 'popupModal',
        autoFocus: false
      });

      //Reload the data after ACH has been successfully created
      let sub = dialogRef.afterClosed().subscribe( achCreated => {
        if (achCreated) {
          this.fileManagerERASelectionModel.clear()
          this.loadFileManagerRows(true)
        }
      })
    }
  }

  getViewerRoute(fileManagerRow:FileManagerRow) {
    switch(fileManagerRow.FileType) {
      case FileManagerFileTypeEnum.ACH:
        return './ERAViewerForACH/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.Claim:
        return './validateClaim/' + fileManagerRow.AssociatedEpisode;
      case FileManagerFileTypeEnum.ERA:
        return './ERAViewerForERA/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.Denial:
        return './ERAViewerForDenial/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.Corrected_Claim:
        return './ViewerForAdditionalDoc/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.OP_Note:
        return './ViewerForAdditionalDoc/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.Secondary_Insurance:
        return './ViewerForAdditionalDoc/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.Other:
        return './ViewerForAdditionalDoc/' + fileManagerRow.Id;
      case FileManagerFileTypeEnum.Secondary_Claim:
        return './ViewerForSecondaryClaim/'+ fileManagerRow.AssociatedEpisode;
      case FileManagerFileTypeEnum.Zero_Dollar_Remit:
        return './ZeroDollarRemitFileViewer/'+ fileManagerRow.AssociatedEpisode;
      default:
        throw 'File type unknown';
    }
  }

  convertFileTypeEnumToText(fileType:number) {
    switch(fileType) {
      case FileManagerFileTypeEnum.ACH:
        return 'ACH';
      case FileManagerFileTypeEnum.Claim:
        return '1500';
      case FileManagerFileTypeEnum.ERA:
        return 'ERA';
      case FileManagerFileTypeEnum.Denial:
        return 'Denial';
      case FileManagerFileTypeEnum.Corrected_Claim:
        return 'Corrected_Claim';
      case FileManagerFileTypeEnum.OP_Note:
        return 'OP_Note';
      case FileManagerFileTypeEnum.Secondary_Insurance:
        return 'Secondary_Insurance';
      case FileManagerFileTypeEnum.Other:
        return 'Other';
      case FileManagerFileTypeEnum.Secondary_Claim:
        return 'Secondary_Claim';
      case FileManagerFileTypeEnum.Zero_Dollar_Remit:
        return 'Zero_Dollar_Remit';
      default:
        throw 'File type unknown';
    }
  }

  updatefilter() {
    this.paginator.firstPage()
    this.loadFileManagerRows(true)
  }

  practiceFilterChange(value:number) {
    this.fileManagerQuery.practiceID = value;
    this.fileManagerERASelectionModel.clear();
    this.updatefilter();
  }

  fileTypeFilterChange(value:number) {
    this.fileManagerQuery.fileType = value;
    this.updatefilter();
  }

  uploadStartDateFilterChange(value:Date) {
    this.fileManagerQuery.uploadStartDate = value;
    this.updatefilter();
  }

  uploadEndDateFilterChange(value:Date) {
    this.fileManagerQuery.uploadEndDate = value;
    this.updatefilter();
  }

  amountFilterChange = debounce((event:any) => {
    this.fileManagerQuery.amount = event.target.value;
    this.updatefilter()
  }, 350)

  checkNumberFilterChange = debounce((event:any) => {
    this.fileManagerQuery.checkNumber = event.target.value;
    this.updatefilter()
  }, 350)

  patientIdFilterDebounced = debounce(() => {
    this.fileManagerQuery.medicareID = this.patientIdValue;
    this.updatefilter()
  }, 350)

  patientIdFilterChange(event:any) {
    //First have to push the unaltered change to the view, otherwise after sanitization, if we end up the same value entered, then change detection won't catch it and perform the update
    this.patientIdValue = event.target.value
    this.ref.detectChanges()

    this.patientIdValue = SanitizeMedicareIdInput( event.target.value );
    this.patientIdFilterDebounced()
  }

  episodeIdFilterChange = debounce((event:any) => {
    this.fileManagerQuery.episodeID = event.target.value;
    this.updatefilter()
  }, 300)

  translatePayerOrderToText(payerOrder:number) {
    return translatePayerOrderToText(payerOrder);
  }

  sortChange(event:any) {

    //If direction is set to nothing, that means that the tab is no longer being ordered (for example, if you click once it ascends, twice it descends, and third it resets and is no longer ordered)
    if (event.direction == '') {
      this.fileManagerQuery.orderBy = undefined
      this.fileManagerQuery.sortDesc = undefined
    }
    else {
      this.fileManagerQuery.orderBy = event.active
      this.fileManagerQuery.sortDesc = event.direction == 'desc'
    }

    this.loadFileManagerRows(true)
  }

  exportFilesDisplayedData() {
    const dialogRef = this.dialog.open(ExportDataWarningModalComponent, {
      width: "500px",
      disableClose: true,
      panelClass: 'popupModal',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.callFilesExportDataApi();
      }
    });
  }

  callFilesExportDataApi() {
    this.loadSpinner++;
    let exportDataQuery = this.fileManagerQuery;
    exportDataQuery.limit = this.dataSource.totalCount.value;
    exportDataQuery.offset = this.paginator.pageIndex * this.paginator.pageSize;
    this.api.getFileManagerExportData((res) => {
      this.loadSpinner--;
      this.fileManagerQuery.limit = this.paginator.pageSize;
      let utcDate = new Date();
      let currDateTime = moment(utcDate).format("MMDDyyyyHHmmss");
      var filename = 'ExportFilesERAData' + currDateTime + '.xlsx';
      const blob = new Blob([res], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }, (err) => {
      this.loadSpinner--;
      this.fileManagerQuery.limit = this.paginator.pageSize;
      this.snackBar.open("File did not downloaded", "Dismiss", {duration: 3000});
      console.log(err);
    }, exportDataQuery);
  }

}
