import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog';
import { AddPdfModalComponent } from './add-pdf-modal/add-pdf-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { EpisodeStatusIDs } from './EpisodeStatuses'
import { AddEraModalComponent } from './add-era-modal/add-era-modal.component';
import { Subscription } from 'rxjs';
import { debounce } from 'ts-debounce';
import { SanitizeMedicareIdInput } from '../utility/medicareId';
import { EpisodeLineSummaryDataSource } from './EpisodeLineSummaryDataSource';
import { EpisodeLineSummary } from '../NewDomain/episodeLineSummary';
import { EpisodeLineSummaryQuery } from './EpisodeLineSummaryQuery';
import { EpisodeLineSummarySelectionModel } from './EpisodeLineSummarySelectionModel';
import { ApiService as ApiService } from '../api.service';
import { UserAD } from '../NewDomain/UserAD';
import { Practice } from '../NewDomain/Practice';
import { Bundle } from '../NewDomain/Bundle';
import { EpisodeNote } from '../NewDomain/EpisodeNote';
import { EpisodeStatus } from '../NewDomain/EpisodeStatus';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Episode } from 'src/app/NewDomain/Episode';
import { ValidationResult } from 'src/app/NewDomain/ValidationResult';
import { compare } from 'fast-json-patch';
import { AddDenialModalComponent } from './add-denial-modal/add-denial-modal.component';
import { AddAdditionalDocModalComponent } from '../dialog-modals/add-additional-doc-modal/add-additional-doc-modal.component';
import { AdditionalDocListComponent } from '../dialog-modals/additional-doc-list-modal/additional-doc-list-modal.component';
import { NotesDeletedDocsComponent } from '../dialog-modals/notes-deleted-docs/notes-deleted-docs.component';
import { AppService } from '../app-service';
import { AddSecondaryClaimFileModalComponent } from '../dialog-modals/add-secondary-claim-file-modal/add-secondary-claim-file-modal.component';

import { ExportDataWarningModalComponent } from '../dialog-modals/export-data-warning-modal/export-data-warning-modal.component';
import * as moment from 'moment';

export interface EpisodeData {
  id: number;
  patientName: string;
  ptPayorId: string;
  surgeon: string;
  surgeryDate: Date;
  status: string;
  bundle: string;
  practice: string;
  has1500: boolean;
  hasACH: boolean;
  hasNotes: boolean;
  showNote: boolean;
  notes: EpisodeNote[]
}

export const patientEpisodeManagerRoute = 'patientEpisodeManager'

@Component({
  selector: 'app-patient-episode-manager-page',
  templateUrl: './patient-episode-manager-page.component.html',
  styleUrls: ['./patient-episode-manager-page.component.css']
})
export class PatientEpisodeManagerPageComponent implements OnInit {

  //This will keep track of which line summaries the user has selected (check box next to each line)
  lineSummarySelectionModel:EpisodeLineSummarySelectionModel = new EpisodeLineSummarySelectionModel()
  spinner = 0;
  episodeLineSummarySubscription: Subscription|undefined = undefined

  private static debounceTime:number = 350

  get validator():boolean{
    return this.user.ValidatorFlag;
  }
  
  practiceFilter:string|undefined = undefined
  physicianFilter:string|undefined = undefined
  patientIdFilter:string|undefined = undefined
  bundleFilter:number|undefined = undefined
  statusFilter:string|undefined = undefined
  episodeIdFilter:string|undefined = undefined
  patientNameFilter:string|undefined = undefined
  surgeryDateBegin:Date|undefined = undefined
  surgeryDateEnd:Date|undefined = undefined
  patientIdValue:string = ""
  errorMessage:string = ""
  sortFieldFilter:string|undefined = undefined
  sortDescFilter:boolean|undefined = undefined
  lineQuery:EpisodeLineSummaryQuery = {}
  user:UserAD = new UserAD()
  practices:Practice[] = []
  bundles:Bundle[] = []
  episodeStatuses:EpisodeStatus[] = []
  bundleIDToBundleMap: Map<number, Bundle> = new Map<number, Bundle>()
  episodeStatusIDToEpisodeStatusMap: Map<number, EpisodeStatus> = new Map<number, EpisodeStatus>()
  practiceIDToPracticeMap: Map<number, Practice> = new Map<number, Practice>()
  routerSubscription:Subscription|undefined = undefined
  loadSpinner = 0
  reviewStatus = [{review: 1, reviewName: 'Reviewed'}, {review: 0, reviewName: 'Not Reviewed'}];
  sharedData: any;
  practiceIdFromDashboard: any;


  public episodeStatusIDs = EpisodeStatusIDs; //Have to do this in order to access static variables in the html template (statusColorMap for example)

  displayedColumns: string[] = ['select', 'id', 'patientName', 'ptPayorId', 'surgeon', 'surgeryDate', 'status', 'files', 'denial files', 'additional doc', 'secondary doc', 'bundle'];

  dataSource = new EpisodeLineSummaryDataSource(this.api);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<EpisodeData>;
  @ViewChild("episodeListWindow") episodeListWindow!: ElementRef;

  constructor(private api:ApiService, public dialog: MatDialog, private domSanitizer:DomSanitizer, public activeRouter:ActivatedRoute,
              private ref: ChangeDetectorRef, private router: Router, private snackBar: MatSnackBar, private appService: AppService) {}

  ngOnInit(): void {

    this.appService.currentData.subscribe(data => {
      if((data !== undefined && data !== null) || Object.keys(data).length !== 0) {
        this.sharedData = data;
        if (this.sharedData.practiceId) {
          this.lineQuery.practiceID = this.sharedData.practiceId;
          this.practiceIdFromDashboard = this.sharedData.practiceId;
        }
        if (this.sharedData.statusId === 4 || this.sharedData.statusId === 12 || this.sharedData.statusId === 5 ||
            this.sharedData.statusId === 11 || this.sharedData.statusId === 10) {
          this.statusChange(this.sharedData.statusId);
        }
        else if (this.sharedData.statusId === 6 || this.sharedData.statusId === 2 || this.sharedData.statusId === 1) {
          // last 28 days
          let last28Date = new Date();
          last28Date.setDate(last28Date.getDate() - 28);
          this.sharedData.last28Days = last28Date;
          this.sharedData.todayDate = new Date();
          this.lineQuery.statusID = this.sharedData.statusId;
          this.lineQuery.surgeryStartDate = last28Date;
          this.lineQuery.surgeryEndDate = this.sharedData.todayDate;
          this.lineQuery.sortDesc = true;
          this.updatefilter();
        }
      }
      
    });
    this.spinner++
    this.api.getBundles(
      (res) => {
        this.bundles = res

        this.bundleIDToBundleMap = new Map<number, Bundle>()

        for (let i = 0; i < this.bundles.length; ++i)
          this.bundleIDToBundleMap.set(this.bundles[i].Bundle_ID!, this.bundles[i])

        this.spinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.spinner--
    })

    this.spinner++
    this.api.getEpisodeStatuses(
      (res) => {
        this.episodeStatuses = res;
        this.episodeStatusIDToEpisodeStatusMap = new Map<number, EpisodeStatus>()

        for (let i = 0; i < this.episodeStatuses.length; ++i)
          this.episodeStatusIDToEpisodeStatusMap.set(this.episodeStatuses[i].EpisodeStatus_ID!, this.episodeStatuses[i])

        this.spinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.spinner--
    })

    this.spinner++
    this.api.getPractices(
      (res) => {
        this.practices = res

        this.practiceIDToPracticeMap = new Map<number, Practice>()

        for (let i = 0; i < this.practices.length; ++i)
          this.practiceIDToPracticeMap.set(this.practices[i].Practice_ID!, this.practices[i])

        this.spinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.spinner--
    })

    this.spinner++;

    this.api.getUserADSelf()
    .subscribe({
      next: user => {
        this.user = user;
        this.spinner--;
      },
      error: err => {
        this.errorMessage = err.message;
        this.spinner--;
      }
    });
    

    this.episodeLineSummarySubscription = this.dataSource.episodeLinesSubject.subscribe(
      (lineSummaries:EpisodeLineSummary[]) => {
        //Need to update the line selection model because the newly retrieved values may be different than what's currently stored in the selection model
        this.lineSummarySelectionModel.updateSelectionModelWithLineSummaries(lineSummaries)
      }
    )
  }

  ngOnDestroy() {
    this.episodeLineSummarySubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.sharedData = {};
    this.practiceIdFromDashboard = undefined;
    this.appService.setNewData(this.sharedData);
  }

  ngAfterViewInit() {
    this.loadEpisodeLineSummaries(true)
    this.routerSubscription = this.router.events.subscribe((event:any) => {
                              //If user hits browser back button (popstate) or if user uses the back button on the angular pages, which redirects it directly back to this page
                              if (event instanceof NavigationStart && event.url == '/' + patientEpisodeManagerRoute) {
                                this.loadEpisodeLineSummaries(false)
                              }
                            });
  }

  sortChange(event:any) {

    //If direction is set to nothing, that means that the tab is no longer being ordered (for example, if you click once it ascends, twice it descends, and third it resets and is no longer ordered)
    if (event.direction == '') {
      this.lineQuery.orderBy = undefined
      this.lineQuery.sortDesc = undefined
    }
    else {
      this.lineQuery.orderBy = event.active
      this.lineQuery.sortDesc = event.direction == 'desc'
    }

    this.loadEpisodeLineSummaries(true)
  }

  loadEpisodeLineSummaries(scrollToTop:boolean) {
    if (scrollToTop)
      this.episodeListWindow.nativeElement.scrollTop = 0 //Scroll list back to top

    this.lineQuery.limit = this.paginator.pageSize
    this.lineQuery.offset = this.paginator.pageIndex * this.paginator.pageSize
    this.dataSource.loadEpisodeLineSummaries(this.lineQuery)
  }

  practiceChange(value:string) {
    this.lineQuery.practiceID = value;
    this.updatefilter();
  }

  physicianFilterChange = debounce((event:any) => {
    this.lineQuery.physicianID = event.target.value;
    this.updatefilter()
  }, PatientEpisodeManagerPageComponent.debounceTime)

  statusChange(value:number) {
    this.lineQuery.statusID = value;
    this.updatefilter();
  }

  surgeryBeginChange(value:Date) {
    this.lineQuery.surgeryStartDate = value;
    this.updatefilter();
  }

  surgeryEndChange(value:Date) {
    this.lineQuery.surgeryEndDate = value;
    this.updatefilter();
  }

  bundleFilterChange(value:number) {
    this.lineQuery.bundleID = value;
    this.updatefilter();
  }

  episodeIdFilterChange = debounce((event:any) => {
    this.lineQuery.episodeID = event.target.value;
    this.updatefilter()
  }, PatientEpisodeManagerPageComponent.debounceTime)

  patientNameFilterChange = debounce((event:any) => {
    this.lineQuery.patientName = event.target.value;
    this.updatefilter()
  }, PatientEpisodeManagerPageComponent.debounceTime)

  patientIdFilterDebounced = debounce(() => {
    this.lineQuery.medicareID = this.patientIdValue;
    this.updatefilter()
  }, PatientEpisodeManagerPageComponent.debounceTime)

  patientIdFilterChange(event:any) {
    //First have to push the unaltered change to the view, otherwise after sanitization, if we end up the same value entered, then change detection won't catch it and perform the update
    this.patientIdValue = event.target.value
    this.ref.detectChanges()
    
    this.patientIdValue = SanitizeMedicareIdInput( event.target.value );
    this.patientIdFilterDebounced()
  }

  updatefilter() {
    this.paginator.firstPage()
    this.loadEpisodeLineSummaries(true)
  }

  openAddPDFModal(data:any){
    const dialogRef = this.dialog.open(AddPdfModalComponent, {
      width: "864px",
      disableClose: true,
      data: data,
      panelClass: 'popupModal',
      autoFocus: false
    });

    //Reload the data after ACH has been successfully created
    dialogRef.afterClosed().subscribe( claimUploaded => {
      if (claimUploaded) {
        this.loadEpisodeLineSummaries(false)
      }
    })
  }

  openAddERAModal(){
    if (this.lineSummarySelectionModel.hasAnySelected()) { 

      const dialogRef = this.dialog.open(AddEraModalComponent, {
        width: "864px",
        disableClose: true,
        data: this.lineSummarySelectionModel.getSelected(),
        panelClass: 'popupModal',
        autoFocus: false
      });

      //Reload the data after ACH has been successfully created
      dialogRef.afterClosed().subscribe( eraUploaded => {
        if (eraUploaded) {
          this.loadEpisodeLineSummaries(false)
        }
      })
    }
  }

  openNotes(episode:EpisodeLineSummary) {
    if (episode.notes == null) {
      this.api.getEpisodeNotes(
      (res:EpisodeNote[]) => {
        episode.notes = res;
        }, 
      (err:any) => { 
      }, episode.EpisodeMaster_ID)
    }
  }

  mapBundleIDToBundle(id:number): Bundle|undefined {
    return this.bundleIDToBundleMap.get(id)
  }

  mapEpisodeStatusIDToStatus(id:number): EpisodeStatus|undefined {
    return this.episodeStatusIDToEpisodeStatusMap.get(id)
  }

  mapPracticeIDToPractice(id:number): Practice|undefined {
    return this.practiceIDToPracticeMap.get(id)
  }

  uploadToKareo(episodeDetails) {
    this.loadSpinner++
    this.api.postPatientToKareo(episodeDetails.EpisodeMaster_ID, 
      (res: any) => {
        console.log(res);
        this.snackBar.open("File successfully updated to Kareo", "Dismiss", {duration: 3000});
        this.loadSpinner--;
      },
      (err) => {
        console.log(err);
        this.snackBar.open("File could not be uploaded to Kareo, Try again later.", "Dismiss");

        let blankEpisode:Episode = new Episode();
        let compareEpisode: Episode = new Episode();
        compareEpisode.Status_ID = 5
        let patch = compare(blankEpisode, compareEpisode);
        this.api.patchEpisode(
          (resp: ValidationResult) => {
            this.snackBar.open("Status successfully updated!", "Dismiss", {duration: 3000});
            this.loadSpinner--;
          },
          (err) => {
            this.snackBar.open("Status could not be updated due to error.", "Dismiss");
            this.loadSpinner--;
          }, episodeDetails.EpisodeMaster_ID, patch)
      });
  }

  openAddDenialModal() {
    if (this.lineSummarySelectionModel.hasAnySelected()) { 
      const dialogRef = this.dialog.open(AddDenialModalComponent, {
        width: "864px",
        disableClose: true,
        data: {selectedEpisode: this.lineSummarySelectionModel.getSelected(), userDetails: this.user},
        panelClass: 'popupModal',
        autoFocus: false
      });

      //Reload the data after Denial has been successfully created
      dialogRef.afterClosed().subscribe( denialUploaded => {
        
        if (denialUploaded) {
          let selectedEpisodes = this.lineSummarySelectionModel.getSelected();
          for (let selEpi of selectedEpisodes) {
            this.lineSummarySelectionModel.deselectLineSummary(selEpi);
              let blankEpisode:Episode = new Episode();
              let compareEpisode: Episode = new Episode();
              compareEpisode.Status_ID = EpisodeStatusIDs.denied;
              let patch = compare(blankEpisode, compareEpisode)
              this.api.patchEpisode((res) => {
                this.loadEpisodeLineSummaries(false);
              }, (err) => {
                this.errorMessage = err.message;
                this.loadEpisodeLineSummaries(false);
              }, selEpi.EpisodeMaster_ID, patch);
          }
        }
      });
    }
  }

  openAddAdditionalDocModal(episode) {
    const dialogRef = this.dialog.open(AddAdditionalDocModalComponent, {
      width: "864px",
      disableClose: true,
      data: {episodeDetails: episode, userDetails: this.user},
      panelClass: 'popupModal',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadEpisodeLineSummaries(false);
      }
    });
  }

  openFile(episode) {
    this.api.getAdditionalDocumentsForEpisodeId(episode.EpisodeMaster_ID, (res: any) => {
      if(res.length == 1) {
        // open viewer directly
        this.router.navigate(['ViewerForAdditionalDoc', res[0].AddDoc_Id], {relativeTo: this.activeRouter});
      }
      else if (res.length > 1) {
        // doc list
        const dialogRef = this.dialog.open(AdditionalDocListComponent, {
          width: "500px",
          disableClose: true,
          data: {episodeDetails: episode, userDetails: this.user, additionalDOcList: res},
          panelClass: 'popupModal',
          autoFocus: false
        });
    
        dialogRef.afterClosed().subscribe((addDocId) => {
          this.router.navigate(['ViewerForAdditionalDoc', addDocId], {relativeTo: this.activeRouter});
        });
      }
      else {
        this.snackBar.open("No files found for this episode", "Dismiss", {duration: 3000});
      }
    },
    (err: any) => {
      console.log(err);
    });

  }

  reviewStatusChange(value) {
    this.lineQuery.reviewed = value;
    this.updatefilter();
  }

  showNotesModal(episode) {
    
    const dialogRef = this.dialog.open(NotesDeletedDocsComponent, {
      width: "500px",
      disableClose: true,
      data: {episode: episode},
      panelClass: 'popupModal',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  reviewStatusChangeForDeletedFile(value) {
    console.log(value);
    this.lineQuery.deletedFileReviewed = value;
    this.updatefilter();
  }

  checkStatusIdForAdditionDoc(id) {
    if (id == 11 || id == 3 || id == 10 || id == 4 || id == 9) {
      return true;
    }
    return false;
  }

  checkStatusIdForSecondaryClaim(id) {
    if(id == 5 || id == 6 ||id == 12) {
      return true;
    }
    return false;
  }

  openAddSecondaryClaimModal(episode) {
    const dialogRef = this.dialog.open(AddSecondaryClaimFileModalComponent, {
      width: "864px",
      disableClose: true,
      data: {episodeDetails: episode, userDetails: this.user},
      panelClass: 'popupModal',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadEpisodeLineSummaries(false);
      }
    });
  }

  exportValidationData() {
    const dialogRef = this.dialog.open(ExportDataWarningModalComponent, {
      width: "500px",
      disableClose: true,
      panelClass: 'popupModal',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.callExportValidationDataApi();
      }
    });
  }

  openSecondaryClaimFileViewer(episode) {
    this.router.navigate(['ViewerForSecondaryClaim', episode.EpisodeMaster_ID], {relativeTo: this.activeRouter});
  }

  reviewStatusForSecondaryClaimFiles(value) {
    this.lineQuery.secondaryClaimFileReviewed = value;
    this.updatefilter();
  }

  callExportValidationDataApi() {
    this.spinner++;
    this.api.getEpisodeExportData((res: any) => {
      this.spinner--;
      let utcDate = new Date();
      let currDateTime = moment(utcDate).format("MMDDyyyyHHmmss");
      var filename = 'ExportValidationData' + currDateTime + '.xlsx';
      const blob = new Blob([res], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();      
    },
    (err) => {
      this.spinner--;
      this.snackBar.open("File did not downloaded", "Dismiss", {duration: 3000});
      console.log(err);
    }, this.lineQuery);
  }

  exportDisplayedData() {
    const dialogRef = this.dialog.open(ExportDataWarningModalComponent, {
      width: "500px",
      disableClose: true,
      panelClass: 'popupModal',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.callExportDisplayedDataApi();
      }
    });
  }

  callExportDisplayedDataApi() {
    this.spinner++;
    this.api.getEpisodeDisplayData((res: any) => {
      this.spinner--;
      let utcDate = new Date();
      let currDateTime = moment(utcDate).format("MMDDyyyyHHmmss");
      var filename = 'ExportDisplayedData' + currDateTime + '.xlsx';
      const blob = new Blob([res], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      var downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click(); 
    }, (err) => {
      this.spinner--;
      this.snackBar.open("File did not downloaded", "Dismiss", {duration: 3000});
      console.log(err);
    }, this.lineQuery);
  }

}
