<div class="page-wrapper">
  <div class="page-title">{{pageTitle}}</div>
  <mat-divider class="page-divider" style="margin-bottom: 20px;"></mat-divider>

  <div *ngIf="!errorMessage; else error" style="position: relative">
    <app-loading-spinner *ngIf="loadSpinner > 0"></app-loading-spinner>

    <form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)" style="font-size: 15.2px; width: 812px;" autocomplete="off">

      <mat-grid-list cols="6" gutterSize="16px" rowHeight="83px">

        <mat-grid-tile colspan="6" *ngIf="this.practices.length > 1">
          <mat-form-field style="width: 100%;">
            <mat-label>Practice</mat-label>
            <mat-select [disabled]="form.disabled" formControlName="practice">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let practice of practices" [value]="practice.Practice_ID">
                {{practice.Practice_Name}}
                </mat-option>
            </mat-select>
            <mat-hint>Select Practice</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <mat-form-field style="width: 100%;">
            <mat-label>Payer</mat-label>
            <mat-select [disabled]="form.disabled" formControlName="payer">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let payer of payers" [value]="payer">
                {{payer}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="4">
          <mat-form-field style="width: 100%;">
            <mat-label>Physician</mat-label>
            <mat-select formControlName="physician" [disabled]="form.disabled || this.form.get('practice')?.value == null">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let physician of physicians" [value]="physician.Physician_ID">
                {{physician.LastName}}, {{physician.FirstName}}
              </mat-option>
            </mat-select>
            <mat-hint>Select Physician</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
              <mat-form-field style="width: 100%">
                <mat-label>Date of Service</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="dateOfService">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint>Enter Date of Service</mat-hint>
              </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="4">
          <mat-form-field style="width: 100%;">
            <mat-label>Procedure</mat-label>
            <mat-select formControlName="procedure" [disabled]="form.disabled || this.form.get('physician')?.value == null">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let procedure of procedures" [value]="procedure.Bundle_ID">
                {{procedure.Bundle_Name}}
              </mat-option>
            </mat-select>
            <mat-hint>Select a Procedure</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="6">
          <mat-form-field style="width: 100%;">
            <mat-label>Procedure Description</mat-label>
            <input matInput formControlName="procedureDesc">
            <mat-hint>Describe the Procedure</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="3" rowspan="1" style="overflow: visible;">
          <mat-radio-group aria-label="Place of Service" style="width: 100%;" formControlName="placeOfService">
            <div style="position: absolute; top: 0px; width: 100%">
              <div style="margin-bottom: 10px; font-weight: bold;" [ngClass]="{'requiredError': validatePlaceOfService()}">Place of Service</div>
              <div style="margin-left: 10px">
                <div style="margin-bottom: 14px;" fxLayout="row" fxLayoutAlign="space-between none">
                  <mat-radio-button [value]=8>Inpatient Hospital [21]</mat-radio-button>
                  <mat-radio-button [value]=-1>Other</mat-radio-button>
                </div>
                <mat-radio-button [value]=9>On Campus-Outpatient Hospital [22]</mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </mat-grid-tile>

        <mat-grid-tile colspan="3">
          <mat-form-field style="width: 100%;" [style.display]="this.form.get('placeOfService')?.value === -1 ? 'block' : 'none'">
            <mat-label >Other Place of Service</mat-label>
            <mat-select formControlName="otherPlacesOfService" [disabled]="form.disabled || this.form.get('placeOfService')?.value !== -1">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let otherPlace of otherPlacesOfService" [value]="otherPlace.val">
                {{otherPlace.display}}
              </mat-option>
            </mat-select>
            <mat-hint>Select a Place of Service</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="6">
          <mat-form-field style="width: 100%;">
            <mat-label>Select Acute Facility</mat-label>
            <mat-select formControlName="acuteFacilities" [disabled]="form.disabled || this.form.get('physician')?.value == null">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let acuteFacility of acuteFacilities" [value]="acuteFacility.Facility_ID">
                {{acuteFacility.Alt_LongName ? acuteFacility.Alt_LongName : acuteFacility.Facility_LongName}}
              </mat-option>
            </mat-select>
            <mat-hint>Begin Typing Facility Name</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="3">
          <mat-form-field style="width: 100%;">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName">
            <mat-hint>Patient's Last Name</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="3">
          <mat-form-field style="width: 100%;">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName">
            <mat-hint>Patient's First Name</mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="3">
          <mat-form-field style="width: 100%">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-hint>Patient's Date of Birth</mat-hint>
            <mat-error *ngIf="this.form.get('dateOfBirth')?.invalid">Age of patient must be 18 - 120</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="3">
          <mat-form-field style="width: 100%;">
            <mat-label>Medicare ID</mat-label>
            <input matInput formControlName="medicareId" (input)="sanitizeMedicareIdInput()">
            <mat-hint>Patient's Medicare ID</mat-hint>
            <mat-error *ngIf="this.form.get('medicareId')?.invalid">Format incorrect</mat-error>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="6">
          <div style="width: 100%; display: flex;" [ngStyle]="{'justify-content': this.isEditMode ? 'space-between' : 'flex-end'}">
            <app-back-button *ngIf="this.isEditMode"></app-back-button>

            <button *ngIf="this.isEditMode && isValidator" class="cancel-button" mat-button type="button" (click)="openCancelEpisodeModal()">Cancel Episode</button>

            <div *ngIf="warnings.length > 0" style="margin-left: 10px; margin-right: 10px; flex-shrink: 999; color: orange">
              <div *ngFor="let warning of warnings">
                  {{warning.message}}
              </div>
          </div>

              <button mat-raised-button [disabled]="spinner || submitButtonDisabled || form.disabled" color="accent" style="width: 122px; height: 36px" type="submit"><div *ngIf="!spinner">{{this.isEditMode ? 'Update' : 'Submit'}}</div><mat-spinner *ngIf="spinner" diameter="30" style="margin-left: 30px;"></mat-spinner></button>

          </div>

        </mat-grid-tile>

      </mat-grid-list>

    </form>
  </div>

  <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

</div>
