import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent implements OnInit {

  @Input() iconName: string = "";
  @Input() text: string = "";
  @Input() routerLink: string = "";
  @Input() routerLinkActiveOptions: string = "";
  @Input() routerLinkExact: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
