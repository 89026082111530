<link href="https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet">
<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap" rel="stylesheet"> 


<div *ngIf="loggedIn" style="height: 100%; display: flex;">
<div style="background-color: #3A8240; height: 37px; flex: 0 0 auto;"></div>

<div style="margin-left: 10.5px; flex: 1; display: flex; flex-direction: column;">
  <img src="assets/Episode Solutions Logo.svg" height="53px" style="margin-top: 15px; flex: 0 0 auto; align-self: baseline" />

  <div style="display: flex; flex: 1; margin-right: 10.5px;">
    <div fxLayout="column" fxLayoutAlign="space-between start" >
      <app-side-menu></app-side-menu>
      <div class="buttonSpacing"><app-menu-button iconName="login" text="Logout" routerLink="/logout" (click)="logout()"></app-menu-button></div>
    </div>

    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <div style="height: 100%; width: 100%">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
</div>
</div>