<div *ngIf="routerLinkExact; else routerLinkNonExactBlock">

    <a routerLink={{routerLink}} routerLinkActive="routeActive" [routerLinkActiveOptions]="{exact: true}" class="link routeInactive">
        <div class="iconAndText">
            <mat-icon>{{iconName}}</mat-icon>
            <span class="text">{{text}}</span>
        </div>
    </a>

</div>

<ng-template #routerLinkNonExactBlock>

    <a routerLink={{routerLink}} routerLinkActive="routeActive" class="link routeInactive">
        <div class="iconAndText">
            <mat-icon>{{iconName}}</mat-icon>
            <span class="text">{{text}}</span>
        </div>
    </a>

</ng-template>