import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ValidatorGuard implements CanActivate {
  constructor(private api: ApiService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if we return true, you have access to the Feature
   
    return this.api.getUserADSelf()
    .pipe(switchMap(user => {
      if (user.ValidatorFlag) {
        return of(true);
      }
      return of(false);
    }));
  }

}
