<div class="page-wrapper" style="display:flex; flex-direction: column; height: 100%">
    <div class="page-title">Episode Details</div>
    <mat-divider class="page-divider" style="margin-bottom: 15.5px;"></mat-divider>

    <div style="flex: 1; display:flex; flex-direction: column; position: relative">
        <app-loading-spinner *ngIf="loadSpinner > 0"></app-loading-spinner>

        <div style="display:flex; width: 95%; height: 100%; justify-content: space-between;" *ngIf="episodeDetails">
            <div style="display:flex">
                <app-episode-details-sidebar
                    [EpisodeMaster_ID]="this.episodeDetails.EpisodeMaster_ID"
                    [PhysFirstName] = "this.episodeDetails.PhysFirstName"
                    [PhysLastName] = "this.episodeDetails.PhysLastName"
                    [Bundle_Name] = "this.episodeDetails.Bundle_Name"
                    [Procedure_Desc] = "this.episodeDetails.Procedure_Desc"
                    [Facility_Name] = "this.episodeDetails.Facility_Name"
                    [PlaceOfService_TechnicalID] = "this.episodeDetails.PlaceOfService_TechnicalID"
                    [PlaceOfService_DisplayName] = "this.episodeDetails.PlaceOfService_DisplayName"
                    [PatFirstName] = "this.episodeDetails.PatFirstName"
                    [PatLastName] = "this.episodeDetails.PatLastName"
                    [PatDOB] = "this.episodeDetails.PatDOB"
                    [MedicareID] = "this.episodeDetails.MedicareID"
                    [TargetPrice] = "this.episodeDetails.TargetPrice"
                    [isCJR] = "this.episodeDetails.isCJR"
                    [notes] = "this.notes"
                    [isValidator] = this.user.ValidatorFlag
                ></app-episode-details-sidebar>
            </div>

            <div *ngIf="validator" style="width: 500px; margin-left: 5px; margin-right: 5px; display: flex; flex-direction: column;">
                <form [formGroup]="form" autocomplete="off" style="display: flex; flex-direction: column; justify-content: space-between">
                    <mat-form-field>
                        <mat-select formControlName="bundle">
                            <mat-option *ngFor="let bundle of bundles" [value]="bundle.Bundle_ID">
                            {{bundle.Bundle_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-hint>Confirm/Change Bundle Assignment</mat-hint>
                    </mat-form-field>

                    <mat-form-field class="form-field-no-bottom" style="margin-top: 62px;">
                        <mat-label>Notes</mat-label>
                        <textarea style="height: 112px" matInput formControlName="note"></textarea>
                    </mat-form-field>

                    <div style="display: flex; justify-content: space-between;" >
                        <button mat-button [disabled]="submitBundleAndNoteButtonsDisabled" style="margin-top: 16px; align-self: flex-end" (click)="submitNoteNotEligible()" type="button">NOT ELIGIBLE</button>
                        <button mat-raised-button [disabled]="submitBundleAndNoteButtonsDisabled" color="accent" style="margin-top: 16px; align-self: flex-end" (click)="submitNoteReadyForClaim()" type="button">READY FOR CLAIM</button>
                    </div>

                    <div *ngIf="updateBundleAndStatusErrors.length > 0">
                        <div *ngFor="let error of updateBundleAndStatusErrors" [ngStyle]="{'color': error.errorType == 0 ? 'red' : 'orange'}">
                            {{error.error}}
                        </div>
                    </div>

                </form>

                <div style="display: flex; flex: 1; align-items: flex-end;">
                    <app-back-button></app-back-button>
                </div>
            </div>

            <div style="display: flex; flex-direction: column">

                <div style="width: 260px; display: flex; flex-direction: column;">
                    <mat-form-field style="width: 100%">
                        <mat-select [formControl]="statusControl" [disabled]="user.ValidatorFlag == false">
                            <mat-option *ngFor="let status of episodeStatuses | visibleEpisodeStatus | applicableEpisodeStatuses : episodeDetails : episodeDetails.CurrentStatusID" [value]="status.EpisodeStatus_ID">
                                {{status.EpisodeStatus_Name}}
                            </mat-option>
                        </mat-select>
                        <mat-hint *ngIf="user.ValidatorFlag === true">Override/Reset an Episode Status</mat-hint>
                    </mat-form-field>

                    <button mat-raised-button color="accent" *ngIf="user.ValidatorFlag === true" (click)="submitStatus()" style="margin-top: 16px; align-self: flex-end; background-color: var(--accent-medium); color: black" type="submit" [disabled]="episodeDetails.CurrentStatusID === 9 || (form.get('status')?.pristine && form.get('bundle')?.pristine)">UPDATE</button>

                    <div *ngIf="updateEpisodeStatusErrors.length > 0">
                        <div *ngFor="let error of updateEpisodeStatusErrors" [ngStyle]="{'color': error.errorType == 0 ? 'red' : 'orange'}">
                            {{error.error}}
                        </div>
                    </div>
                </div>

                <div *ngIf="user.ValidatorFlag === true" style="display: flex; flex: 1; align-items: flex-end;">
                    <div style="margin-left: 45%; margin-bottom: 5%;">
                        <button mat-raised-button style="background-color: #C6C9E7;" type="button" (click)="showEpisodeStatusLogs()">View Activity Log</button>
                    </div>
                </div>
            </div>





        </div>


    </div>

    <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

</div>
