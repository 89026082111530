<div class="page-wrapper">
    <div class="page-title">Administration</div>
    <mat-divider class="page-divider" style="margin-bottom: 20px;"></mat-divider>
  
    <div *ngIf="!errorMessage; else error" style="position: relative">
      <app-loading-spinner *ngIf="loadSpinner > 0"></app-loading-spinner>

      <mat-form-field class="form-field-no-bottom" style="width: 700px" *ngIf="this.practices.length > 1">
        <mat-label>Practice</mat-label>
        <mat-select [value]="selectedPractice" (selectionChange)="practiceChange($event.value)">
            <mat-option *ngFor="let practice of practices" [value]="practice.Practice_ID">
            {{practice.Practice_Name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <br><br>

      <div>
          <div class="section-heading">Users with Access</div>
          <ul *ngIf="users && users.length > 0">
            <li *ngFor="let user of users">
                {{(user.UserEmail ? user.UserEmail + ", " : "") + user.UserName}}
            </li>
        </ul>
      </div>

      <br>

      <div>
        <div class="section-heading">Physicians and Bundle Assignments</div>
        <div *ngFor="let physician of physicians">
            <div class="physician-heading">{{physician.FirstName}} {{physician.LastName}}</div>
            <ul>
                <li *ngFor="let bundleID of physician.BundleIDs">
                    {{this.bundleMap.get(bundleID)?.Bundle_Name}}
                </li>
            </ul>
            <br>
        </div>
    </div>

    <br>

    <div>
        <div class="section-heading">Facilities</div>
        <ul>
            <li *ngFor="let facility of facilities">
                {{facility.Facility_LongName}}
            </li>
        </ul>
    </div>
  
    </div>
    
    <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>
  
  </div>