import 'chartjs-plugin-datalabels';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { ApiService } from '../api.service';
import { AppService } from '../app-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Practice } from '../NewDomain/Practice';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  SharedData: any;
  public isValidator: boolean;
  practices:Practice[] = [];
  practiceIDToPracticeMap: Map<number, Practice> = new Map<number, Practice>();
  practiceId: any;

  dashboardStatusDetails: any;
  displayPieChart = false;

  public pieChartLabels: string[] = ['Ready for Claim', 'Denied Claim', 'Processing Claim', 'ERA Receieved', 'Surgery Scheduled', 'Submission Failed', 'Not Eligible', 'On Hold'];

  public pieChartData: any[] = [];
  public pieChartType: string = 'pie';
  public pieChartOptions : any = {
    plugins: {
      legend: { position: 'right' },
      datalabels: {
        formatter: (value, ctx) => {
        },
        color: '#fff', // Label color
        font: {
          weight: 'bold'
        }
      }
    }
  };

  constructor(private api: ApiService, private appService: AppService, public route: ActivatedRoute, private router: Router,) { }

  ngOnInit(): void {
    this.api.getUserADSelf()
    .subscribe({
      next: user => {
       this.isValidator = user.ValidatorFlag;
      },
      error: err => {
        this.isValidator = false;
      }
    });

    this.api.getPractices(
      (res) => {
        this.practices = res;
        this.practiceIDToPracticeMap = new Map<number, Practice>();

        for (let i = 0; i < this.practices.length; ++i) {
          this.practiceIDToPracticeMap.set(this.practices[i].Practice_ID!, this.practices[i]);
        }

      },
      (err) => { 
        console.log(err);
    })

    this.api.getDashboardStatusDetails(this.practiceId, (resp: any) => {
      this.dashboardStatusDetails = resp;
      this.calculatePercentage();
      let pieChartDataArr: number[] = [];
      pieChartDataArr.push(this.dashboardStatusDetails?.ReadyForClaimRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.DeniedFilesRecords?.TotalDenialRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.ProcessingClaimRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.EraReceivedRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.SurgeryScheduledRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.SubmissionFailedRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.NotEligibleRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.OnHoldRecords);
      let dataObj = {
        data: pieChartDataArr,
        backgroundColor: ['#DBAE58', '#AC3E31', '#488A99', '#6AB187', '#1C4E80', '#EA6A47', '#A5D8DD', '#7E909A'],
        hoverBackgroundColor: ['#DBAE58', '#AC3E31', '#488A99', '#6AB187', '#1C4E80', '#EA6A47', '#A5D8DD', '#7E909A'],
        hoverBorderColor: ['#DBAE58', '#AC3E31', '#488A99', '#6AB187', '#1C4E80', '#EA6A47', '#A5D8DD', '#7E909A']
      }
      this.pieChartData[0] = dataObj;
      this.displayPieChart = true;     
    },
    (err) => {
      console.log(err);
    })
  }

  ngOnDestroy() {
    this.SharedData = undefined;
  }

  assignToPieChart() {
    this.api.getDashboardStatusDetails(this.practiceId, (resp: any) => {
      this.dashboardStatusDetails = undefined;
      this.dashboardStatusDetails = resp;
      this.calculatePercentage();
      let pieChartDataArr: number[] = [];
      pieChartDataArr.push(this.dashboardStatusDetails?.ReadyForClaimRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.DeniedFilesRecords?.TotalDenialRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.ProcessingClaimRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.EraReceivedRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.SurgeryScheduledRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.SubmissionFailedRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.NotEligibleRecords);
      pieChartDataArr.push(this.dashboardStatusDetails?.OnHoldRecords);
      let dataObj = {
        data: pieChartDataArr,
        backgroundColor: ['#DBAE58', '#AC3E31', '#488A99', '#6AB187', '#1C4E80', '#EA6A47', '#A5D8DD', '#7E909A'],
        hoverBackgroundColor: ['#DBAE58', '#AC3E31', '#488A99', '#6AB187', '#1C4E80', '#EA6A47', '#A5D8DD', '#7E909A'],
        hoverBorderColor: ['#DBAE58', '#AC3E31', '#488A99', '#6AB187', '#1C4E80', '#EA6A47', '#A5D8DD', '#7E909A']
      }
      this.pieChartData[0] = dataObj;
      this.displayPieChart = true;
      
    },
    (err) => {
      console.log(err);
    });
  }

  calculatePercentage() {
    let total = this.dashboardStatusDetails.TotalCount;
    this.dashboardStatusDetails.PercentReadyClaim = Math.round(((this.dashboardStatusDetails.ReadyForClaimRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentDeniedFiles = Math.round(((this.dashboardStatusDetails.DeniedFilesRecords?.TotalDenialRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentProssClaim = Math.round(((this.dashboardStatusDetails.ProcessingClaimRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentERARc = Math.round(((this.dashboardStatusDetails.EraReceivedRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentSurgerySch = Math.round(((this.dashboardStatusDetails.SurgeryScheduledRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentSubmissionFailed = Math.round(((this.dashboardStatusDetails.SubmissionFailedRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentNotEligible = Math.round(((this.dashboardStatusDetails.NotEligibleRecords / total) * 100) * 100) / 100;
    this.dashboardStatusDetails.PercentOnHold = Math.round(((this.dashboardStatusDetails.OnHoldRecords / total) * 100) * 100) / 100;
  }

  StatusSelect(status, statusId) {
    this.SharedData = {
      status: status,
      statusId: statusId,
    };
    if (this.practiceId) {
      this.SharedData.practiceId = this.practiceId;
    }
    this.sendDataToAppService();
    this.router.navigate(['/patientEpisodeManager']);
  }

  sendDataToAppService() {
    this.appService.setNewData(this.SharedData);
  }

  practiceChange(value) {
    this.practiceId = value;
    this.displayPieChart = false;
    this.assignToPieChart();
  }

}
