import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AppService {
    private data = new BehaviorSubject({})
    currentData = this.data.asObservable();

    constructor() {}

    setNewData(data) {
        this.data.next(data);
    }
}