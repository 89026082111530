import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ApiService} from 'src/app/api.service';

@Component({
    selector: 'episode-status-activity-log',
    templateUrl: './episode-status-activity-log-component.html',
    styleUrls: ['./episode-status-activity-log-component.css']
})

export class EpisodeStatusActivityLogComponent implements OnInit {

    episodeId: any;
    public episodeStatusActivity: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { episodeDetails: any},
    public dialogRef: MatDialogRef<EpisodeStatusActivityLogComponent>,
    private api: ApiService) {}

    ngOnInit(): void {
        this.episodeId = this.data.episodeDetails.EpisodeMaster_ID;
        this.api.getEpisodeStatusActivityLogDetails(this.episodeId,
            (res) => {
                console.log(res);
                this.episodeStatusActivity = res;
            },
            (err) => {
                console.log(err);
            })
    }

    close() {
        this.dialogRef.close();
    }
}