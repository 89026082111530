<div class="position-relative">
    <div>
        <button mat-icon-button class="modal-close-button close-icon" (click)="close(false)">
            <mat-icon>close</mat-icon>
        </button> 
    </div>
    <div>
        <div>
            <h2 mat-dialog-title style="margin-top: 0px; padding-left: 42%; font-size: large; font-weight: 600;">
                Warning
            </h2>
        </div>
        <div>
            <span style="display: flex; text-align: center; justify-content: center; font-size: medium;">Exporting a large amount of data can take several minutes and, if done during business hours can adversely affect other users.</span>
            <br>
            <span style="display: flex; text-align: center; justify-content: center; font-size: medium;">Do you want to continue with the export at this time?</span>
        </div>

        <div class="option-buttons" style="justify-content: center; margin-left: 20px;">
            <button mat-raised-button class="width-122px" color="accent" (click)="close(true)">Yes</button>
            <button mat-raised-button class="width-122px margin-left-10px" color="accent" (click)="close(false)">No</button>
        </div>
         
    </div>
</div>