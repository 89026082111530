<div class="page-wrapper">
    <div class="page-title">Support</div>
    <mat-divider class="page-divider" style="margin-bottom: 20px;"></mat-divider>

    <div style="position: relative">
        <app-loading-spinner *ngIf="loadSpinner"></app-loading-spinner>

        <form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSubmit(formDirective)" style="font-size: 15.2px; width: 500px" autocomplete="off">

                <mat-form-field style="width: 66.6%; margin-bottom: 30px" [ngStyle]="{'display' : practices.length > 1 ? '' : 'none'}">
                    <mat-label>Practice</mat-label>
                    <mat-select formControlName="practice">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let practice of practices" [value]="practice.Practice_ID">
                        {{practice.Practice_Name}}
                    </mat-option>
                    </mat-select>
                    <mat-hint>Select your practice</mat-hint>
                </mat-form-field>

                <mat-form-field  style="width: 100%; margin-bottom: 30px">
                    <mat-label>How can we help today?</mat-label>
                    <textarea style="height: 112px" matInput formControlName="emailText"></textarea>
                    <mat-hint>Provide as much information as possible</mat-hint>
                </mat-form-field>

                <div style="margin-bottom: 10px" [ngStyle]="{'color': formDirective.submitted && !form.get('phoneEmailGroup')?.valid ? 'red' : 'initial'}">How may we contact you?</div>
                <div style="margin-left: 10px; margin-bottom: 30px" formGroupName="phoneEmailGroup">
                    <mat-checkbox value="1" formControlName="phoneCheckbox" (change)="$event.checked ? this.form.get('phoneNumber')?.enable() : this.form.get('phoneNumber')?.disable()">Phone</mat-checkbox>
                    <mat-checkbox value="2" formControlName="emailCheckbox" style="margin-left: 35px">Email {{userEmail}}</mat-checkbox>
                </div>

                <mat-form-field style="width: 66.6%">
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phoneNumber">
                    <mat-hint>Please enter your phone number</mat-hint>
                    <mat-error *ngIf="this.form.get('phoneNumber')?.invalid">Format incorrect</mat-error>
                </mat-form-field>

                <div style="width: 100%;" fxLayoutAlign="end">
                    <button mat-raised-button [disabled]="spinner" color="accent" style="width: 122px; height: 36px" type="submit"><div *ngIf="!spinner">Submit</div><mat-spinner *ngIf="spinner" diameter="30" style="margin-left: 30px;"></mat-spinner></button>
                </div>
                
        </form>
    </div>

</div>
