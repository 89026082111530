<div style="position: relative;">
    <app-loading-spinner *ngIf="loadSpinner"></app-loading-spinner>

    <div *ngIf="!errorMessage">
        <div *ngIf="showConfirm">
            <button mat-icon-button class="modal-close-button" style="position: absolute; right: -20px; top: -20px" (click)="close()">
                <mat-icon>close</mat-icon>
            </button> 

            <div>
                <div style="font: normal normal bold 18px/22px Roboto;">Confirm Cancellation</div>
                <div style="font: normal normal normal 18px/22px Roboto; padding-top: 6.26px;">Are you sure you wish to cancel this episode?</div>
                <div style="font: normal normal normal 12px/22px Roboto;">(The episode status will change to "Canceled")</div>
                <form [formGroup]="form" style="padding-top: 22px;" (ngSubmit)="submit()">
                    <mat-form-field style="width: 100%; font-size: 12px;">
                        <mat-label>Cancellation Reason</mat-label>
                        <mat-select  formControlName="cancellationReason">
                            <mat-option>--</mat-option>
                            <mat-option *ngFor="let cancellationReason of episodeCancellationReasonList" [value]="cancellationReason.Id">
                            {{cancellationReason.Reason}}
                            </mat-option>
                        </mat-select>
                        <mat-hint>Select a reason for cancellation</mat-hint>
                        <mat-error *ngIf="this.form.get('cancellationReason')?.invalid">Cancellation reason is required</mat-error>
                    </mat-form-field>

                    <div style="padding-top: 22px; display: flex; justify-content: space-between;">
                        <button type="button" mat-button (click)="close()">No. Go back</button>
                        <button type="submit" mat-raised-button color="accent" [disabled]="!this.form.valid">YES - CANCEL</button>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="!showConfirm" style="display: flex; flex-direction: column; align-items: flex-start; align-items: center;">
            <div style="font: normal normal bold 18px/22px Roboto; padding-bottom: 22.24px;">Episode Canceled</div>
            <button mat-raised-button color="accent">RETURN TO EPISODE MANAGER</button>
        </div>
    </div>

    <div *ngIf="errorMessage">
        <div style="color: red;">{{errorMessage}}</div>
        <button mat-raised-button color="accent" (click)="close()">Close</button>
    </div>
</div>