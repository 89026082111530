<div style="position: relative;">
    <div>
        <button mat-icon-button class="modal-close-button" style="position: absolute;" (click)="close()">
            <mat-icon>close</mat-icon>
        </button> 
    </div>
    <div>
        <div>
            <h2 mat-dialog-title style="margin-top: 0px;">
                Notes
            </h2>
        </div>

        <div>
            <table style="width: 450px;">
                <tr>
                    <th class="first-col-header text-align-left">
                        <span>Note Date</span>
                    </th>
                    <th class="second-col-header text-align-left border-styling">
                        <span>Note</span>
                    </th>
                    <th class="second-col-header text-align-left">
                        <span>Read</span>
                    </th>
                </tr>
                <tr *ngFor="let deletedReson of deletedFileResponse">   
                    <td class="first-col-header text-align-left">
                        {{deletedReson.FileDeletedDate | date:"MM/dd/yy":"UTC"}}
                    </td>
                    <td class="second-col-header font-category border-styling">
                        {{deletedReson.FileDeleteReason}}
                    </td>
                    <td class="second-col-header font-category">
                        <mat-checkbox [checked]="deletedReson.NoteReviewed" class="cursor-pointer" style="margin-right: 10px" (change)="noteReviewedChecked($event, deletedReson)"></mat-checkbox>
                    </td>
                </tr>
            </table>
        </div>

        <div style="display: flex; width: 450px; margin-top: 40px; margin-bottom: 11px; justify-content: end;">
            <button mat-button style="width: 122px; background-color: rgba(0, 0, 0, 0.12); color: rgba(0, 0, 0, 0.87);" (click)="close()">Cancel</button>
        </div>   
    </div>
</div>