import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MsalService} from '@azure/msal-angular';
import {DuplicateEpisodeError} from 'src/app/json-mappings/duplicateEpisodeError';
import {Subscription} from 'rxjs';
import {ApiService} from 'src/app/api.service';
import {EpisodeDetails, EpisodeDetailsResponseDTO} from 'src/app/NewDomain/EpisodeDetails';
import {EpisodeNote} from 'src/app/NewDomain/EpisodeNote';
import {Bundle} from 'src/app/NewDomain/Bundle';
import {EpisodeStatus} from 'src/app/NewDomain/EpisodeStatus';
import {EpisodeStatusIDs} from '../EpisodeStatuses';
import {Episode} from 'src/app/NewDomain/Episode';
import {compare} from 'fast-json-patch';
import {ValidationResult} from 'src/app/NewDomain/ValidationResult';
import {UserAD} from 'src/app/NewDomain/UserAD';
import { EpisodeStatusActivityLogComponent } from '../episode-status-activity-log-modal/episode-status-activity-log-component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-episode-details',
  templateUrl: './episode-details.component.html',
  styleUrls: ['./episode-details.component.css']
})
export class EpisodeDetailsComponent implements OnInit, OnDestroy {
  public loadSpinner = 0;
  public errorMessage = '';
  public bundles: Bundle[] = [];
  public notes: EpisodeNote[] = [];
  public subscriptions: Subscription[] = [];
  public episodeStatuses: EpisodeStatus[] = [];
  public initialEpisodeState: Episode = new Episode();
  public user = new UserAD();
  public updateEpisodeStatusErrors: DuplicateEpisodeError[] = [];
  public updateBundleAndStatusErrors: DuplicateEpisodeError[] = [];
  public overrideDuplicateEpisodeWarning = false;
  public submitBundleAndNoteButtonsDisabled = false;
  public overrideDuplicateWarning = false;
  public episodeDetails: EpisodeDetails | undefined;
  public submitStatusError = false;

  public form = new FormGroup({
    bundle: new FormControl(null),
    note: new FormControl('')
  });

  public statusControl = new FormControl(null);

  get validator(): boolean {
    return this.user.ValidatorFlag;
  }

  constructor(private api: ApiService,
              private route: ActivatedRoute,
              private location: Location,
              private snackBar: MatSnackBar,
              private authService: MsalService,
              private router: Router,
              public dialog: MatDialog) {
  }

  public ngOnInit(): void {
    // If they send an episode, and it returns a warning, if they interact with the form again,
    // assume that it needs to be checked for duplicate errors again since there may be new values
    this.subscriptions.push(this.form.valueChanges.subscribe((value: any) => this.overrideDuplicateWarning = false) as Subscription);

    this.loadSpinner++;
    this.api.getEpisodeDetails(Number(this.route.snapshot.paramMap.get('episodeId')),
      (res: EpisodeDetailsResponseDTO) => {
        this.episodeDetails = res.episodeDetails;
        this.notes = res.episodeNotes;

        if (this.episodeDetails.CurrentStatusID === 9) {
          this.form.disable();
          this.statusControl.disable();
          this.submitBundleAndNoteButtonsDisabled = true;
        }

        this.initialEpisodeState.PopulateFromEpisodeDetails(res.episodeDetails);

        this.loadSpinner--;
        this.setBundlesAndStatus();
      },
      (err: any) => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      });

    this.loadSpinner++;
    this.api.getEpisodeStatuses(
      (res) => {
        this.episodeStatuses = res;
        this.loadSpinner--;
        this.setBundlesAndStatus();
      },
      (err) => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      });

    this.loadSpinner++;
    this.api.getUserADSelf()
    .subscribe({
      next: user => {
        this.user = user;
        this.loadSpinner--;
      },
      error: err => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public setBundlesAndStatus(): void {
    // If loadSpinner isn't 0, then the other call hasn't completed. Dirty way of signaling
    if (this.loadSpinner === 0 && !this.errorMessage) {
      this.loadSpinner++;
      this.api.getBundles(
        (res) => {
          this.bundles = res;
          this.loadSpinner--;
        },
        (err) => {
          this.errorMessage = err.message;
          this.loadSpinner--;
        }, this.episodeDetails?.PhysicianID);

      this.form.get('bundle')?.setValue(this.episodeDetails?.BundleID);
      this.statusControl.setValue(this.episodeDetails?.CurrentStatusID);
    }
  }

  public update(): void {
    this.snackBar.open('Episode successfully updated!', 'Dismiss', {duration: 3000});
  }

  public submitNoteNotEligible(): void {
    this.submitNote(EpisodeStatusIDs.notEligible);
  }

  public submitNoteReadyForClaim(): void {
    this.submitNote(EpisodeStatusIDs.readyForClaim);
  }

  public submitNote(status: number): void {
    this.submitStatusError = false;

    // Post note if one was entered
    if (this.form.get('note')?.value) {
      this.loadSpinner++;
      this.api.postEpisodeNote(
        (resp) => {
          this.notes.push(resp);
          this.loadSpinner--;
          this.submitNoteFinally();
        },
        (err) => {
          this.submitStatusError = true;
          this.loadSpinner--;
          this.submitNoteFinally();
        }, this.episodeDetails!.EpisodeMaster_ID, this.form.get('note')?.value);
    }

    // If bundle or status is different than what's already on the episode, need to update episode with the changes
    if (this.initialEpisodeState.Bundle_ID !== this.form.get('bundle')?.value ||
      this.initialEpisodeState.Status_ID !== status) {
      this.loadSpinner++;

      const ep: Episode = new Episode();
      ep.PopulateFromEpisodeDetails(this.episodeDetails!);

      if (this.initialEpisodeState.Status_ID !== status) {
        ep.Status_ID = status;
      }

      if (this.initialEpisodeState.Bundle_ID !== this.form.get('bundle')?.value) {
        ep.Bundle_ID = this.form.get('bundle')?.value;
      }

      const patch = compare(this.initialEpisodeState, ep);

      this.api.patchEpisode(
        (resp: ValidationResult) => {
          this.initialEpisodeState.Bundle_ID = this.form.get('bundle')?.value;
          this.initialEpisodeState.Status_ID = status;
          this.loadSpinner--;
          this.submitNoteFinally();
        },
        (err) => {
          this.submitStatusError = true;
          this.loadSpinner--;
          this.submitNoteFinally();
        }, this.episodeDetails!.EpisodeMaster_ID, patch);
    }

    this.submitNoteFinally();
  }

  public submitStatus(): void {
    if (this.statusControl.value === this.initialEpisodeState.Status_ID) {
      // Nothing to do since the status hasn't changed, so just pretend we did something to satisfy the user
      this.snackBar.open('Status successfully updated!', 'Dismiss', {duration: 3000});
    } else {
      this.loadSpinner++;
      const blankEpisode: Episode = new Episode();
      const compareEpisode: Episode = new Episode();
      compareEpisode.Status_ID = this.statusControl.value;
      const patch = compare(blankEpisode, compareEpisode);

      this.api.patchEpisode(
        (resp: ValidationResult) => {
          this.initialEpisodeState.Status_ID = this.statusControl.value;
          this.snackBar.open('Status successfully updated!', 'Dismiss', {duration: 3000});
          this.loadSpinner--;
        },
        (err) => {
          this.snackBar.open('Status could not be updated due to error.', 'Dismiss');
          this.loadSpinner--;
        }, this.episodeDetails!.EpisodeMaster_ID, patch);
    }
  }

  public submitNoteFinally(): void {
    if (this.loadSpinner === 0) {
      if (this.submitStatusError) {
        this.snackBar.open('Status and bundle could not be updated due to error.', 'Dismiss');
      } else {
        this.snackBar.open('Status and bundle successfully updated!', 'Dismiss', {duration: 3000});
        this.router.navigate(['/patientEpisodeManager']);
      }
    }
  }

  showEpisodeStatusLogs() {
    if (this.episodeDetails.EpisodeMaster_ID) {
      const dialogRef = this.dialog.open(EpisodeStatusActivityLogComponent, {
        width: "864px",
        disableClose: true,
        data: {episodeDetails: this.episodeDetails},
        panelClass: 'popupModal',
        autoFocus: false
      });
  
      dialogRef.afterClosed().subscribe(() => {
      });
    }
  }

}
