import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { clearInterval, setInterval } from 'worker-timers';

@Component({
    selector: 'timeout-notify-modal',
    templateUrl: './timeout-notify-modal.component.html',
    styleUrls: ['./timeout-notify-modal.component.css']
})

export class TimeoutNotifyModalComponent implements OnInit {

    timerCounter = 125;
    autoLogout = false;
    intervalId: any;

    constructor(public dialogRef: MatDialogRef<TimeoutNotifyModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any){}
    
    ngOnInit() {
        this.intervalId = setInterval(() => {
            this.timerCounter = this.timerCounter - 1;
            if(this.timerCounter === 5) {
                this.autoLogout = true;
            }
            else if (this.timerCounter === 0) {
                clearInterval(this.intervalId);
                this.close(false);
            }
        }, 1000)
    }

    close(action?): void {
        clearInterval(this.intervalId);
        this.dialogRef.close(action);
    }
}