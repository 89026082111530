import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { UserAD } from '../../NewDomain/UserAD';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.css']
})
export class DeleteConfirmationDialogComponent {

  user:UserAD = new UserAD();

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { episodeId: string, reason: string, userName: string },
    private apiService: ApiService,
    private router: Router 
  ) { }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    const deletionReason = this.data.reason;
    this.apiService.storeDeletionReason(this.data.episodeId, deletionReason, this.data.userName).subscribe(
      () => {
        this.dialogRef.close({ confirm: true });
      },
      (err) => {
        console.error(err);
      }
    );
  } }
