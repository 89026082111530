import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ACHFilesQuery } from 'src/app/apiQueryInterfaces';
import { ApiService } from 'src/app/api.service';
import { ACHFile } from 'src/app/NewDomain/ACHFile';
import { EraViewerComponent } from '../era-viewer.component';

@Component({
  selector: 'app-era-viewer-for-ach',
  templateUrl: '../era-viewer.component.html',
  styleUrls: ['../era-viewer.component.css']
})
export class EraViewerForAchComponent extends EraViewerComponent implements OnInit {

  constructor(api:ApiService, domSanitizer:DomSanitizer, route: ActivatedRoute, snackBar: MatSnackBar) { 
    super(api, domSanitizer, route, snackBar ) 
  }

  ngOnInit(): void {
    this.wholePageLoad++
    let achQueryParams:ACHFilesQuery = { ids: [ Number(this.route.snapshot.paramMap.get('achId')) ] }
    this.api.getACHFiles(achQueryParams, (res:ACHFile[]) => {

      let achFound = false
      let index = 1 //Have to account for the extra empty

      for (let i = 0; i < res.length; ++i) {
        this.achAndERAResults.achs[res[i].ACHFile_ID] = this.CreateACHDocumentFromACHFileDomain(res[i])

        if (!achFound && res[i].ACHFile_ID.toString() == this.route.snapshot.paramMap.get('achId')) {
          this.setACHTab(index)
          this.selectedACH = this.achAndERAResults.achs[res[i].ACHFile_ID]
          this.ACHTabClick(this.achAndERAResults.achs[res[i].ACHFile_ID])
          achFound = true
        }

        ++index
      }

      this.wholePageLoad--
    },
    (err:any) => {
      //this.errorMessage = err.message
      this.wholePageLoad--
    })
  }

}
