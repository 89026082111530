<div [hidden]="this.activeRouter.children.length != 0" class="page-wrapper">
    <div class="page-title">Patient Episodes</div>
    <mat-divider class="page-divider"></mat-divider>

    <div *ngIf="!errorMessage; else error" style="position: relative">
        <app-loading-spinner *ngIf="(spinner > 0) || (dataSource.loading$ | async)"></app-loading-spinner>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <div [ngStyle]="{'display' : validator ? 'flex' : 'none'}">
                <div [ngClass]="lineSummarySelectionModel.hasAnySelected() ? 'eraEnable': 'eraDisable'" class="filterSpacer" (click)="openAddERAModal()" style="display: flex; align-items: center;">
                    Upload ERA
                </div>
                <div [ngClass]="lineSummarySelectionModel.hasAnySelected() ? 'eraEnable': 'eraDisable'" class="filterSpacer" (click)="openAddDenialModal()" style="display: flex; align-items: center;">
                    Upload Denial
                </div>

                <mat-form-field class="filterDropdowns form-field-no-bottom filterSpacer">
                    <mat-label>Practice</mat-label>
                    <mat-select [(ngModel)]="practiceIdFromDashboard" (selectionChange)="practiceChange($event.value)">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let practice of this.practices" [value]="practice.Practice_ID">
                            {{practice.Practice_Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div [ngStyle]="{'display' : validator ? 'flex' : 'none'}">
                <div fxLayout="row" fxLayoutAlign="space-between" style="align-self: center;">
                    <div style="margin-right: 10px;">
                        <button mat-raised-button style="background-color: #FAC710; color: black;" (click)="exportValidationData()" type="button">Export Validation Data</button>
                    </div>
                    <div>
                        <button mat-raised-button style="background-color: #D5F692; color: black;" (click)="exportDisplayedData()" type="button">Export Displayed Data</button>
                    </div>
                </div>
            </div>
        </div>

        <div #episodeListWindow style="overflow: auto" [ngStyle]="{'height' : validator ? '705px' : '770px'}">
            <table mat-table matSort [dataSource]="dataSource" (matSortChange)=sortChange($event)>

                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-icon style="cursor: pointer" 
                                  [ngStyle]="{'color': lineSummarySelectionModel.hasAnySelected() ? 'red' : 'unset' }"
                                  (click)="lineSummarySelectionModel.clear()">cancel_presentation</mat-icon>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event.checked ? lineSummarySelectionModel.selectLineSummary(row) : lineSummarySelectionModel.deselectLineSummary(row)"
                                    [checked]="lineSummarySelectionModel.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
            
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Episode ID </th>
                <td mat-cell *matCellDef="let episode"> 
                    <a routerLink="./episodeDetails/{{episode.EpisodeMaster_ID}}">{{episode.EpisodeMaster_ID}}</a>
                </td>
                </ng-container>
            
                <ng-container matColumnDef="patientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Patient Name </th>
                    <td mat-cell *matCellDef="let episode"> {{episode.PatLastName}}, {{episode.PatFirstName}} </td>
                </ng-container>

                <ng-container matColumnDef="ptPayorId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Medicare ID </th>
                    <td mat-cell *matCellDef="let episode"> {{episode.MedicareID}} </td>
                </ng-container>

                <ng-container matColumnDef="surgeon">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Surgeon </th>
                    <td mat-cell *matCellDef="let episode"> {{episode.SurgeonLastName}}, {{episode.SurgeonFirstName}} </td>
                </ng-container>

                <ng-container matColumnDef="surgeryDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Surgery Date </th>
                    <td mat-cell *matCellDef="let episode"> {{episode.DateOfService | date:"MM/dd/yy"}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let episode"> 
                        <div style="display: flex">
                            
                            <div style="display:flex; position: relative; align-items: center;">
                                <div *ngIf="episode.hasNotes == true" style="position: absolute; right: 10px; user-select: none;">
                                    <mat-icon style="cursor: pointer; color: #52575D" (click)="episode.showNote = !episode.showNote; openNotes(episode)">
                                        sticky_note_2
                                    </mat-icon>
                                    <div *ngIf="episode.showNote" style="position: absolute; left: 25px; top: 0px; border: 1px solid #D0DBE5; border-radius: 15px; max-width: 400px; z-index: 50; background-color: white; padding: 20px; margin-right: -999999px">
                                        <div (click)="episode.showNote = !episode.showNote" style="cursor: pointer; position: absolute; top: 5px; right: 5px; user-select: none; color: #52575D">X</div>
                                        <div *ngFor="let note of episode.notes; let i = index">
                                            <div *ngIf="i > 0">--</div>
                                            <div>{{note.Author}}, {{note.AddDate | date:"MM/dd/yy"}}</div>
                                            <div>{{note.Note}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="this.mapEpisodeStatusIDToStatus" style="height: 8px; width: 8px; border-radius: 50%;" [ngStyle]="{'background-color': this.mapEpisodeStatusIDToStatus(episode.Status_ID)?.EpisodeStatus_DisplayColor}"></div>
                            </div>
                            <div style="padding-left: 8px;">{{this.mapEpisodeStatusIDToStatus(episode.Status_ID)?.EpisodeStatus_Name}}</div>
                        </div> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="files">
                    <th mat-header-cell *matHeaderCellDef> Files </th>
                    <td mat-cell *matCellDef="let episode">
                        <div style="display:flex">

                            <ng-container *ngIf="episode.has1500 == true || episode.hasZeroDollarRemitFile == true; then hasClaim; else noClaim"> </ng-container>

                            <ng-template #hasClaim>
                                <div style="display:flex; justify-content: center; width: 75px">
                                    <a *ngIf="episode.hasZeroDollarRemitFile == true" routerLink="./ZeroDollarRemitFileViewer/{{episode.EpisodeMaster_ID}}" style="position: relative; color: inherit">
                                        <img src="assets/image/Zero_dollar_remit_icon.png" height="24px" style="margin-left: 7.6px; cursor: pointer" />
                                    </a>
                                    <a *ngIf="episode.hasACH == true" style="position: relative; color: inherit" routerLink="./ERAViewerForEpisode/{{episode.EpisodeMaster_ID}}">
                                        <img src="assets/ACH Icon.svg" height="24px" style="cursor: pointer" />
                                    </a>
                                    <a routerLink="./validateClaim/{{episode.EpisodeMaster_ID}}">
                                        <img src="assets/pdf 1500 icon.svg" height="24px" style="margin-left: 7.6px; cursor: pointer" />
                                    </a>
                                </div>
                            </ng-template>

                            <ng-template #noClaim>
                                <button *ngIf="episode.Status_ID == episodeStatusIDs.readyForClaim" mat-button (click)="openAddPDFModal(episode)" style="display:flex; justify-content: center; align-items: center; background-color: #DFDFDF; border-radius: 10px; height: 26px; width: 75px; font: normal normal normal 10px/13px Roboto;">
                                    <div>Upload Claim</div>
                                </button>
                            </ng-template>

                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="denial files">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let episode" style="padding-left: 0;">
                        <div style="display:flex">
                                <div style="display:flex; justify-content: start; width: 40px">
                                    <a *ngIf="episode.hasDenial == true" routerLink="./ERAViewerForDenial/{{episode.EpisodeMaster_ID}}">
                                        <img src="assets/image/Denial_icon.png" height="24px" style="cursor: pointer" />
                                    </a>
                                </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="additional doc">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 0px; text-align: center;">Additional Doc</th>
                    <td mat-cell *matCellDef="let episode" style="padding-left: 0px;">
                        <div style="margin-top: -20px;">
                            <div class="row" style="margin-bottom: 15px; width: 50px;">
                                <div *ngIf="user.ValidatorFlag" style="float: left;">
                                    <a *ngIf="episode.hasDocsToReview"><img src="assets/image/Review_icon.png" height="10px" style="cursor: pointer" /></a>
                                </div>
                                <div *ngIf="!user.ValidatorFlag && !episode.hasDeletedFilesReviewed" style="float: left;">
                                    <a *ngIf="episode.hasDeletedFiles"><img src="assets/image/Notesunreviewed_icon.png" (click)="showNotesModal(episode)" height="10px" style="cursor: pointer" /></a>
                                </div>
                                <div *ngIf="!user.ValidatorFlag && episode.hasDeletedFilesReviewed" style="float: left;">
                                    <a *ngIf="episode.hasDeletedFiles"><img src="assets/image/NotesReviewed_icon.png" (click)="showNotesModal(episode)" height="10px" style="cursor: pointer" /></a>
                                </div>
                                <div *ngIf="!checkStatusIdForAdditionDoc(episode.Status_ID)" style="float: right;">
                                    <a *ngIf="episode.fileRecordCount < 10"><img src="assets/image/AddFile_icon.png" height="10px" style="cursor: pointer" (click)="openAddAdditionalDocModal(episode)"/></a>
                                </div>
                            </div>
                            <div style="width: 50px; display: block;">
                                <div style="margin-left: 33%;">
                                    <a *ngIf="episode.hasAdditionalDoc" (click)="openFile(episode)">
                                        <img src="assets/image/ViewDoc_icon.png" height="24px" style="cursor: pointer" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                    </td>
                </ng-container>

                <ng-container matColumnDef="secondary doc">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 0px; text-align: center;">Secondary Claim</th>
                    <td mat-cell *matCellDef="let episode" style="padding-left: 0px;">
                        <div>
                            <div class="row" style=" width: 100%;">
                                <div *ngIf="!user.ValidatorFlag" style="padding-left: 34%;">
                                    <a *ngIf="episode.hasSecondaryClaimReviewed"><img src="assets/image/Review_icon.png" height="10px" style="cursor: pointer" /></a>
                                </div>
                                <div *ngIf="user.ValidatorFlag && checkStatusIdForSecondaryClaim(episode.Status_ID) && episode.hasSecondaryClaim != true">
                                    <a><img src="assets/image/AddFile_icon.png" height="10px" style="cursor: pointer; padding-left: 45%;" (click)="openAddSecondaryClaimModal(episode)"/></a>
                                </div>
                            </div>
                            <div style="width: 50px; display: block;">
                                <div style="margin-left: 70%;">
                                    <a *ngIf="episode.hasSecondaryClaim" (click)="openSecondaryClaimFileViewer(episode)">
                                        <img src="assets/image/SecClaim.png" height="24px" style="cursor: pointer" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                    </td>
                </ng-container>

                <ng-container matColumnDef="bundle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Bundle </th>
                    <td mat-cell *matCellDef="let episode">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div>
                                {{this.mapBundleIDToBundle(episode.Bundle_ID)?.Bundle_Name}}
                            </div>

                            <a *ngIf="episode.Status_ID === episodeStatusIDs.processingClaim && user.ValidatorFlag" title="Upload to Kareo" (click)="uploadToKareo(episode)">
                                <span class="upload-file-icon"></span>
                            </a>

                            <a *ngIf="(episode.Status_ID === episodeStatusIDs.pendingApproval || episode.Status_ID === episodeStatusIDs.readyForClaim || episodeStatusIDs.surgeryScheduled) && user.ValidatorFlag" routerLink="./editEpisode/{{episode.EpisodeMaster_ID}}">
                                <mat-icon style="color: var(--primary-main)">
                                    edit
                                </mat-icon>
                            </a>

                            <a *ngIf="(episode.Status_ID === episodeStatusIDs.pendingApproval || episode.Status_ID === episodeStatusIDs.readyForClaim) && !user.ValidatorFlag" routerLink="./editEpisode/{{episode.EpisodeMaster_ID}}">
                                <mat-icon style="color: var(--primary-main)">
                                    visibility
                                </mat-icon>
                            </a>

                        </div>

                    </td>
                </ng-container>

                <ng-container matColumnDef="filter-checkBox">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-episodeId">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 72px">
                            <mat-label>ID</mat-label>
                            <input matInput (input)="episodeIdFilterChange($event)">
                          </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-patientName">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 130px">
                            <mat-label>Name</mat-label>
                            <input matInput (input)="patientNameFilterChange($event)">
                          </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-patientId">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 118px">
                            <mat-label>Medicare ID</mat-label>
                            <input matInput (input)="patientIdFilterChange($event)" [value]="patientIdValue">
                          </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-physician">
                    <th mat-header-cell *matHeaderCellDef > 
                    <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 118px">
                        <mat-label>Physician</mat-label>
                        <input matInput (input)="physicianFilterChange($event)">
                        </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-surgeryDate">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 150px">
                            <mat-label>Range</mat-label>
                            <mat-date-range-input [rangePicker]="picker" >
                                <input matStartDate placeholder="Start date" [(ngModel)]="sharedData.last28Days" (dateChange)="surgeryBeginChange($event.value)">
                                <input matEndDate placeholder="End date" [(ngModel)]="sharedData.todayDate" (dateChange)="surgeryEndChange($event.value)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </th>
                </ng-container>

                <!-- <ng-container matColumnDef="filter-surgeryDate">
                    <th mat-header-cell *matHeaderCellDef >
                        <div style="display: flex">
                            <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 118px">
                                <mat-label>Start</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="sharedData.last28Days" (dateChange)="surgeryBeginChange($event.value)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 118px; padding-left: 15px;">
                                <mat-label>End</mat-label>
                                <input matInput [matDatepicker]="picker2" (dateChange)="surgeryEndChange($event.value)">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </th>
                </ng-container> -->


                <ng-container matColumnDef="filter-status">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" >
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="sharedData.statusId" (selectionChange)="statusChange($event.value)">
                                <mat-option>--</mat-option>
                                <mat-option *ngFor="let status of episodeStatuses | visibleEpisodeStatus" [value]="status.EpisodeStatus_ID">
                                    {{status.EpisodeStatus_Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-empty">
                    <th mat-header-cell *matHeaderCellDef >
                    </th>
                </ng-container>

                <ng-container matColumnDef="denial-file-filter-empty">
                    <th mat-header-cell *matHeaderCellDef >
                    </th>
                </ng-container>

                <ng-container matColumnDef="add-doc-filter-empty">
                    <th mat-header-cell *matHeaderCellDef>
                        <div *ngIf="user.ValidatorFlag">
                            <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 50px;">
                                <mat-label>Review</mat-label>
                                <mat-select (selectionChange)="reviewStatusChange($event.value)">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let status of reviewStatus" [value]="status.review">
                                        {{status.reviewName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="!user.ValidatorFlag">
                            <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 50px;">
                                <mat-label>Note</mat-label>
                                <mat-select (selectionChange)="reviewStatusChangeForDeletedFile($event.value)">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let status of reviewStatus" [value]="status.review">
                                        {{status.reviewName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                    </th>
                </ng-container>

                <ng-container matColumnDef="sec-filter-empty">
                    <th mat-header-cell *matHeaderCellDef >
                        <div *ngIf="user.ValidatorFlag">
                            <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 50px;">
                                <mat-label>Review</mat-label>
                                <mat-select (selectionChange)="reviewStatusForSecondaryClaimFiles($event.value)">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let status of reviewStatus" [value]="status.review">
                                        {{status.reviewName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-bundle">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom filter-headers" style="width: 100%">
                            <mat-label>Bundle</mat-label>
                            <mat-select (selectionChange)="bundleFilterChange($event.value)">
                                <mat-option>--</mat-option>
                                <mat-option *ngFor="let bundle of this.bundles" [value]="bundle.Bundle_ID">
                                    {{bundle.Bundle_Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </th>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="['filter-checkBox', 'filter-episodeId', 'filter-patientName', 'filter-patientId', 'filter-physician', 'filter-surgeryDate', 'filter-status', 'filter-empty', 'denial-file-filter-empty', 'add-doc-filter-empty', 'sec-filter-empty', 'filter-bundle']; sticky: true" style="background-color: white;"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{'background-color': lineSummarySelectionModel.isSelected(row) ? 'var(--primary-menuButton)' : 'initial'}"></tr>

            </table>
            
        </div>
        <div>
            <mat-paginator (page)="loadEpisodeLineSummaries(true)" [length]="dataSource.totalCount | async" [pageSizeOptions]="[50, 100, 250]"></mat-paginator>
        </div>
    </div>

    <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

</div>

<router-outlet [hidden]="this.activeRouter.children.length == 0"></router-outlet>
