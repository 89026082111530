import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { ApiService } from '../api.service';
import { Practice } from '../NewDomain/Practice';
import { SupportEmail } from '../NewDomain/SupportEmail';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export const phoneEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  //At least one of them must be checked
  if (control.get("phoneCheckbox")?.value == true)
    return null;

  if (control.get("emailCheckbox")?.value == true)
    return null;

  return { phoneEmailNotChecked: true }
};

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.css']
})
export class SupportPageComponent implements OnInit {

  private static phoneNumberRegex = "^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$"

  practices: Practice[] = [];
  loadSpinner = 0
  userEmail = ""
  spinner = false
  contactPhone:boolean = false;
  contactEmail:boolean = false;
  validator = false;
  private readonly _destroying$ = new Subject<void>();
 
  form = new FormGroup({
    practice: new FormControl("", Validators.required),
    emailText: new FormControl("", Validators.required),
    phoneNumber: new FormControl({value: "", disabled: 'true'}, [Validators.required, Validators.pattern(SupportPageComponent.phoneNumberRegex)]),
    phoneEmailGroup: new FormGroup({
      phoneCheckbox: new FormControl(""),
      emailCheckbox: new FormControl("")
    }, {validators: phoneEmailValidator})
  });
 
  constructor(private api:ApiService, private authService: MsalService, private snackBar: MatSnackBar, private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {

    this.loadSpinner++
    this.api.getPractices(
          (res) => {
            this.practices = res

            if (this.practices.length == 1) {
              this.form.get("practice")?.setValue(this.practices[0].Practice_ID)
            }

            this.loadSpinner--
          },
          (err) => { 
            this.snackBar.open("Could not load practices due to error.", "Dismiss");
            this.loadSpinner--
      })

    this.form.get('phoneNumber')?.disable()

    this.loadSpinner++
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.userEmail = this.authService.instance.getActiveAccount()?.idTokenClaims?.email as string
        this.loadSpinner--
      })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.form.valid) {
      this.loadSpinner++

      let supportEmail:SupportEmail = { practiceID: this.form.get("practice")?.value,
                                        information: this.form.get("emailText")?.value}
      
      if (this.form.get("phoneEmailGroup.emailCheckbox")?.value)
        supportEmail.contactByEmail = this.userEmail

      if (this.form.get("phoneEmailGroup.phoneCheckbox")?.value)
        supportEmail.contactByPhoneNumber = this.form.get("phoneNumber")?.value

      this.api.postSupportEmail(supportEmail, 
      (resp) => {
        this.snackBar.open("Support request successfully sent!", "Dismiss", {duration: 3000});
        this.loadSpinner--
      }, 
      (err) => {
        this.snackBar.open("Support request could not be sent due to error.", "Dismiss");
        this.loadSpinner--
      })
    }
  }

}
