import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EpisodeLineSummary } from 'src/app/domain/episodeLineSummary';
import { ApiService } from 'src/app/api.service';
import { EpisodeStatusIDs } from '../EpisodeStatuses';
import { Episode } from 'src/app/NewDomain/Episode';
import { compare } from 'fast-json-patch';

@Component({
    selector: 'app-add-denial-modal',
    templateUrl: './add-denial-modal.component.html',
    styleUrls: ['./add-denial-modal.component.css']
})

export class AddDenialModalComponent implements OnInit {

    fileToUpload: File = new File([], "")
  errorMessage: string = "";
  showError = false;
  loadSpinner = false;
  missing1500 = false;
  selectedEpisodes: EpisodeLineSummary[];
  userDetails: any;

    form = new FormGroup({
        note: new FormControl(null),
        bundleRiskOption: new FormControl(null, Validators.required)
    });

  constructor(
    public dialogRef: MatDialogRef<AddDenialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api:ApiService, private snackBar: MatSnackBar){}

  ngOnInit(): void {
    this.selectedEpisodes = this.data.selectedEpisode;
    this.userDetails = this.data.userDetails;
    //Is there a way to do this in the ngFor?
    for (let episode of this.selectedEpisodes) {
      if (!episode.has1500 || episode.hasDenial) {
        this.missing1500 = true
        break
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  change(ev:Event) {
    this.fileToUpload = (ev.target as HTMLInputElement).files?.item(0) as File
  }

  dropHandler(ev:DragEvent) {
    ev.preventDefault();

    let theFile: File = ev.dataTransfer?.files[0] as File

    if (theFile.type != "application/pdf") {
      return;
    }

    this.fileToUpload = theFile;
  }

  dragOverHandler(ev:DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  submit() {
    if (this.fileToUpload && this.form.valid) {
      this.loadSpinner = true;

      let episodeIdList: number[] = []

      for (let episode of this.selectedEpisodes) {
        episodeIdList.push(episode.EpisodeMaster_ID)
      }
      
      let note = this.form.get("note")?.value;
      let bundleRiskOption = this.form.get("bundleRiskOption")?.value;
      console.log(bundleRiskOption);

      this.api.denialUploadFunction(episodeIdList, bundleRiskOption, note, this.userDetails.UserName, this.userDetails.UserAD_ID, this.fileToUpload, 
        (retVal) => { //Success
          this.snackBar.open("Denial successfully uploaded!", "Dismiss", {duration: 3000});
          this.dialogRef.close(true);
        },
        (err) => { //Error
          this.errorMessage = err.message;
          this.showError = true;
          this.loadSpinner = false;
        });
    }
  }

}