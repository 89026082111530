<div class="position-relative">
    <div *ngIf="!autoLogout">
        <button mat-icon-button class="modal-close-button close-icon" (click)="close(true)">
            <mat-icon>close</mat-icon>
        </button> 
    </div>
    <div>
        <div>
            <h2 mat-dialog-title style="margin-top: 0px;">
                Session Timeout
            </h2>
        </div>
        <div *ngIf="!autoLogout">
            Your session will time out in {{timerCounter - 5}} sec. Would you like to stay logged in?
        </div>

        <div *ngIf="autoLogout">
            You have been logged out due to inactivity
        </div>

        <div class="option-buttons" *ngIf="!autoLogout">
            <button mat-raised-button class="width-122px" color="accent" (click)="close(true)">Yes</button>
            <button mat-raised-button class="width-122px margin-left-10px" color="accent" (click)="close(false)">No</button>
        </div>
         
    </div>
</div>