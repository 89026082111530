import {NgModule} from "@angular/core";
import {VisibleEpisodeStatusPipe} from "./pipe/visible-episode-status.pipe";
import {ApplicableEpisodeStatusesPipe} from "./pipe/applicable-episode-statuses.pipe";

@NgModule({
    imports: [],
    declarations: [
      VisibleEpisodeStatusPipe,
      ApplicableEpisodeStatusesPipe
    ],
    exports: [
      VisibleEpisodeStatusPipe,
      ApplicableEpisodeStatusesPipe
    ]
})
export class SharedModule { }