<div class="page-wrapper" style="display:flex; flex-direction: column; height: 100%">
    <div class="page-title">{{additionDocData.FileTypeName}} File</div>
    <mat-divider class="page-divider" style="margin-bottom: 15.5px;"></mat-divider>

    <div style="flex: 1; display:flex; flex-direction: column; position: relative">
        <app-loading-spinner *ngIf="pdfDocumentData?.loadSpinnerPDF"></app-loading-spinner>

        <div style="display:flex; flex: 1; justify-content: space-between;">
                <div style="font: normal normal normal 14px/30px Roboto; flex-direction: column; justify-content: space-between; width: 400px;">

                    <div style="margin-bottom: 30px;">
                        <div>Episode ID: {{this.episodeDetails.EpisodeMaster_ID}}</div>
                        <div><span style="font-weight: bold">Physician</span>: {{this.episodeDetails.PhysFirstName}} {{this.episodeDetails.PhysLastName}}</div>
                        <div style="font-weight: bold">Bundle Selected:</div>
                        <div>{{this.episodeDetails.Bundle_Name}}</div>
                        <div style="font-weight: bold">Procedure Description:</div>
                        <div>{{this.episodeDetails.Procedure_Desc}}</div>
                    </div>
                
                    <div style="margin-bottom: 30px;">
                        <div style="font-weight: bold">Facility:</div>
                        <div>{{this.episodeDetails.Facility_Name}}</div>
                        <div><span style="font-weight: bold">Place of Service:</span> {{this.episodeDetails.PlaceOfService_TechnicalID}}, {{this.episodeDetails.PlaceOfService_DisplayName}}</div>
                        <div><span style="font-weight: bold">Target Price: </span> 
                            <span *ngIf="this.HasTargetPrice(); else NoTargetPrice">{{this.episodeDetails.TargetPrice | currency:"USD"}}</span>
                            <ng-template #NoTargetPrice><span style="color:red">None</span></ng-template>
                        </div>
                        <div><span style="font-weight: bold">CJR: </span> <span [ngStyle]="{'color': this.episodeDetails.isCJR ? 'red' : 'auto'}">{{this.episodeDetails.isCJR ? "Yes" : "No"}}</span></div>
                    </div>
                
                    <div style="margin-bottom: 30px;">
                        <div style="font-weight: bold">Patient Information</div>
                        <div>{{this.episodeDetails.PatFirstName}} {{this.episodeDetails.PatLastName}}, {{this.episodeDetails.PatDOB | date:"MM/dd/yy":"UTC"}}</div>
                        <div>Medicare ID: {{this.episodeDetails.MedicareID}}</div>
                    </div>
                </div>


            <div style="display: flex; flex-direction: column; position: relative; left: -140px;" width="600px">
                <div *ngIf="!pdfDocumentData?.errorMessagePDF; else pdfError" style="display: flex; flex: 1; flex-direction: column">
                    <div style="position: relative">
                        <app-loading-spinner *ngIf="pdfDocumentData?.loadSpinnerPDF"></app-loading-spinner>
                        <embed [src]="pdfDocumentData?.safePDFUrl" type='application/pdf' width="600px" height="660px" />
                    </div>
                    <div style="display: flex; flex-direction: column; position: relative; font-size: 14px;">
                        <div style="width: 250px; margin-top: 15px; margin-bottom: 15px;">           
                                <div>
                                    <div><span style="font-weight: bold">File Name</span>: {{pdfDocumentData.downloadFileName}} </div>
                                    <div><span style="font-weight: bold">Added Date</span>: {{additionDocData[0].AddedDate | date:"MM/dd/yy":"UTC"}} </div>
                                    <div><span style="font-weight: bold">Added By</span>: {{additionDocData[0].AddedByName}} </div>
                                    <div><span style="font-weight: bold">Note</span>: {{additionDocData[0].Notes}} </div>
                                    <div>
                                        <span style="font-weight: bold">File Type</span>
                                        <mat-form-field class="form-field-no-bottom pdf-modal-file-input" style="width: 160px; font-size: 11px; margin-left: 10px;">
                                            <mat-select [(ngModel)]="additionDocData[0].FileType" [disabled]="!this.user.ValidatorFlag" (selectionChange)="fileTypeChange($event.value)">
                                                <mat-option *ngFor="let fileType of fileTypes" [value]="fileType.typeId">
                                                    {{fileType.typeName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                        </div>
                        <div *ngIf="pdfDocumentData.localPDFUrl != ''" style="flex: 1; display: flex; justify-content: center; width: 50px; margin-left: 275px; margin-top: -105px;">
                            <a [href]="pdfDocumentData?.safePDFUrl" [download]="pdfDocumentData.downloadFileName" style="color: inherit; position: relative;  width: 48px;">
                                <mat-icon style="font-size: 48px; width: 48px; margin-bottom: 25px">file_download</mat-icon>
                            </a>
                        </div>
                        <div *ngIf="this.user.ValidatorFlag" style="margin-left: 490px; width: 150px; margin-top: -50px;">
                            <mat-checkbox [checked]="additionDocData[0].IsReviewed" [disabled]="!this.user.ValidatorFlag" style="margin-right: 10px" (change)="esReviewedChecked($event)"></mat-checkbox>
                            <span>ES Reviewed</span>
                        </div>

                        <div style="margin-left: 330px; margin-top: 40px;" *ngIf="this.user.ValidatorFlag">
                            <div>
                                <span>Delete File</span>
                                <div>
                                    <span>Reason: </span>
                                    <mat-form-field class="form-field-no-bottom pdf-modal-file-input" style=" font-size: 11px;">
                                        <mat-label><i>Type delete reason here</i></mat-label>
                                        <input matInput [(ngModel)]="fileDeleteReason"/>
                                    </mat-form-field>
                                    <button mat-raised-button color="accent" style="width: 70px; line-height: 25px; top: -3px; margin-left: 10px;" [disabled]="fileDeleteReason == ''" (click)="deleteDocFile()">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style="display: flex; align-items: flex-end; margin-top: 90px; margin-left: -15px;">
                            <app-back-button></app-back-button>
                            <!-- <div>
                                <button mat-button (click)="returnBack()" style="z-index: 9999;" type="button">Go Back</button>
                            </div> -->
                        </div>       
                        <div style="flex: 1"></div>
                    </div>
                    
                </div>

                <ng-template #pdfError>
                    <div height="100%" width="100%">
                        Could not download PDF<br><br>

                        Server Error: {{pdfDocumentData?.errorMessagePDF}}
                    </div>
                </ng-template>
            </div>

        </div>

    </div>

    <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

</div>

