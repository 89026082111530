<div style="position: relative;">
    <div>
        <button mat-icon-button class="modal-close-button" style="position: absolute;" (click)="close()">
            <mat-icon>close</mat-icon>
        </button> 
    </div>
    <div>
        <div>
            <h2 mat-dialog-title style="margin-top: 0px; font-weight: 600;">
                Additional Documents
            </h2>
        </div>

        <div>
            <table style="width: 450px;">
                <tr>
                    <th class="first-col-header text-align-left">
                        <span>Reviewed</span>
                    </th>
                    <th class="second-col-header text-align-left border-styling">
                        <span>Uploaded</span>
                    </th>
                    <th class="second-col-header text-align-left">
                        <span>File Type</span>
                    </th>
                </tr>
                <tr *ngFor="let docList of additionalDocList">
                    <td style="width: 20%;">
                        <div class="text-align-center" *ngIf="docList.IsReviewed">
                            <a><img src="assets/image/Tick_icon.png" height="10px" /></a>
                        </div>
                    </td>
                    <td class="second-col-header font-category cursor-pointer border-styling" (click)="routeToViewer(docList.AddDoc_Id)">
                        {{docList.fileName}}
                    </td>
                    <td class="second-col-header font-category cursor-pointer" (click)="routeToViewer(docList.AddDoc_Id)">
                        {{docList.fileTypeName}}
                    </td>
                </tr>
            </table>
        </div>

        <div style="display: flex; width: 450px; margin-top: 40px; margin-bottom: 11px; justify-content: end;">
            <button mat-button style="width: 122px; background-color: rgba(0, 0, 0, 0.12); color: rgba(0, 0, 0, 0.87);" (click)="close()">Cancel</button>
        </div>   
    </div>
</div>