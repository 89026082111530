<div style="position: relative;">
    <div>
        <button mat-icon-button class="modal-close-button" style="position: absolute;" (click)="close()">
            <mat-icon>close</mat-icon>
        </button> 
    </div>
    <div>
        <div>
            <h2 mat-dialog-title style="margin-top: 0px; font-weight: 600;">
                Episode Status Activity Log
            </h2>
        </div>

        <div>
            <div style="max-height: 500px; overflow-y: auto;">
                <table style="width: 100%;">
                    <tr class="header-row-bgc">
                        <th class="first-col-header text-align-left font-weight-500">
                            <span>Date</span>
                        </th>
                        <th class="second-col-header text-align-left border-styling font-weight-500">
                            <span>Status Change</span>
                        </th>
                        <th class="second-col-header text-align-left font-weight-500">
                            <span>Changed By</span>
                        </th>
                    </tr>
                    <tr *ngFor="let activityList of this.episodeStatusActivity">
                        <td>
                            {{activityList.ChangedDate}}
                        </td>
                        <td>
                            {{activityList.EpisodeStatus_Name}}
                        </td>
                        <td>
                            {{activityList.UserEmail}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>