import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import {Router} from "@angular/router"
import { AuthError } from 'msal';
import { environment } from '../environments/environment';
import { EpisodeCancellationReason } from './json-mappings/episodeReason';
import { EpisodeLineSummaryDTO } from './NewDomain/episodeLineSummary';
import { EpisodeLineSummaryQuery } from './patient-episode-manager-page/EpisodeLineSummaryQuery';
import { Practice } from './NewDomain/Practice';
import { Bundle } from './NewDomain/Bundle';
import { Facility } from './NewDomain/Facility';
import { Physician } from './NewDomain/Physician';
import { PatientEpisodePostRequest } from './add-new-episode-page/patientEpisodePostRequest';
import { ValidationResult } from './NewDomain/ValidationResult';
import { PatientEpisodeGetRequest } from './add-new-episode-page/patientEpisodeGetRequest';
import { EpisodeDetailsResponseDTO } from './NewDomain/EpisodeDetails';
import { EpisodeStatus } from './NewDomain/EpisodeStatus';
import { EpisodeNote } from './NewDomain/EpisodeNote';
import { UserAD } from './NewDomain/UserAD';
import { FileManagerDTO } from './NewDomain/FileManagerRow';
import { FileManagerQuery } from './files-page/FileManagerQuery';
import { ACHFile } from './NewDomain/ACHFile';
import { ACHFilesQuery, ERAFilesQuery, FacilityQuery, PhysicianQuery, UserADQuery } from './apiQueryInterfaces';
import { ERAFile } from './NewDomain/ERAFile';
import { ERAAssociatedEpisode } from './NewDomain/ERAAssociatedEpisode';
import { SupportEmail } from './NewDomain/SupportEmail';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface claimUploadSuccessResponse {
  uri: string
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private authService: MsalService, private http: HttpClient, private router: Router ) { }

  errorCallback(err: any, elseErrorCallback: (err: any) => void) {
    if (err instanceof AuthError) {
      this.router.navigate(['/dashboard']); //This will force them to have to login and begin on dashboard again
    }
    else {
      elseErrorCallback(err);
    }
  }

  getPractices(successCallback: (resp: Practice[]) => void, errorCallback: (err: any) => void) {

    this.http.get(environment.apiUri + "/practice"
    ).subscribe({
      next: (resp) => { successCallback(resp as Practice[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }
  
  getACHFiles(query:ACHFilesQuery, successCallback: (resp: ACHFile[]) => void, errorCallback: (err: any) => void) {

    let params:HttpParams = new HttpParams()

    if (query){

      if (query.ids) {
        for (let i = 0; i < query.ids.length; ++i)
          params = params.append("id", query.ids[i].toString())
      }

      if (query.ERAIds) {
        for (let i = 0; i < query.ERAIds.length; ++i)
          params = params.append("eraID", query.ERAIds[i].toString())
      }

      if (query.episodeIds) {
        for (let i = 0; i < query.episodeIds.length; ++i)
          params = params.append("episodeID", query.episodeIds[i].toString())
      }
    }


    this.http.get(environment.apiUri + "/ACHFile", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as ACHFile[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getERAFiles(query:ERAFilesQuery, successCallback: (resp: ERAFile[]) => void, errorCallback: (err: any) => void) {

    let params:HttpParams = new HttpParams()

    if (query){

      if (query.ids) {
        for (let i = 0; i < query.ids.length; ++i)
          params = params.append("id", query.ids[i].toString())
      }

      if (query.ACHIds) {
        for (let i = 0; i < query.ACHIds.length; ++i)
          params = params.append("achID", query.ACHIds[i].toString())
      }

      if (query.episodeIDsForUnassigned) {
        for (let i = 0; i < query.episodeIDsForUnassigned.length; ++i)
          params = params.append("episodeIDForUnassigned", query.episodeIDsForUnassigned[i].toString())
      }
    }

    this.http.get(environment.apiUri + "/ERAFile", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as ERAFile[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getEpisodeCancellationReasons(successCallback: (resp: EpisodeCancellationReason[]) => void, errorCallback: (err: any) => void) {

    this.http.get(environment.apiUri + "/episodecancellationreason"
    ).subscribe({
      next: (resp) => { successCallback(resp as EpisodeCancellationReason[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getPhysicians(successCallback: (resp: Physician[]) => void, errorCallback: (err: any) => void, query?:PhysicianQuery) {

    let params:HttpParams = new HttpParams()

    if (query){
      if (query.limit)
        params = params.set("limit", query.limit.toString())

      if (query.offset)
        params = params.set("offset", query.offset.toString())

      if (query.ids) {
        for (let i = 0; i < query.ids.length; ++i)
          params = params.append("id", query.ids[i].toString())
      }

      if (query.Practice_IDs) {
        for (let i = 0; i < query.Practice_IDs.length; ++i)
          params = params.append("Practice_ID", query.Practice_IDs[i].toString())
      }

      if (query.with) {
        for (let i = 0; i < query.with.length; ++i)
          params = params.append("with", query.with[i])
      }
    }

    this.http.get(environment.apiUri + "/physician", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as Physician[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getBundles(successCallback: (resp: Bundle[]) => void, errorCallback: (err: any) => void, physicianID?:number) {

    let params:HttpParams = new HttpParams()

    if (physicianID)
      params = params.set('Physician_ID', physicianID.toString())

    this.http.get(environment.apiUri + "/bundle", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as Bundle[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getEpisodeStatuses(successCallback: (resp: EpisodeStatus[]) => void, errorCallback: (err: any) => void, physicianID?:number) {
    this.http.get(environment.apiUri + "/EpisodeStatus"
    ).subscribe({
      next: (resp) => { successCallback(resp as EpisodeStatus[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getFacilities(successCallback: (resp: Facility[]) => void, errorCallback: (err: any) => void, query?:FacilityQuery) {

    let params:HttpParams = new HttpParams()

    if (query){
      if (query.limit)
        params = params.set("limit", query.limit.toString())

      if (query.offset)
        params = params.set("offset", query.offset.toString())

      if (query.ids) {
        for (let i = 0; i < query.ids.length; ++i)
          params = params.append("id", query.ids[i].toString())
      }

      if (query.Practice_IDs) {
        for (let i = 0; i < query.Practice_IDs.length; ++i)
          params = params.append("Practice_ID", query.Practice_IDs[i].toString())
      }

      if (query.Physician_IDs) {
        for (let i = 0; i < query.Physician_IDs.length; ++i)
          params = params.append("Physician_ID", query.Physician_IDs[i].toString())
      }
    }

    this.http.get(environment.apiUri + "/facility", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as Facility[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  postPatientAndEpisode(successCallback: (resp: ValidationResult) => void, errorCallback: (err: any) => void, patientEpisode:PatientEpisodePostRequest) {

    this.http.post(environment.apiUri + "/patientepisode", patientEpisode
    ).subscribe({
      next: (resp) => { successCallback(resp as ValidationResult); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  postSupportEmail(supportEmail:SupportEmail, successCallback: (resp: ValidationResult) => void, errorCallback: (err: any) => void) {

    this.http.post(environment.apiUri + "/SupportEmail", supportEmail
    ).subscribe({
      next: (resp) => { successCallback(resp as ValidationResult); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getEpisodeNotes(successCallback: (resp: EpisodeNote[]) => void, errorCallback: (err: any) => void, episodeID:number) {

    let params:HttpParams = new HttpParams()
    params = params.set('Episode_ID', episodeID.toString())

    this.http.get(environment.apiUri + "/episodenote", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as EpisodeNote[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  postEpisodeNote(successCallback: (resp: EpisodeNote) => void, errorCallback: (err: any) => void, EpisodeID: number, Note: string) {

    let request:any = {
      Episode_ID: EpisodeID,
      Note: Note
    }

    this.http.post(environment.apiUri + "/episodenote", request
    ).subscribe({
      next: (resp) => { successCallback(resp as EpisodeNote); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  patchPatientAndEpisode(successCallback: (resp: ValidationResult) => void, errorCallback: (err: any) => void, episodeID:number, patientEpisodePatch:any) {

    this.http.patch(environment.apiUri + "/patientepisode/" + episodeID.toString(), patientEpisodePatch, { headers: {'Content-Type': 'application/json-patch+json'} }
    ).subscribe({
      next: (resp) => { successCallback(resp as ValidationResult); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  patchEpisodeERAJoin(successCallback: (resp: ValidationResult) => void, errorCallback: (err: any) => void, id:number, patch:any) {

    this.http.patch(environment.apiUri + "/EpisodeERAJoin/" + id.toString(), patch, { headers: {'Content-Type': 'application/json-patch+json'} }
    ).subscribe({
      next: (resp) => { successCallback(resp as ValidationResult); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getPatientAndEpisode(successCallback: (resp: PatientEpisodeGetRequest) => void, errorCallback: (err: any) => void, episodeID:number) {

    let params:HttpParams = new HttpParams()

    this.http.get(environment.apiUri + "/patientepisode/" + episodeID.toString()
    ).subscribe({
      next: (resp) => { successCallback(resp as PatientEpisodeGetRequest); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getUserADSelf(): Observable<UserAD> {
    return this.http.get<UserAD>(environment.apiUri + "/UserAD/self");
  }

  getUserAD(query:UserADQuery, successCallback: (resp: UserAD[]) => void, errorCallback: (err: any) => void) {

    let params:HttpParams = new HttpParams()

    if (query){

      if (query.ids) {
        for (let i = 0; i < query.ids.length; ++i)
          params = params.append("id", query.ids[i].toString())
      }

      if (query.Practice_IDs) {
        for (let i = 0; i < query.Practice_IDs.length; ++i)
          params = params.append("Practice_ID", query.Practice_IDs[i].toString())
      }

      if (query.UAD_IDs) {
        for (let i = 0; i < query.UAD_IDs.length; ++i)
          params = params.append("UAD_ID", query.UAD_IDs[i].toString())
      }
    }

    this.http.get(environment.apiUri + "/UserAD", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as UserAD[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  patchEpisode(successCallback: (resp: ValidationResult) => void, errorCallback: (err: any) => void, episodeID:number, episodePatch:any) {

    this.http.patch(environment.apiUri + "/episode/" + episodeID.toString(), episodePatch , { headers: {'Content-Type': 'application/json-patch+json'} }
    ).subscribe({
      next: (resp) => { successCallback(resp as ValidationResult); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  cancelEpisode(episodeId:number, cancellationReasonId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {

    let body = {
      "CancellationReason_Id": cancellationReasonId
    };

    this.http.post(environment.apiUri + "/episode/" + episodeId.toString() + "/cancel", body
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getEpisodeLineSummaries(successCallback: (success: EpisodeLineSummaryDTO) => void, errorCallback: (err: any) => void, 
                          lineQuery:EpisodeLineSummaryQuery) {

    let params:HttpParams = new HttpParams()

    if (lineQuery.episodeID)
      params = params.set('episodeID', lineQuery.episodeID)

    if (lineQuery.patientName)
      params = params.set('patientName', lineQuery.patientName)

    if (lineQuery.medicareID)
      params = params.set('medicareID', lineQuery.medicareID)
    
    if (lineQuery.surgeryStartDate)
      params = params.set('surgeryStartDate', lineQuery.surgeryStartDate.getUTCMonth()+1 + "-" + lineQuery.surgeryStartDate.getUTCDate() + "-" + lineQuery.surgeryStartDate.getUTCFullYear())

    if (lineQuery.surgeryEndDate)
      params = params.set('surgeryEndDate', lineQuery.surgeryEndDate.getUTCMonth()+1 + "-" + lineQuery.surgeryEndDate.getUTCDate() + "-" + lineQuery.surgeryEndDate.getUTCFullYear())

    if (lineQuery.practiceID)
      params = params.set('practiceID', lineQuery.practiceID)

    if (lineQuery.physicianID)
      params = params.set('physician', lineQuery.physicianID)

    if (lineQuery.statusID)
      params = params.set('statusID', lineQuery.statusID.toString())

    if (lineQuery.bundleID)
      params = params.set('bundleID', lineQuery.bundleID.toString())

    if (lineQuery.offset != undefined)
      params = params.set('offset', lineQuery.offset.toString())

    if (lineQuery.limit != undefined)
      params = params.set('limit', lineQuery.limit.toString())

    if (lineQuery.orderBy)
      params = params.set('orderBy', this.GetEpisodeLineSummariesOrderByMap.get(lineQuery.orderBy) ?? "")

    if (lineQuery.sortDesc != undefined)
      params = params.set('sortDesc', lineQuery.sortDesc.toString())

    if(lineQuery.reviewed != undefined)
      params = params.set('reviewed', lineQuery.reviewed.toString())

    if(lineQuery.deletedFileReviewed != undefined)
      params = params.set('deletedDocReviewed', lineQuery.deletedFileReviewed.toString())

    if(lineQuery.secondaryClaimFileReviewed != undefined)
      params = params.set('secondaryClaimFileReviewed', lineQuery.secondaryClaimFileReviewed.toString())

    this.http.get(environment.apiUri + "/EpisodeLineSummary", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as EpisodeLineSummaryDTO) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  private GetEpisodeLineSummariesOrderByMap:Map<string, string> = new Map([
    ["id", "episodeID"],
    ["patientName", "PatientName"],
    ["ptPayorId", "medicareID"],
    ["surgeon", "surgeon"],
    ["surgeryDate", "surgeryDate"],
    ["status", "episodeStatus"],
    ["bundle", "bundleName"]
  ])

  getFileManager(successCallback: (success: FileManagerDTO) => void, errorCallback: (err: any) => void, 
                                   lineQuery:FileManagerQuery) {

    let params:HttpParams = new HttpParams()

    if (lineQuery.episodeID != undefined)
      params = params.set('episodeID', lineQuery.episodeID)

    if (lineQuery.fileType != undefined)
      params = params.set('fileType', lineQuery.fileType.toString())

    if (lineQuery.medicareID != undefined)
      params = params.set('medicareID', lineQuery.medicareID)
    
    if (lineQuery.uploadStartDate != undefined)
      params = params.set('uploadStartDate', lineQuery.uploadStartDate.getUTCMonth()+1 + "-" + lineQuery.uploadStartDate.getUTCDate() + "-" + lineQuery.uploadStartDate.getUTCFullYear())

    if (lineQuery.uploadEndDate != undefined)
      params = params.set('uploadEndDate', lineQuery.uploadEndDate.getUTCMonth()+1 + "-" + lineQuery.uploadEndDate.getUTCDate() + "-" + lineQuery.uploadEndDate.getUTCFullYear())

    if (lineQuery.practiceID != undefined)
      params = params.set('practiceID', lineQuery.practiceID.toString())

    if (lineQuery.amount != undefined)
      params = params.set('amount', lineQuery.amount.toString())

    if (lineQuery.checkNumber != undefined)
      params = params.set('checkNumber', lineQuery.checkNumber)

    if (lineQuery.offset != undefined)
      params = params.set('offset', lineQuery.offset.toString())

    if (lineQuery.limit != undefined)
      params = params.set('limit', lineQuery.limit.toString())

    if (lineQuery.orderBy != undefined)
      params = params.set('orderBy', this.getFileManagerOrderByMap.get(lineQuery.orderBy) ?? "")

    if (lineQuery.sortDesc != undefined)
      params = params.set('sortDesc', lineQuery.sortDesc.toString())

    this.http.get(environment.apiUri + "/FileManager", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as FileManagerDTO) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  private getFileManagerOrderByMap:Map<string, string> = new Map([
    ["Amount", "amount"],
    ["CheckNumber", "checkNumber"],
    ["UploadDate", "addDate"]
  ])

  claimUploadFunction(id:string, file:File, successCallback: (retVal: claimUploadSuccessResponse) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();
    formData.append("EpisodeID", id);
    formData.append('ClaimFile', file, file.name);

    this.http.post(environment.apiUri + "/claimfile", formData,
    ).subscribe({
      next: (resp) => { successCallback(resp as claimUploadSuccessResponse) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  downloadClaimFileForEpisodeID(episodeId:number, setCallBack: (res:any) => void, errorCallback: (err: any) => void) {

    let params:HttpParams = new HttpParams()
    params = params.set('episodeID', episodeId.toString())

    this.http.get(environment.apiUri + "/claimfile/download", {params: params, headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { setCallBack(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  deleteFileManager(fileId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.delete(environment.apiUri + "/claimfile/" + fileId
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }
  
  storeDeletionReason(episodeId: string, reason: string, userName: string): Observable<any> {
    const url = `${environment.apiUri}/claimfile/delete?episodeID=${episodeId}&reason=${reason}&userName=${userName}`;
    return this.http.post<any>(url, { reason });
  }

  downloadACHFile(achID:number, setCallBack: (res:any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/achFile/" + achID.toString() + "/download", {headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { setCallBack(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getAssociatedEpisodesForERA(eraID:number, setCallBack: (res:ERAAssociatedEpisode[]) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/ERAAssociatedEpisodes/" + eraID.toString()
    ).subscribe({
      next: (resp) => { setCallBack(resp as ERAAssociatedEpisode[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  downloadERAFile(eraID:number, setCallBack: (res:any) => void, errorCallback: (err: any) => void) {

    this.http.get(environment.apiUri + "/eraFile/" + eraID.toString() + "/download", {headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { setCallBack(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  eraUploadFunction(episodeIDs:number[], payOrderId:number, checkNumber:string, amount:number, file:File, successCallback: (retVal: any) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();

    for(let i = 0; i < episodeIDs.length; ++i)
      formData.append('EpisodeID', episodeIDs[i].toString())

    formData.append('Amount', amount.toString())
    formData.append('CheckNumber', checkNumber)
    formData.append('PayerOrder', payOrderId.toString())
    formData.append('ERAFile', file, file.name);

    this.http.post(environment.apiUri + "/ERAFile", formData
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  achUploadFunction(eraIDs:number[], checkNumber:string, amount:number, file:File, successCallback: (retVal: any) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();

    for(let i = 0; i < eraIDs.length; ++i)
      formData.append('ERAID', eraIDs[i].toString())

    formData.append('Amount', amount.toString())
    formData.append('CheckNumber', checkNumber)
    formData.append('ACHFile', file, file.name);

    this.http.post(environment.apiUri + "/ACHFile", formData
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getEpisodeDetails(episodeId:number, setCallBack: (res:EpisodeDetailsResponseDTO) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/EpisodeDetails/" + episodeId.toString(),
    ).subscribe({
      next: (resp) => { setCallBack(resp as EpisodeDetailsResponseDTO); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  postPatientToKareo(episodeId: number, successCallback: (res: any) => void, errorCallback: (err:any) => void) {
    this.http.post(environment.apiUri + "/claimfile/posttokareo", episodeId).subscribe({
      next: (res) => {successCallback(res)},
      error: (err) => {this.errorCallback(err, errorCallback);}
    });
  }

  denialUploadFunction(episodeIDs:number[], bundleRiskOption: number, notes: string, UserName: string, UserAD_ID: number, file:File, successCallback: (retVal: any) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();

    for(let i = 0; i < episodeIDs.length; ++i)
      formData.append('EpisodeID', episodeIDs[i].toString())

    formData.append('BundleRiskOption', bundleRiskOption.toString())
    formData.append('Notes', notes)
    formData.append('UserName', UserName)
    formData.append('UserAD_ID', UserAD_ID.toString())
    formData.append('DenialFile', file, file.name);

    this.http.post(environment.apiUri + "/DenialFile", formData
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getDenialFiles(query:any, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {

    let params:HttpParams = new HttpParams();

    if (query){
      if (query.DenialIds && query.DenialIds.length) {
        for (let i = 0; i < query.DenialIds.length; ++i){
          params = params.append("id", query.DenialIds[i].toString());
        }
      }
    }

    this.http.get(environment.apiUri + "/DenialFile", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  downloadDenialFile(denialId:number, setCallBack: (res:any) => void, errorCallback: (err: any) => void) {

    this.http.get(environment.apiUri + "/DenialFile/" + denialId.toString() + "/download", {headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { setCallBack(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getDenialIdFromEpisodeId(query:any, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    let params:HttpParams = new HttpParams();

    if (query){
      if (query.EpisodeId && query.EpisodeId.length) {
        for (let i = 0; i < query.EpisodeId.length; ++i) {
          params = params.append("id", query.EpisodeId[i].toString());
        }
      }
    }

    this.http.get(environment.apiUri + "/DenialFile/" + query.EpisodeId[0] + "/ForEpisode"
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  uploadAdditionalDocuments(fileType:number, file:File, notes: string, episodeId: number, UserName: string, UserAD_ID: number, ValidatorFlag:boolean, successCallback: (retVal: any) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();

    formData.append('FileType', fileType.toString())
    formData.append('EpisodeID', episodeId.toString())
    formData.append('Notes', notes)
    formData.append('UserName', UserName)
    formData.append('UserAD_ID', UserAD_ID.toString())
    formData.append('ValidatorFlag', ValidatorFlag.toString())
    formData.append('AdditionalFile', file, file.name);

    this.http.post(environment.apiUri + "/AdditionalDocument", formData
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getAdditionalDocumentsForEpisodeId(episodeId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/AdditionalDocument/" + episodeId.toString(),
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getAdditionalDocumentsForAddDocId(addDocId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/AdditionalDocument/viewer/" + addDocId.toString(),
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  downloadAdditionalDoc(addDocId:number, setCallBack: (res:any) => void, errorCallback: (err: any) => void) {

    this.http.get(environment.apiUri + "/AdditionalDocument/" + addDocId.toString() + "/download", {headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { setCallBack(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  updateESReviewed(addDocId, isReviewed: number, successCallback, errorCallback: (err: any) => void) {
    let params:HttpParams = new HttpParams();

    params.set("addDocId", addDocId);
    params.set("reviewedStatus", isReviewed.toString());
    addDocId = Number(addDocId);

    this.http.get(environment.apiUri + "/AdditionalDocument/update/ESReviewed/"+addDocId+"/"+isReviewed
    ).subscribe({
      next: (resp) => { successCallback(); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  updateFileType(addDocId, fileType, successCallback, errorCallback: (err: any) => void) {
    let params:HttpParams = new HttpParams();

    params.set("addDocId", addDocId);
    params.set("fileType", fileType);
    addDocId = Number(addDocId);

    this.http.get(environment.apiUri + "/AdditionalDocument/update/fileType/"+addDocId+"/"+fileType
    ).subscribe({
      next: (resp) => { successCallback(); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  deleteAdditionalDocument(addDocId, reason, successCallback, errorCallback: (err: any) => void) {

    let request:any = {
      AddDocId: addDocId,
      DeleteReason: reason
    }

    this.http.post(environment.apiUri + "/AdditionalDocument/deleteFile", request
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getDeletedAddDocsData(episodeId, successCallback, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/AdditionalDocument/deleteReason/" + episodeId
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  updateDeletedFileReviewed(addDocId, noteReviewed: number, successCallback, errorCallback: (err: any) => void) {
    let params:HttpParams = new HttpParams();

    params.set("addDocId", addDocId);
    params.set("noteReviewed", noteReviewed.toString());
    addDocId = Number(addDocId);

    this.http.get(environment.apiUri + "/AdditionalDocument/noteReviewed/"+addDocId+"/"+noteReviewed
    ).subscribe({
      next: (resp) => { successCallback(); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }
  
  getClaimFileDeleteReason(episodeID:number, successCallback: (resp: EpisodeNote[]) => void, errorCallback: (err: any) => void,){
    let params:HttpParams = new HttpParams()
    params = params.set('episodeID', episodeID.toString())

    this.http.get(environment.apiUri + "/claimfile/deleteReason", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp as EpisodeNote[]); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getDashboardStatusDetails(practiceId, successCallback: (resp: any) => void, errorCallback: (err: any) => void) 
  {
    let params:HttpParams = new HttpParams();
    if(practiceId) {
      params = params.set('practiceId', practiceId);
    }
    this.http.get(environment.apiUri + "/Dashboard", {params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  uploadSecondaryClaimFile(secClaimFileObj: any, successCallback: (retVal: any) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();

    formData.append('EpisodeID', secClaimFileObj.episodeId.toString())
    formData.append('Notes', secClaimFileObj.notes)
    formData.append('UserName', secClaimFileObj.userName)
    formData.append('UserAD_ID', secClaimFileObj.userId.toString())
    formData.append('Reviewed', secClaimFileObj.reviewed.toString())
    formData.append('SecondaryFile', secClaimFileObj.file, secClaimFileObj.file.name);
    console.log(formData);

    this.http.post(environment.apiUri + "/SecondaryClaimFile", formData
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getSecondaryClaimForEpisodeId(episodeId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/SecondaryClaimFile/" + episodeId.toString(),
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  downloadSecondaryClaimForClaim(episodeId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/SecondaryClaimFile/" + episodeId.toString() + "/download", {headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  deleteSecondaryClaimFile(secClaimId, reason, successCallback, errorCallback: (err: any) => void) {

    let request:any = {
      SecondaryClaim_id: secClaimId,
      DeleteReason: reason
    }

    this.http.post(environment.apiUri + "/SecondaryClaimFile/deleteFile", request
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  updateSecClaimESReviewed(secClaimId, isReviewed: number, successCallback, errorCallback: (err: any) => void) {
    let params:HttpParams = new HttpParams();

    params.set("secClaimId", secClaimId);
    params.set("reviewedStatus", isReviewed.toString());
    secClaimId = Number(secClaimId);

    this.http.get(environment.apiUri + "/SecondaryClaimFile/update/ESReviewed/"+secClaimId+"/"+isReviewed
    ).subscribe({
      next: (resp) => { successCallback(); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }
  
  getEpisodeStatusActivityLogDetails(episodeId, successCallback: (res: any) => void, errorCallback: (err: any) => void) {
    let params: HttpParams = new HttpParams();
    if (episodeId) {
      params = params.set('episodeId', episodeId);
    }
    this.http.get(environment.apiUri + "/EpisodeStatusActivityLog", {params: params})
    .subscribe({
      next: (res) => {successCallback(res);},
      error: (err) => {errorCallback(err);}
    });
  }

  getEpisodeExportData(successCallback: (success: any) => void, errorCallback: (err: any) => void, 
  lineQuery:EpisodeLineSummaryQuery) {

    let params:HttpParams = new HttpParams()

    if (lineQuery.episodeID)
    params = params.set('episodeID', lineQuery.episodeID)

    if (lineQuery.patientName)
    params = params.set('patientName', lineQuery.patientName)

    if (lineQuery.medicareID)
    params = params.set('medicareID', lineQuery.medicareID)

    if (lineQuery.surgeryStartDate)
    params = params.set('surgeryStartDate', lineQuery.surgeryStartDate.getUTCMonth()+1 + "-" + lineQuery.surgeryStartDate.getUTCDate() + "-" + lineQuery.surgeryStartDate.getUTCFullYear())

    if (lineQuery.surgeryEndDate)
    params = params.set('surgeryEndDate', lineQuery.surgeryEndDate.getUTCMonth()+1 + "-" + lineQuery.surgeryEndDate.getUTCDate() + "-" + lineQuery.surgeryEndDate.getUTCFullYear())

    if (lineQuery.practiceID)
    params = params.set('practiceID', lineQuery.practiceID)

    if (lineQuery.physicianID)
    params = params.set('physician', lineQuery.physicianID)

    if (lineQuery.statusID)
    params = params.set('statusID', lineQuery.statusID.toString())

    if (lineQuery.bundleID)
    params = params.set('bundleID', lineQuery.bundleID.toString())

    if (lineQuery.offset != undefined)
    params = params.set('offset', lineQuery.offset.toString())

    if (lineQuery.limit != undefined)
    params = params.set('limit', lineQuery.limit.toString())

    if (lineQuery.orderBy)
    params = params.set('orderBy', this.GetEpisodeLineSummariesOrderByMap.get(lineQuery.orderBy) ?? "")

    if (lineQuery.sortDesc != undefined)
    params = params.set('sortDesc', lineQuery.sortDesc.toString())

    if(lineQuery.reviewed != undefined)
    params = params.set('reviewed', lineQuery.reviewed.toString())

    if(lineQuery.deletedFileReviewed != undefined)
    params = params.set('deletedDocReviewed', lineQuery.deletedFileReviewed.toString())

    this.http.get(environment.apiUri + "/ExportEpisodeStatusActivityLog", {headers: {"Accept": "application/octet-stream", 'Content-Type': 'application/octet-stream'}, responseType: "blob", params: params}
    ).subscribe({
    next: (resp) => { successCallback(resp) },
    error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getEpisodeDisplayData(successCallback: (success: any) => void, errorCallback: (err: any) => void, 
  lineQuery:EpisodeLineSummaryQuery) {

    let params:HttpParams = new HttpParams()

    if (lineQuery.episodeID)
    params = params.set('episodeID', lineQuery.episodeID)

    if (lineQuery.patientName)
    params = params.set('patientName', lineQuery.patientName)

    if (lineQuery.medicareID)
    params = params.set('medicareID', lineQuery.medicareID)

    if (lineQuery.surgeryStartDate)
    params = params.set('surgeryStartDate', lineQuery.surgeryStartDate.getUTCMonth()+1 + "-" + lineQuery.surgeryStartDate.getUTCDate() + "-" + lineQuery.surgeryStartDate.getUTCFullYear())

    if (lineQuery.surgeryEndDate)
    params = params.set('surgeryEndDate', lineQuery.surgeryEndDate.getUTCMonth()+1 + "-" + lineQuery.surgeryEndDate.getUTCDate() + "-" + lineQuery.surgeryEndDate.getUTCFullYear())

    if (lineQuery.practiceID)
    params = params.set('practiceID', lineQuery.practiceID)

    if (lineQuery.physicianID)
    params = params.set('physician', lineQuery.physicianID)

    if (lineQuery.statusID)
    params = params.set('statusID', lineQuery.statusID.toString())

    if (lineQuery.bundleID)
    params = params.set('bundleID', lineQuery.bundleID.toString())

    if (lineQuery.offset != undefined)
    params = params.set('offset', lineQuery.offset.toString())

    if (lineQuery.limit != undefined)
    params = params.set('limit', lineQuery.limit.toString())

    if (lineQuery.orderBy)
    params = params.set('orderBy', this.GetEpisodeLineSummariesOrderByMap.get(lineQuery.orderBy) ?? "")

    if (lineQuery.sortDesc != undefined)
    params = params.set('sortDesc', lineQuery.sortDesc.toString())

    if(lineQuery.reviewed != undefined)
    params = params.set('reviewed', lineQuery.reviewed.toString())

    if(lineQuery.deletedFileReviewed != undefined)
    params = params.set('deletedDocReviewed', lineQuery.deletedFileReviewed.toString())

    this.http.get(environment.apiUri + "/ExportDisplayedEpisodeData", {headers: {"Accept": "application/octet-stream", 'Content-Type': 'application/octet-stream'}, responseType: "blob", params: params}
    ).subscribe({
    next: (resp) => { successCallback(resp) },
    error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getFileManagerExportData(successCallback: (success: any) => void, errorCallback: (err: any) => void, 
                                   lineQuery:FileManagerQuery) {

    let params:HttpParams = new HttpParams()

    if (lineQuery.episodeID != undefined)
      params = params.set('episodeID', lineQuery.episodeID)

    if (lineQuery.fileType != undefined)
      params = params.set('fileType', lineQuery.fileType.toString())

    if (lineQuery.medicareID != undefined)
      params = params.set('medicareID', lineQuery.medicareID)
    
    if (lineQuery.uploadStartDate != undefined)
      params = params.set('uploadStartDate', lineQuery.uploadStartDate.getUTCMonth()+1 + "-" + lineQuery.uploadStartDate.getUTCDate() + "-" + lineQuery.uploadStartDate.getUTCFullYear())

    if (lineQuery.uploadEndDate != undefined)
      params = params.set('uploadEndDate', lineQuery.uploadEndDate.getUTCMonth()+1 + "-" + lineQuery.uploadEndDate.getUTCDate() + "-" + lineQuery.uploadEndDate.getUTCFullYear())

    if (lineQuery.practiceID != undefined)
      params = params.set('practiceID', lineQuery.practiceID.toString())

    if (lineQuery.amount != undefined)
      params = params.set('amount', lineQuery.amount.toString())

    if (lineQuery.checkNumber != undefined)
      params = params.set('checkNumber', lineQuery.checkNumber)

    if (lineQuery.offset != undefined)
      params = params.set('offset', lineQuery.offset.toString())

    if (lineQuery.limit != undefined)
      params = params.set('limit', lineQuery.limit.toString())

    if (lineQuery.orderBy != undefined)
      params = params.set('orderBy', this.getFileManagerOrderByMap.get(lineQuery.orderBy) ?? "")

    if (lineQuery.sortDesc != undefined)
      params = params.set('sortDesc', lineQuery.sortDesc.toString())

    this.http.get(environment.apiUri + "/ExportDisplayedEpisodeData/files", {headers: {"Accept": "application/octet-stream", 'Content-Type': 'application/octet-stream'}, responseType: "blob", params: params}
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  UploadZeroDollarRemitFile(episodeIDs:number[], payOrderId:number, checkNumber:string, amount:number, file:File, successCallback: (retVal: any) => void, errorCallback: (err: any) => void) {
    const formData: FormData = new FormData();

    for(let i = 0; i < episodeIDs.length; ++i)
      formData.append('EpisodeID', episodeIDs[i].toString())

    formData.append('Amount', amount.toString())
    formData.append('CheckNumber', checkNumber)
    formData.append('PayerOrder', payOrderId.toString())
    formData.append('ERAFile', file, file.name);

    this.http.post(environment.apiUri + "/ERAFile", formData
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  getZeroDollarRemitForEpisodeId(episodeId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/ZeroDollarRemitFile/" + episodeId.toString(),
    ).subscribe({
      next: (resp) => { successCallback(resp) },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  downloadZeroDollarRemitFile(episodeId:number, successCallback: (resp: any) => void, errorCallback: (err: any) => void) {
    this.http.get(environment.apiUri + "/ZeroDollarRemitFile/" + episodeId.toString() + "/download", {headers: {"Accept": "application/pdf", 'Content-Type': 'application/pdf'}, responseType: "blob"}
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

  deleteZeroDollarRemitFile(fileId, reason, successCallback, errorCallback: (err: any) => void) {

    let request:any = {
      Episode_ZeroDollarRemit_Join_ID: fileId,
      DeletedReason: reason
    }

    this.http.post(environment.apiUri + "/ZeroDollarRemitFile/deleteFile", request
    ).subscribe({
      next: (resp) => { successCallback(resp); },
      error: (err) => { this.errorCallback(err, errorCallback); }
    });
  }

}
