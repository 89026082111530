import { FileManagerRow } from "../NewDomain/FileManagerRow";

export class FileManagerERASelectionModel {

    //Key is Episode ID, value is the line summary object itself
    selectionMap = new Map();

    constructor() {}

    clear() { this.selectionMap.clear() }

    selectfileManagerRow(fileManagerRow:FileManagerRow) 
    {
        this.selectionMap.set( fileManagerRow.Id, fileManagerRow )
    }

    deselectfileManagerRow(fileManagerRow:FileManagerRow)
    {
        this.selectionMap.delete( fileManagerRow.Id )
    }

    isSelected(fileManagerRow:FileManagerRow) : boolean
    {
        return this.selectionMap.has( fileManagerRow.Id )
    }

    hasAnySelected() : boolean
    {
        return this.selectionMap.size > 0
    }

    //This method is necessary because the data on the row might be different when the page changes than when it was first retrieved, so those changes
    //need to passed to the selection model as well
    updateSelectionModelWithFileManagerRow(fileManagerRow:FileManagerRow)
    {
        let existingRow:FileManagerRow|undefined = this.selectionMap.get(fileManagerRow.Id)
        
        if (existingRow != undefined)
        existingRow = fileManagerRow
    }

    updateSelectionModelWithFileManagerRows(fileManagerRows:FileManagerRow[])
    {
        if (this.selectionMap.size > 0)
        {
            for (let i = 0; i < fileManagerRows.length; ++i)
                this.updateSelectionModelWithFileManagerRow(fileManagerRows[i])
        }
    }

    getSelected() : FileManagerRow[]
    {
        return Array.from(this.selectionMap.values())
    }
}