export class UserAD {
    UserAD_ID: number = 0
    UserEmail: string = ""
    UserName: string = ""
    UAD_ObjectID: string = ""
    ValidatorFlag: boolean = false
    FormattedEmail: string = ""

    constructor() {
    }
}