import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EpisodeDetails, EpisodeDetailsResponseDTO } from 'src/app/NewDomain/EpisodeDetails';
import { UserAD } from 'src/app/NewDomain/UserAD';
import { MatDialog } from '@angular/material/dialog';
import { FileDeleteConfirmationComponent } from 'src/app/dialog-modals/file-delete-confirmation/file-delete-confirmation.component';

@Component({
    selector: 'additional-doc-viewer-modal',
    templateUrl: './additional-doc-viewer-modal.component.html',
    styleUrls: ['./additional-doc-viewer-modal.component.css']
})

export class AdditionalDocViewerModalComponent implements OnInit
{

    addDocId: any;
    additionDocData: AddDocObject = this.createEmptyAddDocObject();
    pdfDocumentData: PDFDocumentAddDoc = this.createEmptyDocument();
    wholePageLoad: number = 0;
    episodeLoadSpinner: number = 1;
    loadSpinner = 0;
    episodeDetails: EpisodeDetails = new EpisodeDetails();
    user:UserAD = new UserAD();
    fileTypes = [{typeId: 1, typeName: 'Corrected Claim'}, {typeId: 2, typeName: 'OP Note'}, {typeId: 3, typeName: 'Secondary Insurance'}, {typeId: 4, typeName: 'Other'}];
    fileDeleteReason = "";

    constructor(public api:ApiService, public route: ActivatedRoute, public snackBar: MatSnackBar, public domSanitizer:DomSanitizer,
        private router: Router, public dialog: MatDialog) {}

    ngOnInit(): void {
        this.addDocId = this.route.snapshot.paramMap.get('addDocId');
        this.wholePageLoad++;
        this.api.getAdditionalDocumentsForAddDocId(this.addDocId, (res: any) => {
            switch(res[0].FileType) {
                case 1:
                    res[0].FileTypeName = "Corrected_Claim";
                    break;
                case 2:
                    res[0].FileTypeName = "OP_Note";
                    break;
                case 3:
                    res[0].FileTypeName = "Secondary_Insurance";
                    break;
                case 4:
                    res[0].FileTypeName = "Other";
                    break;
            }
            res[0].FileName = res[0].FileTypeName+"_"+res[0].AddDoc_Id+".pdf";
            this.additionDocData = res;

            this.api.downloadAdditionalDoc(this.addDocId, (res: any) => {
                this.pdfDocumentData.localPDFUrl = URL.createObjectURL(res);
                this.pdfDocumentData.safePDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfDocumentData.localPDFUrl);
                this.pdfDocumentData.loadSpinnerPDF = false;
                this.pdfDocumentData.downloadFileName = this.additionDocData[0].FileName;
                this.wholePageLoad--;
                this.episodeLoadSpinner--;
                this.loadSpinner--;
            },
            (err: any) => {
                this.pdfDocumentData.errorMessagePDF = err.message;
                this.pdfDocumentData.loadSpinnerPDF = false;
                this.wholePageLoad--;
                this.episodeLoadSpinner--;
                this.loadSpinner--;
            });

            this.api.getEpisodeDetails(
                this.additionDocData[0].EpisodeId,
                (resp: EpisodeDetailsResponseDTO) => {
                  this.episodeDetails = resp.episodeDetails;
                  this.loadSpinner--;
                },
                (error: any) => {
                  //this.errorMessage = err.message;
                  this.loadSpinner--;
                }
              );

        },
        (err: any) => {
            this.snackBar.open("File data not found!", "Dismiss", {duration: 3000});
            this.wholePageLoad--;
            this.episodeLoadSpinner--;
            this.loadSpinner--;
        });

        this.api.getUserADSelf()
        .subscribe({
          next: user => {
            this.user = user;
          },
          error: err => {
            console.log(err);
          }
        });
  
    }

    createEmptyDocument():PDFDocumentAddDoc {
        return {
          localPDFUrl: "", 
          safePDFUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(""), 
          loadSpinnerPDF: false,
          errorMessagePDF: "",
          downloadFileName: "file"
        }
    }

    createEmptyAddDocObject(): AddDocObject {
        return {
            AddDoc_Id: 0,
            FileLocation: "",
            FileType: 4,
            AddedDate: new Date,
            Notes: "",
            EpisodeId: 0,
            AddedByName: "",
            AddedByID: 0,
            IsReviewed: 1,
            IsDeleted: 0,
            FileName: "",
            FileTypeName: ""
        };
    }

    HasTargetPrice() : boolean{
        return this.episodeDetails.TargetPrice != null && !isNaN(this.episodeDetails.TargetPrice);
    }

    esReviewedChecked(event) {
        let reviewed;
        reviewed = event.checked? 1 : 0;
        this.api.updateESReviewed(this.addDocId, reviewed, () => {},
        (err => {
            this.snackBar.open("Reviewed status could not be updated", "Dismiss", {duration: 3000});
        }));

    }

    fileTypeChange(event) {
        this.api.updateFileType(this.addDocId, event, () => {},
        (err) => {
            console.log(err);
        });
    }

    deleteDocFile() {

        const dialogRef = this.dialog.open(FileDeleteConfirmationComponent, {
            width: "500px",
            disableClose: true,
            data: {},
            panelClass: 'popupModal',
            autoFocus: false
          });
      
          dialogRef.afterClosed().subscribe((action) => {
            if(action) {
                this.api.deleteAdditionalDocument(this.addDocId, this.fileDeleteReason, () => {
                    this.router.navigate(['.'], { relativeTo: this.route.parent });
                },
                (err) => {
                    console.log(err);
                });
            }
            else {
                console.log('FILE NOT DELETED');
            }
          });

        
    }

    returnBack() {
        this.router.navigate(['/patientEpisodeManager']);
    }

}

export interface PDFDocumentAddDoc {
    localPDFUrl: string, 
    safePDFUrl: SafeResourceUrl, 
    loadSpinnerPDF: boolean,
    errorMessagePDF: string,
    downloadFileName: string
}

export interface AddDocObject {
    AddDoc_Id: number,
    FileLocation: string,
    FileType: number,
    AddedDate: Date,
    Notes: string,
    EpisodeId: number,
    AddedByName: string,
    AddedByID: number,
    IsReviewed: number,
    IsDeleted: number,
    FileName: string,
    FileTypeName: string
}