import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { translatePayerOrderToText } from 'src/app/json-mappings/payerOrder';
import { ApiService } from 'src/app/api.service';
import { ERAFilesQuery } from 'src/app/apiQueryInterfaces';
import { ERAFile } from 'src/app/NewDomain/ERAFile';
import { ERAAssociatedEpisode } from 'src/app/NewDomain/ERAAssociatedEpisode';
import { compare } from 'fast-json-patch';
import { ACHFile } from 'src/app/NewDomain/ACHFile';

export interface FetchACHandERAforEpisodeResult {

  achs: {
    [key:number]: ACHDocument
  },

  eras: {
    [key:number]: ERADocument
  }
}

export interface ERAViewerEpisode {
  Id:number,
  Physician_FirstName:string,
  Physician_LastName:string,
  DateOfService:Date,
  Patient_FirstName:string,
  Patient_LastName:string,
  DateOfBirth:Date,
  selected:boolean,
  ERA_Finished:boolean,
  PayerID:string,
  EpisodeMaster_ID:number
  
}


export interface ACHDocument {
  ACHFile_ID: number,
  CheckNumber: string,
  Amount: number,
  pdfDocument:PDFDocument,
  ERAs: number[],

  eraIndexSelected: number,
  hasLoadedERAs: boolean
}

export interface ERADocument {
  ERAFile_ID: number,
  PayerOrder_ID: number,
  CheckNumber: string,
  pdfDocument:PDFDocument,
  hasLoadedEpisodes:boolean,
  SelectedEpisode?: ERAViewerEpisode,
  Episodes: ERAViewerEpisode[],

  numSelected: number,
}

export interface PDFDocument {
  localPDFUrl: string, 
  safePDFUrl: SafeResourceUrl, 
  loadSpinnerPDF: boolean,
  errorMessagePDF: string,
  downloadFileName: string
}

@Component({
  selector: 'app-era-viewer',
  templateUrl: './era-viewer.component.html',
  styleUrls: ['./era-viewer.component.css'],

})
export class EraViewerComponent implements OnInit {

  @Input() achAndERAResults: FetchACHandERAforEpisodeResult = {achs: {}, eras: {}}

  @Input() wholePageLoad:number = 0;
  localPDFUrl:string = ""
  safeLocalPDFUrl:SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl("");
  loadSpinnerPDF = true
  episodeLoadSpinner = 0
  errorMessagePDF = ""
  episodeList:ERAViewerEpisode[] = []
  numSelected = 0

  errorMessage = ""
  selectedIndex = 0
  @Input() selectedACHTabIndex = 0
  

  selectedACH?: ACHDocument
  selectedERA?: ERADocument
  selectedPDFDocument:PDFDocument = this.createEmptyDocument()

  constructor(protected api:ApiService, protected domSanitizer:DomSanitizer, protected route: ActivatedRoute,
              protected snackBar: MatSnackBar) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    //Have to free all the downloaded document blobs
    for (let [key, value] of Object.entries(this.achAndERAResults.achs)) {
      URL.revokeObjectURL(value.pdfDocument.localPDFUrl)
    }
    
    for (let [key, value] of Object.entries(this.achAndERAResults.eras)) {
      URL.revokeObjectURL(value.pdfDocument.localPDFUrl)
    }
  }

  CreateACHDocumentFromACHFileDomain(achFile:ACHFile):ACHDocument {
    return { ACHFile_ID: achFile.ACHFile_ID,
            CheckNumber: achFile.CheckNumber,
            Amount: achFile.Amount,
            pdfDocument: this.createEmptyDocument(),
            ERAs: [],
            eraIndexSelected: 0,
            hasLoadedERAs: false
          }
  }

  CreateERADocumentFromERAFileDomain(eraFile:ERAFile):ERADocument {
    return { ERAFile_ID: eraFile.ERAFile_ID,
            CheckNumber: eraFile.CheckNumber,
            PayerOrder_ID: eraFile.PayerOrder_ID,
            pdfDocument: this.createEmptyDocument(),
            numSelected: 0,
            hasLoadedEpisodes: false,
            Episodes: []
    }
  }

  setFinished(document:ERADocument) {
    let episodes:ERAViewerEpisode[] = []

    if (document.SelectedEpisode && document.SelectedEpisode.selected && !document.SelectedEpisode.ERA_Finished) //If selected and not already in finished status
    episodes.push(document.SelectedEpisode)

    for (let episode of document.Episodes) {
      if (episode.selected && !episode.ERA_Finished) //If selected and not already in completed status
      episodes.push(episode)
    }

    for (let i = 0; i < episodes.length; ++i) {
      
      let eraEpJoin:any = { IsFinished: false }
      let updateEraEpJoin:any = { IsFinished: true }

      let patch = compare(eraEpJoin, updateEraEpJoin)

      this.wholePageLoad++
      this.api.patchEpisodeERAJoin( 
        (resp) => {
          episodes[i].ERA_Finished = true
          document.numSelected--
          this.wholePageLoad--
          this.updateERAJoinFinally()
        },
        (err) => {
          this.snackBar.open("Status could not be updated due to error.", "Dismiss");
          this.wholePageLoad--
        }, episodes[i].Id, patch)
    }
  }

  updateERAJoinFinally(){
    if (this.wholePageLoad == 0)
      this.snackBar.open("Status successfully updated!", "Dismiss", {duration: 3000});
  }
  
  LoadERAsForACH(ach:ACHDocument){
    if (!ach.hasLoadedERAs) {
      this.wholePageLoad++
      let eraQueryParams:ERAFilesQuery = { ACHIds: [ ach.ACHFile_ID ] }
      this.api.getERAFiles(eraQueryParams, (res:ERAFile[]) => {

        let achFound = false
        let index = 1 //Have to account for the extra empty

        for (let i = 0; i < res.length; ++i) {
          this.achAndERAResults.eras[res[i].ERAFile_ID] = this.CreateERADocumentFromERAFileDomain(res[i])

          ach.ERAs.push(res[i].ERAFile_ID)
        }

        ach.hasLoadedERAs = true
        this.wholePageLoad--
      },
      (err:any) => {
        //this.errorMessage = err.message
        this.wholePageLoad--
      })
    }
  }

  unassignedTabClick(ach:ACHDocument) {
    this.LoadERAsForACH(ach)

    //This should remove the episode list on the left, and update the visible document to the ACH one
    if (this.selectedERA) {
      this.selectedERA.numSelected = 0
      this.selectedERA = undefined
    }
    if (this.selectedACH) {
      this.selectedACH.eraIndexSelected = 0
    }
    
    this.selectedACH = ach
    this.selectedPDFDocument = this.createEmptyDocument()
  }

  ACHTabClick(ach:ACHDocument) {
    this.LoadERAsForACH(ach)

    //This should remove the episode list on the left, and update the visible document to the ACH one
    if (this.selectedERA) {
      this.selectedERA.numSelected = 0
      this.selectedERA = undefined
    }
    if (this.selectedACH) {
      this.selectedACH.eraIndexSelected = 0
    }

    ach.eraIndexSelected = 0

    //These 2 update what's visually shown; be careful not to update these references in the callbacks below because they may change if the user clicks to another tab
    //before the PDF is finished loading
    this.selectedACH = ach
    this.selectedPDFDocument = ach.pdfDocument

    //Lazy load the ACH PDF document
    if (ach.pdfDocument && !ach.pdfDocument.localPDFUrl) {
      ach.pdfDocument.loadSpinnerPDF = true
      
      this.api.downloadACHFile(ach.ACHFile_ID, (res:any) => {
        ach.pdfDocument.localPDFUrl = URL.createObjectURL(res);
        ach.pdfDocument.safePDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(ach.pdfDocument.localPDFUrl);
        ach.pdfDocument.loadSpinnerPDF = false;
        ach.pdfDocument.downloadFileName = "ACH_" + ach.CheckNumber + ".pdf"
      },
      (err:any) => {
        ach.pdfDocument.errorMessagePDF = err.message
        ach.pdfDocument.loadSpinnerPDF = false;
      })
    }
  }

  ERATabClick(eraId:number) {
      //Switching tabs will destroy the dom, which has the effect of unselecting the checkbox
      if (this.selectedERA && this.selectedERA?.ERAFile_ID != eraId)
        this.selectedERA.numSelected = 0

      let era = this.achAndERAResults.eras[eraId]
      this.selectedERA = era
      this.selectedPDFDocument = era.pdfDocument

      //Lazy load the ERA's associated episodes (shown on left of page)
      if (!era.hasLoadedEpisodes) {
        this.episodeLoadSpinner++
        this.api.getAssociatedEpisodesForERA(era.ERAFile_ID, (res:ERAAssociatedEpisode[]) => {

          if (era) {
            for (let i = 0; i < res.length; ++i) {
              era.Episodes.push( {
                Id: res[i].episodeERAJoin_ID,
                Physician_FirstName:  res[i].physicianFirstName,
                Physician_LastName:  res[i].physicianLastName,
                Patient_FirstName:  res[i].patientFirstName,
                Patient_LastName:  res[i].patientLastName,
                DateOfBirth: res[i].patientDateOfBirth,
                DateOfService: res[i].episodeDateOfService,
                PayerID: res[i].patientMedicareID,
                EpisodeMaster_ID: res[i].episodeMasterID,
                ERA_Finished: res[i].episodeERAJoin_IsFinished,
                selected: false
              } )
            }

            era.hasLoadedEpisodes = true

            this.additionalERALoadingProcessing(era)
          }
    
          this.episodeLoadSpinner--
        },
        (err:any) => {
          //this.errorMessage = err.message
          this.episodeLoadSpinner--
        })
      }

      //Lazy load the ERA PDF document
      if (era.pdfDocument  && !era.pdfDocument.localPDFUrl) {
        era.pdfDocument.loadSpinnerPDF = true
        
        this.api.downloadERAFile(era.ERAFile_ID, (res:any) => {
          era.pdfDocument.localPDFUrl = URL.createObjectURL(res);
          era.pdfDocument.safePDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(era.pdfDocument.localPDFUrl);
          era.pdfDocument.loadSpinnerPDF = false;
          era.pdfDocument.downloadFileName = "ERA_" + era.CheckNumber + ".pdf"
          
        },
        (err:any) => {
          era.pdfDocument.errorMessagePDF = err.message
          era.pdfDocument.loadSpinnerPDF = false;
        })
      }
  }

  additionalERALoadingProcessing(era:ERADocument) {}

  setACHTab(achIndex:number) {
    this.selectedACHTabIndex = achIndex+1 //+1 is to account for the empty tab
  }

  setERATabOnACH(ACHDocument:ACHDocument, eraIndex:number) {
    ACHDocument.eraIndexSelected = eraIndex+1 //+1 is to account for the empty tab
  }

  createEmptyDocument():PDFDocument {
    return {
      localPDFUrl: "", 
      safePDFUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(""), 
      loadSpinnerPDF: false,
      errorMessagePDF: "",
      downloadFileName: "file"
    }
  }

  translatePayerOrderToText(payerOrder:number) {
    return translatePayerOrderToText(payerOrder);
  }
  
}
