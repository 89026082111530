import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'add-secondary-claim-file-modal',
    templateUrl: './add-secondary-claim-file-modal.component.html',
    styleUrls: ['./add-secondary-claim-file-modal.component.css']
})

export class AddSecondaryClaimFileModalComponent implements OnInit { 

    fileToUpload: File = new File([], "")
    episodeDetails;
    userDetails;
    errorMessage: string = "";
    loadSpinner = false;
    showError = false;
    notes = '';

    constructor(
        public dialogRef: MatDialogRef<AddSecondaryClaimFileModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api:ApiService, private snackBar: MatSnackBar){}

    
    ngOnInit(): void {
        this.episodeDetails = this.data.episodeDetails;
        this.userDetails = this.data.userDetails;
    }

    dropHandler(ev:DragEvent) {
        ev.preventDefault();
    
        let theFile: File = ev.dataTransfer?.files[0] as File
    
        if (theFile.type != "application/pdf") {
          return;
        }
    
        this.fileToUpload = theFile;
    }
    
    dragOverHandler(ev:DragEvent) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }

    change(ev:Event) {
        this.fileToUpload = (ev.target as HTMLInputElement).files?.item(0) as File
    }

    close(): void {
        this.dialogRef.close(false);
    }

    submit() {
        console.log(this.fileToUpload);
        if (this.userDetails.ValidatorFlag && this.fileToUpload) {
            let secClaimFileObj = {
                file: this.fileToUpload,
                notes: this.notes,
                reviewed: false,
                episodeId: this.episodeDetails.EpisodeMaster_ID,
                userName: this.userDetails.UserName,
                userId: this.userDetails.UserAD_ID
            };
            console.log(secClaimFileObj);
            this.api.uploadSecondaryClaimFile(secClaimFileObj, 
                (retVal) => {
                    this.snackBar.open("File successfully uploaded!", "Dismiss", {duration: 3000});
                    this.dialogRef.close(true);
                },
                (err) => {
                    this.dialogRef.close(false);
                    this.snackBar.open("File not uploaded, try again!", "Dismiss", {duration: 3000});
                }
            );
        }
        
    }
}
