<div [hidden]="this.activeRouter.children.length != 0" class="page-wrapper">
    <div class="page-title">File Manager</div>
    <mat-divider class="page-divider"></mat-divider>

    <div style="position: relative" *ngIf="!errorMessage; else error">
        <app-loading-spinner *ngIf="(loadSpinner > 0) || (dataSource.loading$ | async)"></app-loading-spinner>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <div [ngStyle]="{'display' : isValidator ? 'flex' : 'none'}">
                <div [ngClass]="this.fileManagerERASelectionModel.hasAnySelected() && this.fileManagerQuery.practiceID != undefined ? 'achEnable': 'achDisable'" class="filterSpacer" (click)="openAddACHModal()" style="display: flex; align-items: center;">
                    Upload ACH
                </div>

                <mat-form-field class="filterDropdowns form-field-no-bottom filterSpacer">
                    <mat-label>Practice</mat-label>
                    <mat-select (selectionChange)="practiceFilterChange($event.value)">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let practice of practices" [value]="practice.Practice_ID">
                        {{practice.Practice_Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div [ngStyle]="{'display' : isValidator ? 'flex' : 'none'}">
                <div fxLayout="row" fxLayoutAlign="space-between" style="align-self: center;">
                    <div>
                        <button mat-raised-button style="background-color: #D5F692; color: black;" (click)="exportFilesDisplayedData()" type="button">Export Displayed Data</button>
                    </div>
                </div>
            </div>
        </div>

        <div #fileListWindow style="overflow: auto" [ngStyle]="{'height' : isValidator ? '705px' : '770px'}">
            <table mat-table matSort [dataSource]="dataSource" (matSortChange)=sortChange($event)>
                
                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-icon style="cursor: pointer" 
                                  [ngStyle]="{'color': fileManagerERASelectionModel.hasAnySelected() ? 'red' : 'unset' }"
                                  (click)="fileManagerERASelectionModel.clear()">cancel_presentation</mat-icon>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary"
                                    (click)="$event.stopPropagation()"
                                    (change)="$event.checked ? fileManagerERASelectionModel.selectfileManagerRow(row) : fileManagerERASelectionModel.deselectfileManagerRow(row)"
                                    [checked]="fileManagerERASelectionModel.isSelected(row)"
                                    [ngStyle]="{'visibility' : isValidator && row.FileType == FileManagerFileTypeEnum.ERA ? 'visible' : 'hidden'}"
                                    [disabled]="this.fileManagerQuery.practiceID == undefined">
                        </mat-checkbox>
                    </td>
                </ng-container>
            
                <!-- Position Column -->
                <ng-container matColumnDef="FileType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> File Type </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> 
                        <a routerLink="{{getViewerRoute(fileManagerRow)}}">
                            {{convertFileTypeEnumToText(fileManagerRow.FileType)}}
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="UploadDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Upload Date </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> {{fileManagerRow.UploadDate | date:"MM/dd/yy"}} </td>
                </ng-container>

                <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> {{fileManagerRow.Amount | currency:"USD"}} </td>
                </ng-container>

                <ng-container matColumnDef="CheckNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Check # </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> {{(fileManagerRow.CheckNumber && fileManagerRow.CheckNumber != 'null') ? '#' + fileManagerRow.CheckNumber : ''}} </td>
                </ng-container>

                <ng-container matColumnDef="PayerOrder">
                    <th mat-header-cell *matHeaderCellDef > </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> {{fileManagerRow.PayerOrder_ID == undefined ? undefined : this.translatePayerOrderToText(fileManagerRow.PayerOrder_ID)}} </td>
                </ng-container>

                <ng-container matColumnDef="PatientIDs">
                    <th mat-header-cell *matHeaderCellDef> Medicare IDs </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> {{fileManagerRow.PatientIDs}} </td>
                </ng-container>

                <ng-container matColumnDef="AssociatedEpisode">
                    <th mat-header-cell *matHeaderCellDef> Associated Episodes </th>
                    <td mat-cell *matCellDef="let fileManagerRow"> {{fileManagerRow.AssociatedEpisode}} </td>
                </ng-container>

                <ng-container matColumnDef="filter-checkBox">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-fileType">
                    <th mat-header-cell *matHeaderCellDef > 
                    <mat-form-field appearance="fill" class="form-field-no-bottom" >
                        <mat-label>File Type</mat-label>
                        <mat-select (selectionChange)="fileTypeFilterChange($event.value)">
                            <mat-option>--</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Claim">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Claim)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.ACH">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.ACH)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.ERA">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.ERA)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Denial">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Denial)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Corrected_Claim">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Corrected_Claim)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.OP_Note">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.OP_Note)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Secondary_Insurance">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Secondary_Insurance)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Other">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Other)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Secondary_Claim">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Secondary_Claim)}}</mat-option>
                            <mat-option [value]="FileManagerFileTypeEnum.Zero_Dollar_Remit">{{convertFileTypeEnumToText(FileManagerFileTypeEnum.Zero_Dollar_Remit)}}</mat-option>
                        </mat-select>
                        </mat-form-field>
                    </th>
                </ng-container>



                <ng-container matColumnDef="filter-uploadDate">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom">
                            <mat-label>Range</mat-label>
                            <mat-date-range-input [rangePicker]="picker" >
                                <input matStartDate placeholder="Start date" (dateChange)="uploadStartDateFilterChange($event.value)">
                                <input matEndDate placeholder="End date" (dateChange)="uploadEndDateFilterChange($event.value)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-amount">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom">
                            <mat-label>Amount</mat-label>
                            <input matInput (input)="amountFilterChange($event)">
                          </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-checkNumber">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom">
                            <mat-label>Check #</mat-label>
                            <input matInput (input)="checkNumberFilterChange($event)">
                          </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-empty">
                    <th mat-header-cell *matHeaderCellDef >
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-patientId">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom">
                            <mat-label>Medicare ID</mat-label>
                            <input matInput (input)="patientIdFilterChange($event)" [value]="patientIdValue">
                          </mat-form-field>
                    </th>
                </ng-container>

                <ng-container matColumnDef="filter-associatedEpisodes">
                    <th mat-header-cell *matHeaderCellDef > 
                        <mat-form-field appearance="fill" class="form-field-no-bottom">
                            <mat-label>ID</mat-label>
                            <input matInput (input)="episodeIdFilterChange($event)">
                          </mat-form-field>
                    </th>
                </ng-container>

            
                  <tr mat-header-row *matHeaderRowDef="['filter-checkBox', 'filter-fileType', 'filter-uploadDate', 'filter-amount', 'filter-checkNumber', 'filter-empty', 'filter-patientId', 'filter-associatedEpisodes']; sticky: true" style="background-color: white;"></tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{'background-color': fileManagerERASelectionModel.isSelected(row) ? 'var(--primary-menuButton)' : 'initial'}"></tr>

            </table>
            
        </div>
        <mat-paginator (page)="loadFileManagerRows(true)" [length]="dataSource.totalCount | async" [pageSizeOptions]="[50, 100, 250]"></mat-paginator>
    </div>

    <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

</div>

<router-outlet [hidden]="this.activeRouter.children.length == 0"></router-outlet>