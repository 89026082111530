import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {Router} from "@angular/router";

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

  @Input() errorMessage: string = "";
  @Input() showBackButton:boolean = true;
  @Input() showRefreshButton:boolean = false;
  
  constructor(private location: Location) {
  }

  ngOnInit(): void {
  }

  back() {
    this.location.back();
  }

  refresh(){
    window.location.reload();
  }

}