<div style="position: relative;">
    <app-loading-spinner *ngIf="loadSpinner"></app-loading-spinner>
    <button mat-icon-button class="modal-close-button" style="position: absolute;" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>

    <div (drop)="dropHandler($event);" (dragover)="dragOverHandler($event);"
        style="display: flex; align-items: center; flex-direction: column; font: normal normal bold 20px/30px Roboto;">
        <div [formGroup]="form" style="width: 75%; display: flex; align-items: center; flex-direction: column;">
            <mat-icon
                style="color: var(--accent-menuButtonInactive); font-size: 100px; width: 100px; height: 100px">cloud_upload</mat-icon>
            <div>
                <div style="padding-bottom: 20px;">DRAG AND DROP ERA (PDF) HERE</div>
            </div>
            <div style="padding-bottom: 41px">or</div>
            <div *ngIf="showError" style="color: red;">{{errorMessage}}</div>
            <div style="display: flex; align-items: center; padding-bottom: 17px;">
                <mat-form-field class="form-field-no-bottom pdf-modal-file-input"
                    style="width: 371px; font-size: 11px;">
                    <input #fileInput matInput [formControl]="fileToUploadFormControl" disabled=true
                        [value]="this.fileToUpload?.name">
                </mat-form-field>
                <input #fileInputHidden type="file" hidden="true" (change)="change($event)" accept=".pdf">
                <button mat-raised-button color="accent" style="width: 150px" (click)="fileInputHidden.click()"
                    [disabled]="this.missing1500">Select File</button>
            </div>

            <div style="display: flex; padding-bottom: 17px">
                <mat-form-field style="width: 260px; font-size: 15px; padding-right: 58px">
                    <mat-label>Remittance Total Amount</mat-label>
                    <input matInput currencyMask [formControl]="amountFormControl" autocomplete="off" />
                    <mat-hint>Enter the total amount found on the ERA</mat-hint>
                </mat-form-field>

                <mat-form-field style="width: 260px; font-size: 15px">
                    <mat-label>Check #</mat-label>
                    <input matInput [formControl]="checkNumberFormControl" autocomplete="off" />
                    <mat-hint>Enter the check number</mat-hint>
                </mat-form-field>
            </div>

            <mat-radio-group [formControl]="eraTypeFormControl" class="era-type-radio-group">
                <mat-radio-button value=1>Primary</mat-radio-button>
                <mat-radio-button value=2>Secondary</mat-radio-button>
                <mat-radio-button value=3>Tertiary</mat-radio-button>
            </mat-radio-group>



            <div
                style="display: flex; width: 400px; margin-top: 60px; margin-bottom: 11px; justify-content: space-between;">
                <button mat-button style="width: 122px" (click)="close()">Cancel</button>
                <button mat-raised-button [disabled]="!isButtonEnabled" color="accent" style="width: 122px"
                    (click)="submit()">Submit</button>

            </div>

            <div style="width: 100%;">
                <div>Selected Episodes</div>
                <div
                    style="max-height: 130px; overflow-y: auto; display: flex; flex-wrap: wrap; flex-direction: column;">
                    <div *ngFor="let episode of selectedEpisodes;"
                        [ngStyle]="{'color': !episode.has1500 ? 'red' : 'auto'}"
                        style="font: normal normal normal 14px/22px Roboto; padding-right: 25px;">
                        - {{episode.EpisodeMaster_ID}}: {{episode.SurgeonLastName}} - {{episode.PatLastName}}
                        {{!episode.has1500 ? ' | Has no claim' : ''}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>