//Might make this its own component in the future, but for now just using this as a singular location for medicare ID entries to reuse the same input method
export function SanitizeMedicareIdInput(medicareId:string):string {
    let newMedicareId:String = ""
    let bracket = false
    let code

    //Strip html tags, everything between the tags, and then everything that's not an alphanumeric character
    for (let i = 0; i < medicareId.length; i++) 
    {
      if (!bracket)
      {
        if (medicareId.charAt(i) == "<")
          bracket = true
          
        else 
        {
            code = medicareId.charCodeAt(i);

            if ((code > 47 && code < 58) || // numeric (0-9)
                 (code > 64 && code < 91) || // upper alpha (A-Z)
                 (code > 96 && code < 123)) // lower alpha (a-z)
                newMedicareId += medicareId.charAt(i)
        }
      }
      else if (medicareId.charAt(i) == ">")
        bracket = false
    }
    
    //Change all characters to uppercase
    return newMedicareId.toUpperCase()
  }