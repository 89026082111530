import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTableModule,} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatCheckboxModule} from '@angular/material/checkbox';

import { MsalModule, MsalInterceptor, MsalInterceptorConfiguration, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent, MsalService, MsalGuard, MsalBroadcastService } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module'; 
import { AppComponent } from './app.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'; 

import { HeaderComponent } from './header/header.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { AddNewEpisodePageComponent } from './add-new-episode-page/add-new-episode-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { PatientEpisodeManagerPageComponent } from './patient-episode-manager-page/patient-episode-manager-page.component';
import { SupportPageComponent } from './support-page/support-page.component';
import { FilesPageComponent } from './files-page/files-page.component';
import { AddPdfModalComponent } from './patient-episode-manager-page/add-pdf-modal/add-pdf-modal.component';
import { ValidateClaimFileComponent } from './patient-episode-manager-page/validate-claim-file/validate-claim-file.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { EpisodeDetailsSidebarComponent } from './patient-episode-manager-page/episode-details-sidebar/episode-details-sidebar.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { AddEraModalComponent } from './patient-episode-manager-page/add-era-modal/add-era-modal.component';
import { NgxCurrencyModule } from "ngx-currency";
import { EraViewerComponent } from './patient-episode-manager-page/era-viewer/era-viewer.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { EpisodeDetailsComponent } from './patient-episode-manager-page/episode-details/episode-details.component';
import { AdministrationPageComponent } from './administration-page/administration-page.component';
import { CancelEpisodeModalComponent } from './add-new-episode-page/cancel-episode-modal/cancel-episode-modal.component';
import {MatTabsModule} from '@angular/material/tabs';
import { AddAchModalComponent } from './files-page/add-ach-modal/add-ach-modal.component';
import { EraViewerForEpisodeComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-episode/era-viewer-for-episode.component';
import { EraViewerForAchComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-ach/era-viewer-for-ach.component';
import { EraViewerForEraComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-era/era-viewer-for-era.component';
import {SharedModule} from "./shared/shared.module";
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { AddDenialModalComponent } from "./patient-episode-manager-page/add-denial-modal/add-denial-modal.component";
import { EraViewerForDenialComponent } from "./patient-episode-manager-page/era-viewer/era-viewer-for-denial/era-viewer-for-denial.component";
import { DenialViewerComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-denial/denial-viewer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from './patient-episode-manager-page/DeleteConfirmationDialogComponent/delete-confirmation-dialog.component';
import { TimeoutNotifyModalComponent } from './dialog-modals/timeout-notify-modal/timeout-notify-modal.component';
import { AddAdditionalDocModalComponent } from './dialog-modals/add-additional-doc-modal/add-additional-doc-modal.component';
import { AdditionalDocListComponent } from './dialog-modals/additional-doc-list-modal/additional-doc-list-modal.component';
import { FileDeleteConfirmationComponent } from './dialog-modals/file-delete-confirmation/file-delete-confirmation.component';
import { NotesDeletedDocsComponent } from './dialog-modals/notes-deleted-docs/notes-deleted-docs.component';
import { NgChartsModule} from 'ng2-charts';
import '../../node_modules/chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.js';
import { AdditionalDocViewerModalComponent } from './patient-episode-manager-page/era-viewer/additional-doc-viewer/additional-doc-viewer-modal.component';
import { AddSecondaryClaimFileModalComponent } from './dialog-modals/add-secondary-claim-file-modal/add-secondary-claim-file-modal.component';
import { SecondaryClaimViewerComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-secondary-claim/era-viewer-for-secondary-claim.component';
import { EpisodeStatusActivityLogComponent } from './patient-episode-manager-page/episode-status-activity-log-modal/episode-status-activity-log-component';
import { ExportDataWarningModalComponent } from './dialog-modals/export-data-warning-modal/export-data-warning-modal.component';
import { ZeroDollarRemitFileComponent } from './patient-episode-manager-page/era-viewer/zero-dollar-remit-viewer/zero-dollar-remit-viewer.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }
  
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.frontEndAppRegistrationId,
        authority: 'https://login.microsoftonline.com/' + environment.tenantId,
        redirectUri: environment.redirectUri,
        postLogoutRedirectUri: '/'
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.apiUri + '/*', ['api://' + environment.backEndAppRegistrationId + '/Episodes.Read']);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { 
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['api://' + environment.backEndAppRegistrationId + '/Episodes.Read']
      },
      //loginFailedRoute: '/login-failed'
    };
   }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideMenuComponent,
    MenuButtonComponent,
    AddNewEpisodePageComponent,
    DashboardPageComponent,
    PatientEpisodeManagerPageComponent,
    SupportPageComponent,
    FilesPageComponent,
    AddPdfModalComponent,
    ValidateClaimFileComponent,
    LoadingSpinnerComponent,
    ErrorDialogComponent,
    EpisodeDetailsSidebarComponent,
    ServerErrorComponent,
    AddEraModalComponent,
    EraViewerComponent,
    BackButtonComponent,
    EpisodeDetailsComponent,
    AdministrationPageComponent,
    CancelEpisodeModalComponent,
    AddAchModalComponent,
    EraViewerForEpisodeComponent,
    EraViewerForAchComponent,
    EraViewerForEraComponent,
    DenialViewerComponent,
    AddDenialModalComponent,
    EraViewerForDenialComponent, 
    DeleteConfirmationDialogComponent,
    TimeoutNotifyModalComponent,
    AddAdditionalDocModalComponent,
    AdditionalDocListComponent,
    AdditionalDocViewerModalComponent,
    FileDeleteConfirmationComponent,
    NotesDeletedDocsComponent,
    AddSecondaryClaimFileModalComponent,
    SecondaryClaimViewerComponent,
    EpisodeStatusActivityLogComponent,
    ExportDataWarningModalComponent,
    ZeroDollarRemitFileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    AppRoutingModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatGridListModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    NgxCurrencyModule,
    MatTabsModule,
    MsalModule,
    SharedModule,
    MatDialogModule,
    FormsModule,
    NgChartsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, 
      useValue: { appearance: 'outline' } 
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
