<div class="page-wrapper" style="height: 100%; max-width: calc(100vw - 289px);">
    <div style="display: flex; height: 100%; position: relative">
        <app-loading-spinner *ngIf="wholePageLoad > 0"></app-loading-spinner>

        <div style="display:flex; flex-direction: column;" [ngClass]="wholePageLoad ? 'loadingTrue' : 'loadingFalse'">

            <div class="page-title">Denial Viewer</div>
            <mat-divider class="page-divider" style="margin-bottom: 15.5px;"></mat-divider>

            <div style="display: flex; flex-direction: column; position: relative;">
                <app-loading-spinner *ngIf="episodeLoadSpinner > 0"></app-loading-spinner>

                <div>           
                    <div class="section-title" style="margin-top: 15px;">Related Episodes</div>
                    <div *ngFor="let relEpisode of denialObject" class="spacer" style="display: flex;">
                        <div>
                            {{relEpisode.PhysicianLN}}, {{relEpisode.PhysicianFN}}<br>
                            {{relEpisode.DateOfService | date:"MM/dd/yy":"UTC"}}<br>
                            {{relEpisode.PatientLN}}, {{relEpisode.PatientFN}}, {{relEpisode.Patient_DOB | date:"MM/dd/yy":"UTC"}}<br>
                            {{relEpisode.MedicareId}}
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex; flex-direction: column; position: relative; margin-top: 15px;">
                <div>           
                        <div>
                            <div><span style="font-weight: bold">File Name</span>: {{pdfDocumentData.downloadFileName}} </div>
                            <div><span style="font-weight: bold">Added Date</span>: {{denialFileData.AddDate | date:"MM/dd/yy":"UTC"}} </div>
                            <div><span style="font-weight: bold">Added By</span>: {{denialFileData.AddedByName}} </div>
                            <div><span style="font-weight: bold">Note</span>: {{denialFileData.Notes}} </div>
                            <div><span style="font-weight: bold">Denial Type</span>: {{denialType}} </div>
                        </div>
                </div>
            </div>

            <div>           
                <div class="section-title" style="margin-top: 25px; font-weight: bold">Instructions for Practice</div>
                    <div>
                        <div>To resolve the denial, email <a href="" target="_blank">billing@episodesolutions.com</a></div>
                    </div>
            </div>
        </div>

        <div style="display:flex; flex-direction: column; width: 100%">

            <div style="display: flex; flex: 1; position: relative; margin-top: 20px;">
                    
                <div style="position: relative; flex: 1; display: flex; flex-direction: column">
                    <app-loading-spinner *ngIf="pdfDocumentData?.loadSpinnerPDF"></app-loading-spinner>
        
                    <div *ngIf="!pdfDocumentData?.errorMessagePDF; else pdfError" style="width: 100%; flex: 1">
                        <embed [src]="pdfDocumentData?.safePDFUrl" type='application/pdf' style="width: 100%; height: 100%" />
                    </div>
        
                    <div style="display: flex; margin-top: 30px;">
                        <div style="flex: 1">
                            <app-back-button></app-back-button>
                        </div>
        
                        <div *ngIf="pdfDocumentData.localPDFUrl != ''" style="flex: 1; display: flex; justify-content: center;">
                            <a [href]="pdfDocumentData?.safePDFUrl" [download]="pdfDocumentData.downloadFileName" style="color: inherit; position: relative;  width: 48px;">
                                <mat-icon style="font-size: 48px; width: 48px; margin-bottom: 25px">file_download</mat-icon>
                            </a>
                        </div>
        
                        <div style="flex: 1"></div>
                    </div>
        
                    <ng-template #pdfError>
                        <div style="flex: 1" height="100%" width="100%">
                            Could not download PDF<br><br>
        
                            Server Error: {{pdfDocumentData?.errorMessagePDF}}
                        </div>
                    </ng-template>
                </div>
            </div>

            <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

        </div>


    </div>
  
</div>