<div style="font: normal normal normal 14px/30px Roboto; display: flex; flex-direction: column; justify-content: space-between; flex: 1">

    <div>
        <div>Episode ID: {{this.EpisodeMaster_ID}}</div>
        <div><span style="font-weight: bold">Physician</span>: {{this.PhysFirstName}}  {{this.PhysLastName}}</div>
        <div style="font-weight: bold">Bundle Selected:</div>
        <div>{{this.Bundle_Name}}</div>
        <div style="font-weight: bold">Procedure Description:</div>
        <div>{{this.Procedure_Desc}}</div>
    </div>

    <div>
        <div style="font-weight: bold">Facility:</div>
        <div>{{this.Facility_Name}}</div>
        <div><span style="font-weight: bold">Place of Service:</span> {{this.PlaceOfService_TechnicalID}}, {{this.PlaceOfService_DisplayName}}</div>
        <div *ngIf="isValidator"><span style="font-weight: bold">Target Price: </span> 
            <span *ngIf="this.HasTargetPrice(); else NoTargetPrice">{{this.TargetPrice | currency:"USD"}}</span>
            <ng-template #NoTargetPrice><span style="color:red">None</span></ng-template>
        </div>
        <div><span style="font-weight: bold">CJR: </span> <span [ngStyle]="{'color': this.isCJR ? 'red' : 'auto'}">{{this.isCJR ? "Yes" : "No"}}</span></div>
    </div>

    <div>
        <div style="font-weight: bold">Patient Information</div>
        <div>{{PatFirstName}}  {{PatLastName}}, {{PatDOB | date:"MM/dd/yy":"UTC"}}</div>
        <div>Medicare ID: {{this.MedicareID}}</div>
    </div>

    <div>
        <div style="font-weight: bold">CLAIM NOTES:</div>
        <div *ngFor="let note of notes; let i = index">
            <div *ngIf="i > 0">--</div>
            <div>{{note.Author}}, {{note.AddDate | date:"MM/dd/yy"}}</div>
            <div>{{note.Note}}</div>
        </div>
    </div>
</div>