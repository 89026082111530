import { Component, OnInit } from '@angular/core';
import { FacilityQuery, PhysicianQuery, UserADQuery } from '../apiQueryInterfaces';
import { AdminDetails } from '../json-mappings/adminDetails';
import { ApiService } from '../api.service';
import { Bundle } from '../NewDomain/Bundle';
import { Facility } from '../NewDomain/Facility';
import { Physician } from '../NewDomain/Physician';
import { Practice } from '../NewDomain/Practice';
import { UserAD } from '../NewDomain/UserAD';





@Component({
  selector: 'app-administration-page',
  templateUrl: './administration-page.component.html',
  styleUrls: ['./administration-page.component.css']
})
export class AdministrationPageComponent implements OnInit {

  loadSpinner = 0
  users:UserAD[] = []
  physicians:Physician[] = []
  facilities:Facility[] = []
  errorMessage = ""
  adminDetails: AdminDetails|undefined;
  practices:Practice[] = []
  selectedPractice = 0
  bundleMap: Map<number, Bundle> = new Map<number, Bundle>()

  constructor(private api:ApiService) { }

  ngOnInit(): void {

    this.loadSpinner++;
    this.api.getPractices(
      (res) => {
        this.practices = res

        if (this.practices.length > 0) {
          this.selectedPractice = this.practices[0].Practice_ID
          this.practiceChange(this.selectedPractice)
        }

        this.loadSpinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.loadSpinner--
    })
  }

  practiceChange(value:number) {

    this.loadSpinner++;
    this.api.getBundles(
      (res) => {
        this.bundleMap.clear()

        for (let i = 0; i < res.length; ++i)
          this.bundleMap.set(res[i].Bundle_ID, res[i])

        this.loadSpinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.loadSpinner--
    })

    this.loadSpinner++;
    let userADQuery:UserADQuery = { Practice_IDs: [ value ] }
    this.api.getUserAD( userADQuery,
      (res) => {
        this.users = res

        this.users.sort( (a:any, b:any) => {
          var nameA = a.UserEmail ? a.UserEmail.toUpperCase() : a.UserName.toUpperCase()
          var nameB = b.UserEmail ? b.UserEmail.toUpperCase() : b.UserName.toUpperCase()
    
          if (nameA < nameB) { return -1;}
          if (nameA > nameB) { return 1;}
          return 0; //Equal
        } );  

        this.loadSpinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.loadSpinner--
    })

    this.loadSpinner++;
    let physQuery:PhysicianQuery = { Practice_IDs: [ value ], with: [ "bundleIDs" ] }
    this.api.getPhysicians(
      (res) => {
        this.physicians = res
        this.loadSpinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.loadSpinner--
    }, physQuery)

    this.loadSpinner++;
    let facilityQuery:FacilityQuery = { Practice_IDs: [ value ] }
    this.api.getFacilities(
      (res) => {
        this.facilities = res
        this.loadSpinner--
      },
      (err) => { 
        this.errorMessage = err.message;
        this.loadSpinner--
    }, facilityQuery)
  }

}
