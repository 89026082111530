import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'additional-doc-list-modal',
    templateUrl: './additional-doc-list-modal.component.html',
    styleUrls: ['./additional-doc-list-modal.component.css']
})

export class AdditionalDocListComponent implements OnInit {

    additionalDocList: any[];
    displayedColumns: string[] = ['reviewed', 'uploaded', 'fileType'];
    constructor(
        public dialogRef: MatDialogRef<AdditionalDocListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api:ApiService, private snackBar: MatSnackBar,
        public activeRouter:ActivatedRoute, private router: Router){}

    ngOnInit(): void {
        this.additionalDocList = this.data.additionalDOcList;
        for(let docList of this.additionalDocList) {
            switch(docList.FileType) {
                case 1:
                    docList.fileTypeName = "Corrected_Claim";
                    docList.fileName = docList.fileTypeName+"_"+docList.AddDoc_Id;
                    break;
                case 2:
                    docList.fileTypeName = "OP_Note";
                    docList.fileName = docList.fileTypeName+"_"+docList.AddDoc_Id;
                    break;
                case 3:
                    docList.fileTypeName = "Secondary_Insurance";
                    docList.fileName = docList.fileTypeName+"_"+docList.AddDoc_Id;
                    break;
                case 4:
                    docList.fileTypeName = "Other";
                    docList.fileName = docList.fileTypeName+"_"+docList.AddDoc_Id;
                    break;
            }
        }
    }

    routeToViewer(docId) {
        this.dialogRef.close(docId);
    }

    close() {
        this.dialogRef.close(false);
    }

}