import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'export-data-warning-modal',
    templateUrl: './export-data-warning-modal.component.html',
    styleUrls: ['./export-data-warning-modal.component.css']
})

export class ExportDataWarningModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<ExportDataWarningModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any){}
    
    ngOnInit(): void {
        
    }

    close(action): void {
        this.dialogRef.close(action);
    }
}