import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EpisodeStatusIDs } from '../EpisodeStatuses'
import { MatSnackBar } from '@angular/material/snack-bar';
import { EpisodeLineSummary } from 'src/app/domain/episodeLineSummary';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-add-pdf-modal',
  templateUrl: './add-pdf-modal.component.html',
  styleUrls: ['./add-pdf-modal.component.css']
})
export class AddPdfModalComponent implements OnInit {

  fileToUpload: File = new File([], "")
  errorMessage: string = "";
  showError = false;
  loadSpinner = false;


  constructor(
    public dialogRef: MatDialogRef<AddPdfModalComponent>,
    @Inject(MAT_DIALOG_DATA) public episode: EpisodeLineSummary,
    private api:ApiService, private snackBar: MatSnackBar){}


  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  change(ev:Event) {
    this.fileToUpload = (ev.target as HTMLInputElement).files?.item(0) as File
  }

  dropHandler(ev:DragEvent) {
    ev.preventDefault();

    let theFile: File = ev.dataTransfer?.files[0] as File

    if (theFile.type != "application/pdf") {
      return;
    }

    this.fileToUpload = theFile;
  }

  dragOverHandler(ev:DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  submit() {
    if (this.fileToUpload) {
      this.loadSpinner = true;
      
      this.api.claimUploadFunction(this.episode.EpisodeMaster_ID.toString(), this.fileToUpload, 
        (retVal) => { //Success 

          this.episode.Status_ID = EpisodeStatusIDs.validatingClaim;
          this.episode.has1500 = true;
          this.snackBar.open("Claim successfully uploaded!", "Dismiss", {duration: 3000}); 
          this.dialogRef.close(true);
        },
        (err) => { //Error
          this.errorMessage = err.message;
          this.showError = true;
          this.loadSpinner = false;
        });
    }
  }
  
}
