import {Pipe, PipeTransform} from "@angular/core";
import { EpisodeStatusIDs} from "../../patient-episode-manager-page/EpisodeStatuses";
import { EpisodeStatus } from "src/app/NewDomain/EpisodeStatus";
import { EpisodeDetails } from "src/app/NewDomain/EpisodeDetails";

@Pipe({
  name:'applicableEpisodeStatuses'
})
export class ApplicableEpisodeStatusesPipe implements PipeTransform{

  constructor() {
  }

  transform(episodeStatuses: EpisodeStatus[], currentEpisode: EpisodeDetails, currentStatus: number): EpisodeStatus[] {
    return episodeStatuses.filter(episodeStatus => {

      if(episodeStatus.EpisodeStatus_ID === currentStatus){
        return true;
      }

      // handling for onHold status
      if(episodeStatus.EpisodeStatus_ID === EpisodeStatusIDs.onHold){
        return [EpisodeStatusIDs.pendingApproval,EpisodeStatusIDs.readyForClaim,EpisodeStatusIDs.surgeryScheduled].includes(currentStatus);
      }

      // handling for surgeryScheduled status
      if(episodeStatus.EpisodeStatus_ID === EpisodeStatusIDs.surgeryScheduled){
        const currentTime = Date.now();
        return (currentStatus === EpisodeStatusIDs.pendingApproval &&
          (currentEpisode.DateOfService) &&
          ((new Date(currentEpisode.DateOfService)?.getTime() - (currentTime - currentTime % 86400000)) / 1000 >= /*Days*/ 7 * 86400)
        );
      }

      return true;
    });

  }

}
