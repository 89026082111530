import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { FileManagerRow } from 'src/app/NewDomain/FileManagerRow';

@Component({
  selector: 'app-add-ach-modal',
  templateUrl: './add-ach-modal.component.html',
  styleUrls: ['./add-ach-modal.component.css']
})
export class AddAchModalComponent implements OnInit {

  fileToUpload: File = new File([], "")
  errorMessage: string = "";
  showError = false;
  loadSpinner = false;

  form = new FormGroup({
    amount: new FormControl(null, Validators.required),
    checkNumber: new FormControl(null, Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<AddAchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedERAs: FileManagerRow[],
    private api:ApiService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  change(ev:Event) {
    this.fileToUpload = (ev.target as HTMLInputElement).files?.item(0) as File
  }

  dropHandler(ev:DragEvent) {
    ev.preventDefault();

    let theFile: File = ev.dataTransfer?.files[0] as File

    if (theFile.type != "application/pdf") {
      return;
    }

    this.fileToUpload = theFile;
  }

  dragOverHandler(ev:DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  submit() {
    if (this.fileToUpload && this.form.valid) {
      this.loadSpinner = true;

      let eraIdList: number[] = []

      for (let era of this.selectedERAs) {
        eraIdList.push(era.Id)
      }
      
      let amount = this.form.get("amount")?.value
      let checkNumber = this.form.get("checkNumber")?.value

      
      this.api.achUploadFunction(eraIdList, checkNumber, amount, this.fileToUpload, 
        (retVal) => { 

          this.snackBar.open("ACH successfully uploaded!", "Dismiss", {duration: 3000}); 
          this.dialogRef.close(true); //Signal that file page should reload data
        },
        (err) => { //Error
          this.errorMessage = err.message;
          this.showError = true;
          this.loadSpinner = false
        });
        
    }
  }

}
