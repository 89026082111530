import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from'@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EpisodeLineSummary } from 'src/app/domain/episodeLineSummary';
import { ApiService } from 'src/app/api.service';
import { EpisodeStatusIDs } from '../EpisodeStatuses';

@Component({
  selector: 'app-add-era-modal',
  templateUrl: './add-era-modal.component.html',
  styleUrls: ['./add-era-modal.component.css']
})
export class AddEraModalComponent implements OnInit {

  fileToUpload: File = new File([], "")
  errorMessage: string = "";
  showError = false;
  loadSpinner = false;
  missing1500 = false;
  amountFormControl = new FormControl(null, Validators.required);
  checkNumberFormControl = new FormControl(null);
  eraTypeFormControl = new FormControl(null, Validators.required);
  fileToUploadFormControl = new FormControl(null, Validators.required);

  isButtonEnabled = false;

  form = new FormGroup({
    'amount': this.amountFormControl,
    'checkNumber': this.checkNumberFormControl, 
    'eraType': this.eraTypeFormControl,
    'fileToUpload': this.fileToUploadFormControl
    }
  );
  

  constructor(
    public dialogRef: MatDialogRef<AddEraModalComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedEpisodes: EpisodeLineSummary[],
    private api:ApiService, private snackBar: MatSnackBar){}

  ngOnInit(): void {
    for (let episode of this.selectedEpisodes) {
      if (!episode.has1500) {
        this.missing1500 = true
        break
      }
    }

    this.form.valueChanges.subscribe(() => {
      // checking to see if check has no validators and amount is > 0. If both conditions are met, it sets the checkNumber validator to required and updates its value and validity
      if ((!this.checkNumberFormControl.validator || this.checkNumberFormControl.validator.length === 0) && this.amountFormControl.value > 0) {
        this.checkNumberFormControl.setValidators([Validators.required]);
        this.checkNumberFormControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }

      // if the amount is <= 0 then it is not required and updates its value and validity
      else if (this.amountFormControl.value <= 0) {
        this.checkNumberFormControl.setValidators([]);
        this.checkNumberFormControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }

      // amount > 0, has check, has era, has a file TRUE
      if (this.amountFormControl.value > 0 && this.checkNumberFormControl.value && this.eraTypeFormControl.value && this.fileToUploadFormControl.value) {
        this.isButtonEnabled = true;
      }

      // amount = 0, has a file, has era TRUE
      else if (this.amountFormControl.value === 0 && this.eraTypeFormControl.value && this.fileToUploadFormControl.value) {
        this.isButtonEnabled = true;
      }
      else this.isButtonEnabled = false;
    })
  }


  close(): void {
    this.dialogRef.close();
  }

  change(ev:Event) {
    this.fileToUpload = (ev.target as HTMLInputElement).files?.item(0) as File
    this.fileToUploadFormControl.setValue(this.fileToUpload); // Update the form control value to the selected file
  }

  dropHandler(ev:DragEvent) {
    ev.preventDefault();

    let theFile: File = ev.dataTransfer?.files[0] as File

    if (theFile.type != "application/pdf") {
      return;
    }

    this.fileToUpload = theFile;
  }

  dragOverHandler(ev:DragEvent) {
    ev.preventDefault(); // Prevent default behavior (Prevent file from being opened)
  }

  submit() {
    if (this.fileToUpload && this.form.valid) {
      this.loadSpinner = true;

      let episodeIdList: number[] = []

      for (let episode of this.selectedEpisodes) {
        episodeIdList.push(episode.EpisodeMaster_ID)
      }
      
      let payOrderId = this.form.get("eraType")?.value
      let amount = this.form.get("amount")?.value
      let checkNumber = this.form.get("checkNumber")?.value

      this.api.eraUploadFunction(episodeIdList, payOrderId, checkNumber, amount, this.fileToUpload, 
        (retVal) => { //Success  
          for (let episode of this.selectedEpisodes)
            episode.Status_ID = EpisodeStatusIDs.eraPaymentReceived;

          if(amount == 0) {
            this.snackBar.open("Zero Dollar Remit File successfully uploaded!", "Dismiss", {duration: 3000});
          }
          else {
            this.snackBar.open("ERA successfully uploaded!", "Dismiss", {duration: 3000});
          } 
          this.dialogRef.close(true);
        },
        (err) => { //Error
          this.errorMessage = err.message;
          this.showError = true;
          this.loadSpinner = false;
        });
    }
  }

}
