import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ACHFilesQuery, ERAFilesQuery } from 'src/app/apiQueryInterfaces';
import { ApiService } from 'src/app/api.service';
import { ACHFile } from 'src/app/NewDomain/ACHFile';
import { ERAFile } from 'src/app/NewDomain/ERAFile';
import { ACHDocument, EraViewerComponent } from '../era-viewer.component';

@Component({
  selector: 'app-era-viewer-for-era',
  templateUrl: '../era-viewer.component.html',
  styleUrls: ['../era-viewer.component.css']
})
export class EraViewerForEraComponent extends EraViewerComponent implements OnInit {

  constructor(api:ApiService, domSanitizer:DomSanitizer, route: ActivatedRoute, snackBar: MatSnackBar) { 
    super(api, domSanitizer, route, snackBar ) 
  }

  ngOnInit(): void {
    this.wholePageLoad++
    let achQueryParams:ACHFilesQuery = { ERAIds: [ Number(this.route.snapshot.paramMap.get('eraId') as string) ] }
    this.api.getACHFiles(achQueryParams, (res:ACHFile[]) => {
      //There's only 2 possibilities: either the ERA is assigned to an ACH or its not. If the ACH HTTP call returns nothing, then it must be unassigned
      if (res.length == 0) {
        //Create unassigned tab via the -1
        let unassignedACH = new ACHFile
        unassignedACH.ACHFile_ID = -1
        this.achAndERAResults.achs[-1] = this.CreateACHDocumentFromACHFileDomain(unassignedACH)

        let eraQueryParams:ERAFilesQuery = { ids: [ Number(this.route.snapshot.paramMap.get('eraId') as string) ] }
        this.wholePageLoad++
        this.api.getERAFiles(eraQueryParams, (res:ERAFile[]) => {
          if (res.length == 0)
            return

          this.achAndERAResults.eras[res[0].ERAFile_ID] = this.CreateERADocumentFromERAFileDomain(res[0])
          this.achAndERAResults.achs[-1].ERAs.push(res[0].ERAFile_ID)
          this.achAndERAResults.achs[-1].hasLoadedERAs = true
          
          this.setACHTab(0)
          this.selectedACH = this.achAndERAResults.achs[-1]
          this.setERATabOnACH(this.achAndERAResults.achs[-1], 0)
          this.ERATabClick(res[0].ERAFile_ID)
        },
        (err:any) => {
          //this.errorMessage = err.message
          this.wholePageLoad--
        })
      } 
      else { //ERA was assigned to an ACH
        for (let i = 0; i < res.length; ++i) {
          this.achAndERAResults.achs[res[i].ACHFile_ID] = this.CreateACHDocumentFromACHFileDomain(res[i])
        }

        //Fetch ERAs now since user needs to be directed to the one that was entered in the address bar
        let firstACHFile:ACHDocument = this.achAndERAResults.achs[res[0].ACHFile_ID]
        let eraQueryParams:ERAFilesQuery = { ACHIds: [ res[0].ACHFile_ID ] }
        this.wholePageLoad++
        this.api.getERAFiles(eraQueryParams, (res:ERAFile[]) => {

          for (let i = 0; i < res.length; ++i) {
            this.achAndERAResults.eras[res[i].ERAFile_ID] = this.CreateERADocumentFromERAFileDomain(res[i])

            firstACHFile.ERAs.push(res[i].ERAFile_ID)
          }
          firstACHFile.hasLoadedERAs = true

          //Now find and select the ERA
          let eraFound = false
          if (!eraFound) {
            for (let i = 0; i < firstACHFile.ERAs.length; ++i) {
              if (firstACHFile.ERAs[i].toString() == this.route.snapshot.paramMap.get('eraId')) {
                this.setACHTab(0) //Only visually selects it; does not load the document
                this.selectedACH = firstACHFile
                this.setERATabOnACH(firstACHFile, i)
                this.ERATabClick(firstACHFile.ERAs[i])

                eraFound = true
                break
              }
            }
          }
        },
        (err:any) => {
          //this.errorMessage = err.message
          this.wholePageLoad--
        })
      }
      this.wholePageLoad--
    },
    (err:any) => {
      //this.errorMessage = err.message
      this.wholePageLoad--
    })

    this.wholePageLoad--
  }

}
