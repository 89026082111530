import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

export interface PDFDocumentDenial {
    localPDFUrl: string, 
    safePDFUrl: SafeResourceUrl, 
    loadSpinnerPDF: boolean,
    errorMessagePDF: string,
    downloadFileName: string
}

export interface DenialFileObject {
    DenialFile_ID: number,
    FileLocation: string,
    DenialType: number,
    AddDate: Date,
    Notes: string,
    EpisodeMaster_ID: number,
    AddedByName: string,
    AddedByID: number,
    DateOfService: Date,
    PatientFN: string,
    PatientLN: string,
    Patient_DOB: Date,
    MedicareId: string,
    PhysicianFN: string,
    PhysicianLN: string
}

@Component({
    selector: 'app-denial-viewer',
    templateUrl: './denial-viewer.component.html',
    styleUrls: ['./denial-viewer.component.css']
})

export class DenialViewerComponent implements OnInit {

    @Input() denialObject: any;
    @Input() denialFileData: DenialFileObject = this.createEmptyDenialFileObject();
    @Input() wholePageLoad:number = 0;
    episodeLoadSpinner = 1;
    pdfDocumentData: PDFDocumentDenial = this.createEmptyDocument();
    denialType = "";

    constructor(protected api:ApiService, protected domSanitizer:DomSanitizer, protected route: ActivatedRoute,
        protected snackBar: MatSnackBar) { }
    ngOnInit(): void {}

    createEmptyDocument():PDFDocumentDenial {
        return {
          localPDFUrl: "", 
          safePDFUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(""), 
          loadSpinnerPDF: false,
          errorMessagePDF: "",
          downloadFileName: "file"
        }
    }

    createEmptyDenialFileObject(): DenialFileObject {
        return {
            DenialFile_ID: 0,
            FileLocation: "",
            DenialType: 1,
            AddDate: new Date,
            Notes: "",
            EpisodeMaster_ID: 0,
            AddedByName: "",
            AddedByID: 0,
            DateOfService: new Date,
            PatientFN: "",
            PatientLN: "",
            Patient_DOB: new Date,
            MedicareId: "",
            PhysicianFN: "",
            PhysicianLN: ""
        };
    }
}