import { Component, Input } from '@angular/core';
import { EpisodeNote } from 'src/app/NewDomain/EpisodeNote';

@Component({
  selector: 'app-episode-details-sidebar',
  templateUrl: './episode-details-sidebar.component.html',
  styleUrls: ['./episode-details-sidebar.component.css']
})
export class EpisodeDetailsSidebarComponent {

  @Input() EpisodeMaster_ID: number = 0;
  @Input() PhysFirstName: string = "";
  @Input() PhysLastName: string = "";
  @Input() Bundle_Name: string = "";
  @Input() Procedure_Desc: string = "";
  @Input() Facility_Name: string = "";
  @Input() PlaceOfService_TechnicalID: number = 0;
  @Input() PlaceOfService_DisplayName: string = "";
  @Input() PatFirstName: string = "";
  @Input() PatLastName: string = "";
  @Input() PatDOB: Date = new Date();
  @Input() MedicareID: string = "";
  @Input() TargetPrice: number|undefined = undefined;
  @Input() isCJR: boolean = false;
  @Input() notes: EpisodeNote[] = [];
  @Input() isValidator: boolean = false;
  

  constructor() { }

  HasTargetPrice() : boolean{
    return this.TargetPrice != null && !isNaN(this.TargetPrice);
  }

}
