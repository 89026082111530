export function translatePayerOrderToText(payerOrder:number) {
    switch(payerOrder) {
      case 1:
        return "Pri"
      case 2:
        return "Sec"
      case 3:
        return "Ter"
      default:
        return "Unknown"
    }
  }