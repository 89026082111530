import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { EpisodeLineSummary, EpisodeLineSummaryDTO } from "../NewDomain/episodeLineSummary";
import { ApiService } from "../api.service";
import { EpisodeLineSummaryQuery } from "./EpisodeLineSummaryQuery";

export class EpisodeLineSummaryDataSource implements DataSource<EpisodeLineSummary> {

    public episodeLinesSubject = new BehaviorSubject<EpisodeLineSummary[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public totalCount = new BehaviorSubject<number>(0);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private api:ApiService) {}

    connect(collectionViewer: CollectionViewer): Observable<EpisodeLineSummary[]> {
        return this.episodeLinesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.episodeLinesSubject.complete();
        this.loadingSubject.complete();
        this.totalCount.complete();
    }

    getLastEmittedEpisodes(): EpisodeLineSummary[] {
        return this.episodeLinesSubject.getValue()
    }

    loadEpisodeLineSummaries(lineQuery:EpisodeLineSummaryQuery) {

        this.loadingSubject.next(true);

        this.api.getEpisodeLineSummaries((res: EpisodeLineSummaryDTO) => 
        {
            this.episodeLinesSubject.next(res.episodeLineSummaries)
            this.totalCount.next(res.count)
            this.loadingSubject.next(false)
        },
        (err:any) => {
            //this.errorMessage = err.message
            this.totalCount.next(0)
            this.loadingSubject.next(false);
        }, 
        lineQuery)
    }    
}