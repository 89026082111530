export class EpisodeStatuses {
    static pendingApproval: string = "Pending Approval";
    static notEligible: string = "Not Eligible";
    static readyForClaim: string = "Ready For Claim";
    static validatingClaim: string = "Validating Claim";
    static processingClaim: string = "Processing Claim";
    static eraPaymentReceived: string = "ERA Payment Received";
    static submissionFailed: string = "Submission Failed";
    static completed: string = "Completed";
    static cancelled: string = "Cancelled";
    static onHold: string = "On-Hold";
    static surgeryScheduled: string = "Surgery Scheduled";
    static denied: string ="Denied";
  }

  export enum EpisodeStatusIDs {
    notEligible = 1,
    submissionFailed = 2,
    pendingApproval = 3,
    readyForClaim = 4,
    processingClaim = 5,
    eraPaymentReceived = 6,
    completed = 7,
    validatingClaim = 8,
    cancelled = 9,
    onHold = 10,
    surgeryScheduled = 11,
    denied = 12
}

export const EpisodeStatusTextToIDMap:Map<string, number> = new Map()
EpisodeStatusTextToIDMap.set(EpisodeStatuses.notEligible, EpisodeStatusIDs.notEligible)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.submissionFailed, EpisodeStatusIDs.submissionFailed)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.pendingApproval, EpisodeStatusIDs.pendingApproval)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.readyForClaim, EpisodeStatusIDs.readyForClaim)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.processingClaim, EpisodeStatusIDs.processingClaim)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.eraPaymentReceived, EpisodeStatusIDs.eraPaymentReceived)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.completed, EpisodeStatusIDs.completed)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.validatingClaim, EpisodeStatusIDs.validatingClaim)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.cancelled, EpisodeStatusIDs.cancelled)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.onHold, EpisodeStatusIDs.onHold)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.surgeryScheduled, EpisodeStatusIDs.surgeryScheduled)
EpisodeStatusTextToIDMap.set(EpisodeStatuses.denied, EpisodeStatusIDs.denied)