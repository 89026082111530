import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "../api.service";
import { FileManagerDTO, FileManagerRow } from "../NewDomain/FileManagerRow";
import { FileManagerQuery } from "./FileManagerQuery";

export class FileManagerDataSource implements DataSource<FileManagerRow> {

    public fileManagerRowSubject = new BehaviorSubject<FileManagerRow[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public totalCount = new BehaviorSubject<number>(0);
    public loading$ = this.loadingSubject.asObservable();

    constructor(private api:ApiService) {}

    connect(collectionViewer: CollectionViewer): Observable<FileManagerRow[]> {
        return this.fileManagerRowSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.fileManagerRowSubject.complete();
        this.loadingSubject.complete();
        this.totalCount.complete();
    }

    getLastEmittedEpisodes(): FileManagerRow[] {
        return this.fileManagerRowSubject.getValue()
    }

    loadFileManagerRows(lineQuery:FileManagerQuery) {

        this.loadingSubject.next(true);

        this.api.getFileManager((res: FileManagerDTO) => 
        {
            this.fileManagerRowSubject.next(res.fileManagerRows)
            this.totalCount.next(res.count)
            this.loadingSubject.next(false)
        },
        (err:any) => {
            //this.errorMessage = err.message
            this.totalCount.next(0)
            this.loadingSubject.next(false);
        }, 
        lineQuery)
    }    
}