<div class="page-wrapper" style="display:flex; flex-direction: column; height: 100%">
    <div class="page-title">Validate Claim File</div>
    <mat-divider class="page-divider" style="margin-bottom: 15.5px;"></mat-divider>

    <div *ngIf="!errorMessage; else error" style="flex: 1; display:flex; flex-direction: column; position: relative">
        <app-loading-spinner *ngIf="loadSpinner > 0"></app-loading-spinner>

        <div style="display:flex; flex: 1; justify-content: space-between;">
            <app-episode-details-sidebar [EpisodeMaster_ID]="this.episodeDetails.EpisodeMaster_ID"
                [PhysFirstName]="this.episodeDetails.PhysFirstName" [PhysLastName]="this.episodeDetails.PhysLastName"
                [Bundle_Name]="this.episodeDetails.Bundle_Name" [Procedure_Desc]="this.episodeDetails.Procedure_Desc"
                [Facility_Name]="this.episodeDetails.Facility_Name"
                [PlaceOfService_TechnicalID]="this.episodeDetails.PlaceOfService_TechnicalID"
                [PlaceOfService_DisplayName]="this.episodeDetails.PlaceOfService_DisplayName"
                [PatFirstName]="this.episodeDetails.PatFirstName" [PatLastName]="this.episodeDetails.PatLastName"
                [PatDOB]="this.episodeDetails.PatDOB" [MedicareID]="this.episodeDetails.MedicareID"
                [TargetPrice]="this.episodeDetails.TargetPrice" [isCJR]="this.episodeDetails.isCJR" [notes]="this.notes"
                [isValidator]=this.user.ValidatorFlag></app-episode-details-sidebar>


            <div style="display: flex; flex-direction: column; position: relative" width="600px">
                <div *ngIf="!errorMessagePDF; else pdfError" style="display: flex; flex: 1; flex-direction: column">
                    <div style="position: relative">
                        <app-loading-spinner *ngIf="loadSpinner == 0 && loadSpinnerPDF"></app-loading-spinner>
                        <embed [src]="safeLocalPDFUrl" type='application/pdf' width="600px" height="660px" />
                        <div style="display: flex; align-items: stretch; margin-top: 30px;"> 
                            <div style="display: flex; flex-direction: column; margin-left: 5px"
                                [ngStyle]="{'visibility' : validator ? 'visible' : 'hidden'}">
                                <mat-form-field class="form-field-no-bottom" style="width: 398px;">
                                    <mat-label>Reason for Deleting File</mat-label>
                                    <textarea matInput [(ngModel)]="reason" [formControl]="reasonControl"
                                        maxlength="100"></textarea>
                                </mat-form-field>
                                
                                <button mat-raised-button color="warn" style="margin-top: 16px; align-self: flex-end"
                                type="button" [disabled]="reasonControl.invalid"
                                (click)="openDeleteConfirmationDialog(this.episodeDetails.EpisodeMaster_ID.toString(), this.reason)">Delete</button>
                            
                            </div> 
                            <div style="flex: 1"></div>
                            <div style="flex: 1; display: flex; justify-content: center;">
                                <a [href]="safeLocalPDFUrl"
                                    [download]="'EPISODE_' + this.episodeDetails.EpisodeMaster_ID.toString() + '_CLAIM.pdf'"
                                    style="color: inherit; position: relative;  width: 48px;">
                                    <mat-icon
                                        style="font-size: 48px; width: 48px; position:absolute; top: -5px">file_download</mat-icon>
                                </a>
                            </div>
                            <div style="flex: 1; display: flex; justify-content: flex-end; align-items: center"
                                [ngStyle]="{'visibility' : validator ? 'visible' : 'hidden'}">
                                <button mat-raised-button color="accent" type="button"
                                    (click)="setProcessingClaimClick()">SET PROCESSING CLAIM</button>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; flex: 1; align-items: flex-end;">
                        <app-back-button></app-back-button>
                    </div>
                </div>

                <ng-template #pdfError>
                    <div height="100%" width="100%">
                        Could not download PDF<br><br>

                        Server Error: {{this.errorMessagePDF}}
                    </div>
                </ng-template>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 5px"
                [ngStyle]="{'visibility' : validator ? 'visible' : 'hidden'}">
                <mat-form-field class="form-field-no-bottom" style="width: 398px;">
                    <mat-label>Notes</mat-label>
                    <textarea style="height: 112px" matInput [formControl]="noteControl"></textarea>
                </mat-form-field>

                <button mat-raised-button color="accent"
                    style="margin-top: 16px; align-self: flex-end; background-color: var(--accent-medium); color: black"
                    type="button" (click)="submitNote()">SET SUBMISSION FAILED</button>

                <mat-form-field style="margin-top: 117px">
                    <mat-select [formControl]="statusControl">
                        <mat-option
                            *ngFor="let status of episodeStatuses | visibleEpisodeStatus | applicableEpisodeStatuses : episodeDetails : episodeDetails.CurrentStatusID"
                            [value]="status.EpisodeStatus_ID">
                            {{status.EpisodeStatus_Name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Override/Reset an Episode Status</mat-hint>
                </mat-form-field>

                <button mat-raised-button style="margin-top: 16px; align-self: flex-end" type="button"
                    (click)="overrideStatusClick()">UPDATE</button>
            </div>

        </div>

    </div>

    <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

</div>