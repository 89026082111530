import { EpisodeDetails } from "./EpisodeDetails"

export class Episode {
    Episode_ID: number
    Physician_ID: number|undefined
    Status_ID: number|undefined
    PlaceOfService_Value: number|undefined
    DateOfService: Date|undefined
    Procedure_Desc: string|undefined
    Payer: string|undefined
    TypeOfService: string|undefined
    Bundle_ID: number|undefined
    Facility_ID: number|undefined
    PlaceOfService_ID: number|undefined

    constructor() {
        this.Episode_ID = 0
    }

    PopulateFromEpisodeDetails(episodeDetails:EpisodeDetails) {
        this.Episode_ID = episodeDetails.EpisodeMaster_ID
        this.Physician_ID = episodeDetails.PhysicianID
        this.Status_ID = episodeDetails.CurrentStatusID
        this.DateOfService = episodeDetails.DateOfService
        this.Procedure_Desc = episodeDetails.Procedure_Desc
        this.Bundle_ID = episodeDetails.BundleID
    }
}