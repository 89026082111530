<div class="page-wrapper" style="height: 100%; max-width: calc(100vw - 289px);">
    <div style="display: flex; height: 100%; position: relative">
        <app-loading-spinner *ngIf="wholePageLoad > 0"></app-loading-spinner>

        <div style="display:flex; flex-direction: column;" [ngClass]="wholePageLoad ? 'loadingTrue' : 'loadingFalse'">

            <div class="page-title">ACH/ERA Viewer</div>
            <mat-divider class="page-divider" style="margin-bottom: 15.5px;"></mat-divider>

            <div *ngIf="selectedERA != undefined" style="display: flex; flex-direction: column; flex: 1; position: relative;">
                <app-loading-spinner *ngIf="episodeLoadSpinner > 0"></app-loading-spinner>

                <div>
                    <div *ngIf="selectedERA.SelectedEpisode != undefined">
                        <div class="section-title">Selected Episode</div>
                        <div class="spacer" style="display: flex;">
                            <mat-checkbox [checked]="selectedERA.SelectedEpisode.ERA_Finished" [disabled]="selectedERA.SelectedEpisode.ERA_Finished" style="margin-right: 27px;" (change)="$event.checked ? selectedERA.numSelected=selectedERA.numSelected+1 : selectedERA.numSelected=selectedERA.numSelected-1; selectedERA.SelectedEpisode.selected = $event.checked"></mat-checkbox>
                            <div>
                                {{selectedERA.SelectedEpisode.Physician_LastName}}, {{selectedERA.SelectedEpisode.Physician_FirstName}}<br>
                                {{selectedERA.SelectedEpisode.DateOfService | date:"MM/dd/yy":"UTC"}}<br>
                                {{selectedERA.SelectedEpisode.Patient_LastName}}, {{selectedERA.SelectedEpisode.Patient_FirstName}}, {{selectedERA.SelectedEpisode.DateOfBirth | date:"MM/dd/yy":"UTC"}}<br>
                                {{selectedERA.SelectedEpisode.PayerID}}
                            </div>
                        </div>
                    </div>
                

                    <div class="section-title" style="margin-top: 15px;">Related Episodes</div>
                    <div *ngFor="let relEpisode of selectedERA.Episodes" class="spacer" style="display: flex;">
                        <mat-checkbox [checked]="relEpisode.ERA_Finished" [disabled]="relEpisode.ERA_Finished" style="margin-right: 27px" (change)="$event.checked ? selectedERA.numSelected=selectedERA.numSelected+1 : selectedERA.numSelected=selectedERA.numSelected-1; relEpisode.selected = $event.checked"></mat-checkbox>
                        <div>
                            {{relEpisode.Physician_LastName}}, {{relEpisode.Physician_FirstName}}<br>
                            {{relEpisode.DateOfService | date:"MM/dd/yy":"UTC"}}<br>
                            {{relEpisode.Patient_LastName}}, {{relEpisode.Patient_FirstName}}, {{relEpisode.DateOfBirth | date:"MM/dd/yy":"UTC"}}<br>
                            {{relEpisode.PayerID}}
                        </div>
                    </div>
                </div>


                <div style="margin-bottom: 15px; display: flex; flex: 1; align-items: flex-end">
                    <button mat-raised-button color="accent" type="button" [disabled]="selectedERA.numSelected == 0 || selectedACH?.ACHFile_ID == -1" (click)="setFinished(selectedERA)">SET FINISHED</button>
                </div>
            </div>

        </div>



        

        <div style="display:flex; flex-direction: column; width: 100%">
            <mat-tab-group [(selectedIndex)]="selectedACHTabIndex"  animationDuration="0">
                <mat-tab></mat-tab>
                <mat-tab *ngFor="let achDocument of achAndERAResults.achs | keyvalue; index as i">

                    <ng-container *ngIf="achDocument.value.ACHFile_ID == -1; then UnassignedLabel; else ACHLabel"> </ng-container>

                    <ng-template #UnassignedLabel>
                        <ng-template mat-tab-label>
                            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" (click)=unassignedTabClick(achDocument.value)></div>
                            Unassigned
                        </ng-template>
                    </ng-template>

                    <ng-template #ACHLabel>
                        <ng-template mat-tab-label>
                            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" (click)=ACHTabClick(achDocument.value)></div>
                            <div style="display: flex; justify-content: space-between; width: 100%">
                                <img src="assets/ACH Icon.svg" height="24px" style="cursor: pointer;" />
                                <div style="width: 20px;"></div>
                                <div style="text-align: right">
                                    <div class="tabMainTitle">#{{achDocument.value.CheckNumber}}</div>
                                    <div class="tabSubTitle">{{achDocument.value.Amount | currency:"USD"}}</div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-template>

                    <mat-tab-group [(selectedIndex)]="achDocument.value.eraIndexSelected" animationDuration="0">
                        <mat-tab></mat-tab>
                        <mat-tab *ngFor="let eraDocumentId of achDocument.value.ERAs; index as j">
                            <ng-template mat-tab-label>
                                <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" (click)=ERATabClick(eraDocumentId)></div>
                                <div style="width: 100%">
                                    <div style="display: flex; justify-content: space-between; width: 100%;">
                                        <img src="assets/ERA Icon.svg" height="24px" style="cursor: pointer;" />
                                        <div style="width: 20px;"></div>
                                        <div style="text-align: right">
                                            <div class="tabMainTitle">ERA {{j+1}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tabSubTitle" style="align-self: flex-start">#{{achAndERAResults.eras[eraDocumentId].CheckNumber}} | {{this.translatePayerOrderToText(achAndERAResults.eras[eraDocumentId].PayerOrder_ID)}}</div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                        
                </mat-tab>
            </mat-tab-group>

            <div style="display: flex; flex: 1; position: relative; margin-top: 20px;">
                    
                <div style="position: relative; flex: 1; display: flex; flex-direction: column">
                    <app-loading-spinner *ngIf="selectedPDFDocument?.loadSpinnerPDF"></app-loading-spinner>
        
                    <div *ngIf="!selectedPDFDocument?.errorMessagePDF; else pdfError" style="width: 100%; flex: 1">
                        <embed [src]="selectedPDFDocument?.safePDFUrl" type='application/pdf' style="width: 100%; height: 100%" />
                    </div>
        
                    <div style="display: flex; margin-top: 30px;">
                        <div style="flex: 1">
                            <app-back-button></app-back-button>
                        </div>
        
                        <div *ngIf="selectedPDFDocument.localPDFUrl != ''" style="flex: 1; display: flex; justify-content: center;">
                            <a [href]="selectedPDFDocument?.safePDFUrl" [download]="selectedPDFDocument.downloadFileName" style="color: inherit; position: relative;  width: 48px;">
                                <mat-icon style="font-size: 48px; width: 48px; margin-bottom: 25px">file_download</mat-icon>
                            </a>
                        </div>
        
                        <div style="flex: 1"></div>
                    </div>
        
                    <ng-template #pdfError>
                        <div style="flex: 1" height="100%" width="100%">
                            Could not download PDF<br><br>
        
                            Server Error: {{selectedPDFDocument?.errorMessagePDF}}
                        </div>
                    </ng-template>
                </div>
            </div>

            <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

        </div>


    </div>
  
</div>