import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  
  public isValidator: boolean;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.api.getUserADSelf()
    .subscribe({
      next: user => {
       this.isValidator = user.ValidatorFlag;
      },
      error: err => {
        this.isValidator = false;
      }
    });
  }
}
