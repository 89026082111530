import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { EpisodeStatusIDs } from '../EpisodeStatuses';
import { FormControl, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EpisodeDetails, EpisodeDetailsResponseDTO } from 'src/app/NewDomain/EpisodeDetails';
import { EpisodeStatus } from 'src/app/NewDomain/EpisodeStatus';
import { ApiService } from 'src/app/api.service';
import { EpisodeNote } from 'src/app/NewDomain/EpisodeNote';
import { Episode } from 'src/app/NewDomain/Episode';
import { compare } from 'fast-json-patch';
import { ValidationResult } from 'src/app/NewDomain/ValidationResult';
import { UserAD } from 'src/app/NewDomain/UserAD';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../DeleteConfirmationDialogComponent/delete-confirmation-dialog.component';
@Component({
  selector: 'app-validate-claim-file',
  templateUrl: './validate-claim-file.component.html',
  styleUrls: ['./validate-claim-file.component.css']
})
export class ValidateClaimFileComponent implements OnInit {
  loadSpinner = 0;
  loadSpinnerPDF = true;
  errorMessage = '';
  errorMessagePDF = '';
  localPDFUrl: string = '';
  finalURL: string = '';
  safeLocalPDFUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('');
  notes: EpisodeNote[] = [];
  episodeDetails: EpisodeDetails = new EpisodeDetails();
  episodeStatuses: EpisodeStatus[] = [];
  lastUpdatedStatus: number = 0;
  user: UserAD = new UserAD();
  noteControl = new FormControl('');
  statusControl = new FormControl('');
  reasonControl = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  reason: string = '';
  get validator(): boolean {
    return this.user.ValidatorFlag;
  }
  constructor(
    private api: ApiService,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private location: Location,
    private authService: MsalService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {
    this.api.getEpisodeDetails(
      Number(this.route.snapshot.paramMap.get('episodeId')),
      (res: EpisodeDetailsResponseDTO) => {
        this.episodeDetails = res.episodeDetails;
        this.notes = res.episodeNotes;
        this.statusControl.setValue(this.episodeDetails.CurrentStatusID);
        this.lastUpdatedStatus = this.episodeDetails.CurrentStatusID;
        this.episodeDetails.files = this.episodeDetails.files = this.episodeDetails.files || [];
        this.loadSpinner--;
      },
      (err: any) => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      }
    );

    this.api.downloadClaimFileForEpisodeID(
      parseInt(this.route.snapshot.paramMap.get('episodeId') as string),
      (res: any) => {
        this.localPDFUrl = URL.createObjectURL(res);
        this.safeLocalPDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.localPDFUrl);
        this.loadSpinnerPDF = false;
      },
      (err: any) => {
        this.errorMessagePDF = err.message;
        this.loadSpinnerPDF = false;
      }
    );


    this.loadSpinner++;

    console.log("After getEpisodeDetails");
    this.loadSpinner++;
    this.api.getEpisodeStatuses(
      (res: EpisodeStatus[]) => {
        this.episodeStatuses = res;
        this.loadSpinner--;
      },
      (err) => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      }
    );
    this.loadSpinner++;
    this.api.getUserADSelf().subscribe({
      next: (user) => {
        this.user = user;
        this.loadSpinner--;
      },
      error: (err) => {
        this.errorMessage = err.message;
        this.loadSpinner--;
      }
    });
  }
  ngOnDestroy(): void {
    URL.revokeObjectURL(this.localPDFUrl);
  }
  back() {
    this.location.back();
  }
  submitStatusError = false;
  submitNote() {
    this.submitStatusError = false;
    if (this.noteControl.value) {
      this.loadSpinner++;
      this.api.postEpisodeNote(
        (resp) => {
          this.notes.push(resp);
          this.noteControl.reset();
          this.loadSpinner--;
          this.submitNoteFinally();
        },
        (err) => {
          this.submitStatusError = true;
          this.loadSpinner--;
          this.submitNoteFinally();
        },
        this.episodeDetails.EpisodeMaster_ID,
        this.noteControl.value
      );
    }
    if (this.lastUpdatedStatus != EpisodeStatusIDs.submissionFailed) {
      this.loadSpinner++;
      let blankEpisode: Episode = new Episode();
      let compareEpisode: Episode = new Episode();
      compareEpisode.Status_ID = EpisodeStatusIDs.submissionFailed;
      let patch = compare(blankEpisode, compareEpisode);
      this.api.patchEpisode(
        (resp: ValidationResult) => {
          this.lastUpdatedStatus = EpisodeStatusIDs.submissionFailed;
          this.loadSpinner--;
          this.submitNoteFinally();
        },
        (err) => {
          this.submitStatusError = true;
          this.loadSpinner--;
          this.submitNoteFinally();
        },
        this.episodeDetails.EpisodeMaster_ID,
        patch
      );
    }
    this.submitNoteFinally();
  }
  submitNoteFinally() {
    if (this.loadSpinner == 0) {
      if (this.submitStatusError) {
        this.snackBar.open('Status could not be updated due to an error.', 'Dismiss');
      } else {
        this.snackBar.open('Status successfully updated!', 'Dismiss', { duration: 3000 });
        this.router.navigate(['/patientEpisodeManager']);
      }
    }
  }
  setProcessingClaimClick() {
    this.submitStatus(EpisodeStatusIDs.processingClaim, true);
  }
  overrideStatusClick() {
    this.submitStatus(this.statusControl.value, false);
  }
  submitStatus(status: number, goBackAfter: boolean) {
    if (status == this.lastUpdatedStatus) {
      this.snackBar.open('Status successfully updated!', 'Dismiss', { duration: 3000 });
    } else {
      this.loadSpinner++;
      let blankEpisode: Episode = new Episode();
      let compareEpisode: Episode = new Episode();
      compareEpisode.Status_ID = status;
      let patch = compare(blankEpisode, compareEpisode);
      this.api.patchEpisode(
        (resp: ValidationResult) => {
          this.lastUpdatedStatus = status;
          this.snackBar.open('Status successfully updated!', 'Dismiss', { duration: 3000 });
          this.loadSpinner--;
          if (goBackAfter) {
            this.router.navigate(['/patientEpisodeManager']);
          }
        },
        (err) => {
          this.snackBar.open('Status could not be updated due to an error.', 'Dismiss');
          this.loadSpinner--;
        },
        this.episodeDetails.EpisodeMaster_ID,
        patch
      );
    }
  }
  openDeleteConfirmationDialog(episodeId: number, reason: string): void {
    if (
      this.episodeDetails.CurrentStatusID === EpisodeStatusIDs.cancelled ||
      this.episodeDetails.CurrentStatusID === EpisodeStatusIDs.completed
    ) {
      this.snackBar.open('File deletion is not allowed for this status.', 'Dismiss', { duration: 3000 });
    } else {
      const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
        width: '300px',
        data: { episodeId: episodeId, reason: reason, userName: this.user.UserName }
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.submitStatus(EpisodeStatusIDs.readyForClaim, true);
        this.statusControl.setValue(EpisodeStatusIDs.readyForClaim);
        if (result && result.confirm) {
          this.deleteFileManager(episodeId, reason);
        }
      });
    }
  }
  deleteFileManager(episodeId: number, reason: string): void {
    this.api.deleteFileManager(
      episodeId,
      (resp: any) => { // Success callback
        this.snackBar.open('File deletion successful', 'Dismiss', { duration: 3000 });
        // Remove the file from the UI
        const fileIndex = this.episodeDetails.files.findIndex((file) => file.id === episodeId);
        if (fileIndex !== -1) {
          this.episodeDetails.files.splice(fileIndex, 1);
          // binding this variable
          this.safeLocalPDFUrl = '';
        }
        this.dialog.closeAll();
      },
      (err: any) => { // Error callback
        console.error(err);
      }
    );
  }
}

