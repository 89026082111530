import { Component, OnInit } from '@angular/core';
import { DenialViewerComponent } from './denial-viewer.component';
import { ApiService } from 'src/app/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-era-viewer-for-denial',
    templateUrl: './denial-viewer.component.html',
    styleUrls: ['./denial-viewer.component.css']
})

export class EraViewerForDenialComponent extends DenialViewerComponent implements OnInit {
    constructor(api:ApiService, domSanitizer:DomSanitizer, route: ActivatedRoute, snackBar: MatSnackBar) { 
        super(api, domSanitizer, route, snackBar ) 
    }

    ngOnInit(): void {
        this.wholePageLoad++
        let denialQueryParams = { DenialIds: [ Number(this.route.snapshot.paramMap.get('denialId') as string) ] };
        let episodeQueryParams = { EpisodeId: [ Number(this.route.snapshot.paramMap.get('episodeId') as string) ] };

        if(episodeQueryParams.EpisodeId.length && episodeQueryParams.EpisodeId[0] != 0) {
            this.api.getDenialIdFromEpisodeId(episodeQueryParams,
            (res: any) => {
                console.log(res);
                denialQueryParams = { DenialIds: res};
                this.getDenialFile(denialQueryParams);
            },
            (err: any) => {
                this.wholePageLoad--;
                this.episodeLoadSpinner--;
            });
        }
        else if (denialQueryParams.DenialIds.length && denialQueryParams.DenialIds[0] != 0) {
            this.getDenialFile(denialQueryParams);
        }

    }

    getDenialFile (denialQueryParams) {
        this.api.getDenialFiles(denialQueryParams, (res: any) => {
            console.log(res);
            if (res.length > 0) {
                this.denialObject = res;
                this.denialFileData.FileLocation = this.denialObject[0].FileLocation;
                this.denialFileData.Notes = this.denialObject[0].Notes;
                this.denialFileData.DenialType = this.denialObject[0].DenialType;
                this.denialType = (this.denialObject[0].DenialType == 1) ? 'Bundle at Risk' : 'Bundle not at Risk';
                this.denialFileData.AddDate = this.denialObject[0].AddDate;
                this.denialFileData.AddedByName = this.denialObject[0].AddedByName;
                this.api.downloadDenialFile(this.denialObject[0].DenialFile_ID,
                    (res: any) => {
                        this.pdfDocumentData.localPDFUrl = URL.createObjectURL(res);
                        this.pdfDocumentData.safePDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfDocumentData.localPDFUrl);
                        this.pdfDocumentData.loadSpinnerPDF = false;
                        this.pdfDocumentData.downloadFileName = "Denial_" + this.denialObject[0].DenialFile_ID + ".pdf";
                        this.wholePageLoad--;
                        this.episodeLoadSpinner--;
                    },
                    (err: any) => {
                        this.pdfDocumentData.errorMessagePDF = err.message;
                        this.pdfDocumentData.loadSpinnerPDF = false;
                        this.wholePageLoad--;
                        this.episodeLoadSpinner--;
                    });
            }
            else {
                console.log("No data");
                this.wholePageLoad--;
                this.episodeLoadSpinner--;
            }
        }, (err: any) => {
            this.wholePageLoad--;
            this.episodeLoadSpinner--;
        });
    }
}