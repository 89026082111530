import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'add-additional-modal',
    templateUrl: './add-additional-doc-modal.component.html',
    styleUrls: ['./add-additional-doc-modal.component.css']
})

export class AddAdditionalDocModalComponent implements OnInit {

    fileToUpload: File = new File([], "")
    errorMessage: string = "";
    loadSpinner = false;
    showError = false;
    episodeDetails;
    userDetails;

    fileTypes = [{typeId: 1, typeName: 'Corrected Claim'}, {typeId: 2, typeName: 'OP Note'}, {typeId: 3, typeName: 'Secondary Insurance'}, {typeId: 4, typeName: 'Other'}];
    typeSelected = 4;
    notes = '';

    constructor(
        public dialogRef: MatDialogRef<AddAdditionalDocModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api:ApiService, private snackBar: MatSnackBar){}

    ngOnInit(): void {
        this.episodeDetails = this.data.episodeDetails;
        this.userDetails = this.data.userDetails;
    }

    dropHandler(ev:DragEvent) {
        ev.preventDefault();
    
        let theFile: File = ev.dataTransfer?.files[0] as File
    
        if (theFile.type != "application/pdf") {
          return;
        }
    
        this.fileToUpload = theFile;
    }
    
    dragOverHandler(ev:DragEvent) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }

    change(ev:Event) {
        this.fileToUpload = (ev.target as HTMLInputElement).files?.item(0) as File
    }

    submit() {
        if (this.fileToUpload) {
            this.api.uploadAdditionalDocuments(this.typeSelected, this.fileToUpload, this.notes, this.episodeDetails.EpisodeMaster_ID, this.userDetails.UserName, this.userDetails.UserAD_ID, this.userDetails.ValidatorFlag,
                (retVal) => {
                    //reload list
                    this.snackBar.open("File successfully uploaded!", "Dismiss", {duration: 3000});
                    this.dialogRef.close(true); 
                },
                (err) => {
                    this.dialogRef.close(false);
                    this.snackBar.open("File not uploaded, try again!", "Dismiss", {duration: 3000});
                });
        }
    }

    close(): void {
        this.dialogRef.close(false);
    }
}