import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'file-delete-confirmation',
    templateUrl: './file-delete-confirmation.component.html',
    styleUrls: ['./file-delete-confirmation.component.css']
})

export class FileDeleteConfirmationComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<FileDeleteConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any){}
    
    ngOnInit(): void {
        
    }

    close(action): void {
        this.dialogRef.close(action);
    }
}