import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService as ApiService } from '../../api.service';

@Component({
    selector: 'notes-deleted-docs',
    templateUrl: './notes-deleted-docs.component.html',
    styleUrls: ['./notes-deleted-docs.component.css']
})

export class NotesDeletedDocsComponent implements OnInit {

    deletedFileResponse:any;

    constructor(public dialogRef: MatDialogRef<NotesDeletedDocsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private api:ApiService){}
    
    ngOnInit(): void {
        this.api.getDeletedAddDocsData(this.data.episode.EpisodeMaster_ID, (res) => {
            this.deletedFileResponse = res;
        }, 
        (err) => {
            console.log(err);
        });
    }

    close(action?) {
        this.dialogRef.close(action);
    }

    noteReviewedChecked(event, deletedReson) {
        let checked = 0;
        if(event.checked) {
            checked = 1;
        }
        this.api.updateDeletedFileReviewed(deletedReson.AddDoc_Id, checked, () => {
        },
        (err) => {
            console.log(err);
        })
    }
}