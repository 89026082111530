import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { EpisodeCancellationReason } from 'src/app/NewDomain/EpisodeCancellationReason';

@Component({
  selector: 'app-cancel-episode-modal',
  templateUrl: './cancel-episode-modal.component.html',
  styleUrls: ['./cancel-episode-modal.component.css']
})
export class CancelEpisodeModalComponent implements OnInit {

  errorMessage: string = "";
  showError = false;
  loadSpinner = false;

  showConfirm = true

  episodeCancellationReasonList: EpisodeCancellationReason[] = []
  
  form = new FormGroup({
    cancellationReason: new FormControl(undefined, Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<CancelEpisodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public episodeId: number,
    private api:ApiService, private snackBar: MatSnackBar,
    private router: Router){}


  ngOnInit(): void {
    this.loadSpinner = true
    this.api.getEpisodeCancellationReasons( 
      (resp: EpisodeCancellationReason[]) => {
        
        //The list should be alphabetized (comes in that way from the server) except for the "Other" entry; it should be taken out and put on the bottom if it exists
        let otherEntry:EpisodeCancellationReason|undefined = undefined
        for (let i = 0; i < resp.length; i++) {
          if (resp[i].Reason === "Other")
            otherEntry = resp[i]
          else
            this.episodeCancellationReasonList.push(resp[i])
        }

        if (otherEntry)
          this.episodeCancellationReasonList.push(otherEntry)

        this.loadSpinner = false
      },
      (err) => {
        this.errorMessage = err.message
        this.loadSpinner = false
      })
  }

  close(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.valid) {
      this.loadSpinner = true

      this.api.cancelEpisode(this.episodeId, this.form.get("cancellationReason")?.value,
        (resp) => {
          this.snackBar.open("Episode successfully cancelled!", "Dismiss", {duration: 3000});
          this.loadSpinner = false
          this.close()
          this.router.navigate(['/patientEpisodeManager']);
        },
        (err) => {
          this.errorMessage = err.message
          this.loadSpinner = false
        })
    }
  }

}
