import { EpisodeNote } from "./EpisodeNote"

export class EpisodeDetails {
    "EpisodeMaster_ID": number
    "PhysicianID": number
    "PhysFirstName": string
    "PhysLastName": string
    "BundleID": number
    "Bundle_Name": string
    "Procedure_Desc": string
    "Facility_Name": string
    "PlaceOfService_TechnicalID": number
    "PlaceOfService_DisplayName": string
    "PatFirstName": string
    "PatLastName": string
    "PatDOB": Date
    "MedicareID": string
    "TargetPrice": number
    "isCJR": boolean
    "CurrentStatusID": number
    "Practice_Name"?: string
    "DateOfService"?: Date
    "files": any[] = [];
    constructor() {
    }
  }

  export interface EpisodeDetailsResponseDTO {
    episodeDetails:EpisodeDetails,
    episodeNotes:EpisodeNote[]
  }