import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ERADocument, EraViewerComponent } from '../era-viewer.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api.service';
import { ACHFilesQuery, ERAFilesQuery } from 'src/app/apiQueryInterfaces';
import { ACHFile } from 'src/app/NewDomain/ACHFile';
import { ERAFile } from 'src/app/NewDomain/ERAFile';

@Component({
  selector: 'app-era-viewer-for-episode',
  templateUrl: '../era-viewer.component.html',
  styleUrls: ['../era-viewer.component.css']
})
export class EraViewerForEpisodeComponent extends EraViewerComponent implements OnInit {

  constructor(api:ApiService, domSanitizer:DomSanitizer, route: ActivatedRoute, snackBar: MatSnackBar) { 
      super(api, domSanitizer, route, snackBar ) 
    }

  ngOnInit(): void {
    this.wholePageLoad++
    let achQueryParams:ACHFilesQuery = { episodeIds: [ Number(this.route.snapshot.paramMap.get('episodeId') as string) ] }
    this.api.getACHFiles(achQueryParams, (res:ACHFile[]) => {

      for (let i = 0; i < res.length; ++i) {
        this.achAndERAResults.achs[res[i].ACHFile_ID] = { ACHFile_ID: res[i].ACHFile_ID,
                                                          CheckNumber: res[i].CheckNumber,
                                                          Amount: res[i].Amount,
                                                          pdfDocument: this.createEmptyDocument(),
                                                          ERAs: [],
                                                          eraIndexSelected: 0,
                                                          hasLoadedERAs: false
                                                        }
      }

      this.wholePageLoad--
    },
    (err:any) => {
      //this.errorMessage = err.message
      this.wholePageLoad--
    })

    //We need to know whether or not there are any unassigned ERAs. This fetches all unassigned ERAs, and if they exist, creates the unassigned tab via the -1 ID ACH file
    //and adds these ERAs to it
    this.wholePageLoad++
    let eraQueryParams:ERAFilesQuery = { episodeIDsForUnassigned: [ Number(this.route.snapshot.paramMap.get('episodeId') as string) ] }
    this.api.getERAFiles(eraQueryParams, (eras:ERAFile[]) => {
      if (eras.length > 0) {
        let unassignedACH = new ACHFile
        unassignedACH.ACHFile_ID = -1
        this.achAndERAResults.achs[-1] = this.CreateACHDocumentFromACHFileDomain(unassignedACH)

        for (let i = 0; i < eras.length; ++i) {
          this.achAndERAResults.achs[-1].ERAs.push(eras[i].ERAFile_ID)
          this.achAndERAResults.eras[eras[i].ERAFile_ID] = this.CreateERADocumentFromERAFileDomain(eras[i])
        }
      }

      this.wholePageLoad--
    },
    (err:any) => {
      //this.errorMessage = err.message
      this.wholePageLoad--
    })
  }

  additionalERALoadingProcessing(era:ERADocument) {
    super.additionalERALoadingProcessing(era)

    //Pull out the selected episode; this is a bit hacky so maybe change in the future
    for (let i = 0; i < era.Episodes.length; ++i){
      if (era.Episodes[i].EpisodeMaster_ID.toString() == this.route.snapshot.paramMap.get('episodeId')) {
        era.SelectedEpisode = era.Episodes[i]
        era.Episodes.splice(i, 1)
        break
      }
    }
  }

}
