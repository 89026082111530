import { EpisodeLineSummary } from "../NewDomain/episodeLineSummary";

export class EpisodeLineSummarySelectionModel {

    //Key is Episode ID, value is the line summary object itself
    selectionMap = new Map();

    constructor() {}

    clear() { this.selectionMap.clear() }

    selectLineSummary(lineSummary:EpisodeLineSummary) 
    {
        this.selectionMap.set( lineSummary.EpisodeMaster_ID, lineSummary )
    }

    deselectLineSummary(lineSummary:EpisodeLineSummary)
    {
        this.selectionMap.delete( lineSummary.EpisodeMaster_ID )
    }

    isSelected(lineSummary:EpisodeLineSummary) : boolean
    {
        return this.selectionMap.has( lineSummary.EpisodeMaster_ID )
    }

    hasAnySelected() : boolean
    {
        return this.selectionMap.size > 0
    }

    //This method is necessary because the data on the line summary might be different when the page changes than when it was first retrieved, so those changes
    //need to passed to the selection model as well
    updateSelectionModelWithLineSummary(lineSummary:EpisodeLineSummary)
    {
        let existingLineSummary:EpisodeLineSummary|undefined = this.selectionMap.get(lineSummary.EpisodeMaster_ID)
        
        if (existingLineSummary != undefined)
            existingLineSummary = lineSummary
    }

    updateSelectionModelWithLineSummaries(lineSummaries:EpisodeLineSummary[])
    {
        if (this.selectionMap.size > 0)
        {
            for (let i = 0; i < lineSummaries.length; ++i)
                this.updateSelectionModelWithLineSummary(lineSummaries[i])
        }
    }

    getSelected() : EpisodeLineSummary[]
    {
        return Array.from(this.selectionMap.values())
    }
}