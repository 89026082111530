import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ApiService } from '../api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import {FormGroupDirective} from '@angular/forms'
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelEpisodeModalComponent } from './cancel-episode-modal/cancel-episode-modal.component';
import { SanitizeMedicareIdInput } from '../utility/medicareId';
import { DuplicateEpisodeError } from '../json-mappings/duplicateEpisodeError';
import { Practice } from '../NewDomain/Practice';
import { Physician } from '../NewDomain/Physician';
import { Bundle } from '../NewDomain/Bundle';
import { Facility } from '../NewDomain/Facility';
import { PatientEpisodePostRequest } from './patientEpisodePostRequest';
import { ValidationProblem, ValidationResult } from '../NewDomain/ValidationResult';
import { PatientEpisodeGetRequest } from './patientEpisodeGetRequest';
import { compare } from 'fast-json-patch';
import { UserAD } from '../NewDomain/UserAD';
import { FacilityQuery, PhysicianQuery } from '../apiQueryInterfaces';

export function birthDateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value) {

      let today:Date = new Date();
      let birthDate:Date = control.value;
      let age:number = today.getFullYear() - birthDate.getFullYear();
      let m:number = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      //Patient can't be under 18 or over 120
      return (age < 18 || age > 120) ? {wrongDate: control.value} : null;
    }
    else {
      return null;
    }
  };
}


@Component({
  selector: 'app-add-new-episode-page',
  templateUrl: './add-new-episode-page.component.html',
  styleUrls: ['./add-new-episode-page.component.css']
})
export class AddNewEpisodePageComponent implements OnInit {

  //See https://www.cms.gov/medicare/new-medicare-card/understanding-the-mbi-with-format.pdf
  private static medicareIdRegex =
  "^" + //New format
  "[1-9]" +
  "[AC-HJ-KMNPQRT-Y]" +
  "[\\dAC-HJ-KMNPQRT-Y]" +
  "\\d" +
  "[AC-HJ-KMNPQRT-Y]" +
  "[\\dAC-HJ-KMNPQRT-Y]" +
  "\\d" +
  "[AC-HJ-KMNPQRT-Y]" +
  "[AC-HJ-KMNPQRT-Y]" +
  "\\d" +
  "\\d" +
  "$" +

  "|" +

  "^\\d{9}[A-Z]$" //Old format

  private static minBirthdate = new Date()
  errorMessage:string = ""
  overrideDuplicateWarning:boolean = false
  submitButtonDisabled:boolean = false

  addOrUpdateEpisodeErrors: DuplicateEpisodeError[] = []
  warnings: ValidationProblem[] = []
  initialEditObject: PatientEpisodePostRequest|undefined = undefined
  user:UserAD = new UserAD()

  get isValidator(): boolean{
    return this.user.ValidatorFlag;
  }

  pageTitle: string;
  isEditMode: boolean;

  constructor(private api:ApiService, private snackBar: MatSnackBar, public dialog: MatDialog, private route: ActivatedRoute,
              private router: Router) {
  }


  ngOnInit(): void {
    this.isEditMode = this.route.snapshot.paramMap.has('episodeId');
   
    
    
    this.loadSpinner++


    this.loadSpinner++
    this.api.getPractices(
          (res) => {
            this.practices = res

            if (this.practices.length == 1)
              this.form.get("practice")?.setValue(this.practices[0].Practice_ID)

            this.loadSpinner--
          },
          (err) => {
            this.errorMessage = err.message;
            this.loadSpinner--
      })

    this.loadSpinner++
    this.api.getUserADSelf()
    .subscribe({
      next: user => {
        this.user = user;
        if (!this.user.ValidatorFlag) {
          this.form.disable();
        }
        if(!this.isEditMode){
          this.pageTitle = "Submit New Episode";
        } else if (this.isEditMode && this.user.ValidatorFlag){ 
          this.pageTitle = "Edit Episode";
        } else {
          this.pageTitle = "View Episode";
        }
        this.loadSpinner--;
      },
      error: err => {
        this.errorMessage = err.message;
        this.loadSpinner--; 
      }
    });


    this.subscriptions.push(this.form.get("practice")?.valueChanges.subscribe((value:any) => this.practiceChange(value)) as Subscription);
    this.subscriptions.push(this.form.get("physician")?.valueChanges.subscribe((value:any) => this.physicianChange(value)) as Subscription);

    //If they send an episode, and it returns a warning, if they interact with the form again, assume that it needs to be checked for duplicate errors again since there may be new values
    this.subscriptions.push(this.form.valueChanges.subscribe( (value:any) => this.overrideDuplicateWarning = false ) as Subscription);


    //Edit mode needs to fetch existing episode info and populate it.

    if (this.isEditMode) {
      this.api.getPatientAndEpisode(
          (res:PatientEpisodeGetRequest) => {
            this.form.get("practice")?.setValue(res.Practice_ID)
            this.form.get("physician")?.setValue(res.Physician_ID);
            this.form.get("procedure")?.setValue(res.Bundle_ID);
            this.form.get("procedureDesc")?.setValue(res.Procedure_Desc);
            this.form.get("acuteFacilities")?.setValue(res.Facility_ID);
            this.form.get("firstName")?.setValue(res.FirstName);
            this.form.get("lastName")?.setValue(res.LastName);

            //Date comes in the format of "1-1-2000", which is interpreted as UTC time. Need to interpret it as local time so its not shifted to match
            //Date overall needs to have time zone problems fixed
            let dob = new Date(res.DateOfBirth)
            this.form.get("dateOfBirth")?.setValue( new Date(dob.getUTCFullYear(), dob.getUTCMonth(), dob.getUTCDate()) );

            this.form.get("medicareId")?.setValue(res.MedicareID);
            this.form.get("dateOfService")?.setValue(res.DateOfService);

            let placeOfServiceRef:number = res.PlaceOfService_ID

            //Inpatient Hospital || On Campus-Outpatient Hospital
            if (placeOfServiceRef == 8 || placeOfServiceRef == 9) {
              this.form.get("placeOfService")?.setValue(placeOfServiceRef);
            } else {
              this.form.get("placeOfService")?.setValue(-1);
              this.form.get("otherPlacesOfService")?.setValue(placeOfServiceRef);
            }

            //Now that the form is set, create a copy to use to compare against the user's eventual changes to create a patch for update
            this.initialEditObject = this.createPatientEpisodePostFromForm()

            this.loadSpinner--
          },
          (err) => {
            this.errorMessage = err.message;
            this.loadSpinner--
      }, parseInt(this.route.snapshot.paramMap.get('episodeId') as string))
    } else {
      this.loadSpinner--
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  subscriptions: Subscription[] = []
  spinner = false;
  loadSpinner = 0;
  hasSubmitBeenClicked = false;
  jsonFilter = {physician: ""};
  practices:Practice[] = []


  form = new FormGroup({
    payer: new FormControl({value: 'Medicare FFS', disabled: 'true'}, Validators.required),
    physician: new FormControl(null, Validators.required),
    dateOfService: new FormControl(null, Validators.required),
    procedure: new FormControl(null, Validators.required),
    procedureDesc: new FormControl(null, Validators.required),
    placeOfService: new FormControl(null),
    otherPlacesOfService: new FormControl(null),
    acuteFacilities: new FormControl(null, Validators.required),
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    dateOfBirth: new FormControl(null, [Validators.required, birthDateValidator()]),
    medicareId: new FormControl(null, [Validators.required, Validators.pattern(AddNewEpisodePageComponent.medicareIdRegex)]),
    practice: new FormControl(null, Validators.required)
  });



  payers: string[] = ['Medicare FFS'];



  physicians:Physician[] = []

  procedures:Bundle[] = []
  acuteFacilities:Facility[] = []

  otherPlacesOfService: any[any] = [
    {val: 1, display: "Unassigned"},
    {val: 2, display: "Office"},
    {val: 3, display: "Home"},
    {val: 4, display: "Assisted Living Facility"},
    {val: 5, display: "Group Home"},
    {val: 6, display: "Off Campus-Outpatient Hospital"},
    {val: 7, display: "Urgent Care Facility"},
    {val: 10, display: "Emergency Room – Hospital"},
    {val: 11, display: "Ambulatory Surgical Center"}
  ];

  practiceChange(value?:number) {
    this.form.get("physician")?.reset();

    if (value) {
      this.loadSpinner++
      let physQuery:PhysicianQuery = { Practice_IDs: [ value ] }
      this.api.getPhysicians(
            (res) => {
              this.physicians = res
              this.loadSpinner--
            },
            (err) => {
              this.errorMessage = err.message;
              this.loadSpinner--
        }, physQuery)
    }
  }

  physicianChange(value?:number) {
    this.form.get("procedure")?.reset();

    this.form.get("acuteFacilities")?.reset();

    if (value) {
      this.loadSpinner++
      this.api.getBundles(
            (res) => {
              this.procedures = res
              this.loadSpinner--
            },
            (err) => {
              this.errorMessage = err.message;
              this.loadSpinner--
        }, value)

      this.loadSpinner++
      let facilityQuery:FacilityQuery = { Physician_IDs: [ value ] }
      this.api.getFacilities(
            (res) => {
              this.acuteFacilities = res
              this.loadSpinner--
            },
            (err) => {
              this.errorMessage = err.message;
              this.loadSpinner--
        }, facilityQuery)
    }
  }

  private createPatientEpisodePostFromForm() : PatientEpisodePostRequest {

    let retVal:PatientEpisodePostRequest =
    {
      Physician_ID: this.form.get("physician")?.value,
      PlaceOfService_ID: this.form.get("placeOfService")?.value != -1 ? this.form.get("placeOfService")?.value : this.form.get("otherPlacesOfService")?.value,
      DateOfService: this.form.get("dateOfService")?.value,
      Procedure_Desc: this.form.get("procedureDesc")?.value,
      Payer: this.form.get("payer")?.value,
      TypeOfService: "Elective",
      Bundle_ID: this.form.get("procedure")?.value,
      Facility_ID: this.form.get("acuteFacilities")?.value,

      FirstName: this.form.get("firstName")?.value,
      LastName: this.form.get("lastName")?.value,
      DateOfBirth: this.form.get("dateOfBirth")?.value,
      MedicareID: this.form.get("medicareId")?.value,
      OverrideWarnings: this.overrideDuplicateWarning
    };

    return retVal;
  }

  //Should only highlight Place of Service as red if there is no option selected, or if "Other" is selected, then a selection in the dropdown must be made
  validatePlaceOfService() : boolean {
    if (this.hasSubmitBeenClicked == true) {
      if (this.form.get('placeOfService')?.value == null || (this.form.get('placeOfService')?.value == -1 && this.form.get('otherPlacesOfService')?.value == null)) {
        return true;
      }
    }

    return false;
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.form.valid && ( (this.form.get("placeOfService")?.value && this.form.get("placeOfService")?.value != -1) || this.form.get("otherPlacesOfService")?.value )) {
      this.hasSubmitBeenClicked = false;
      this.spinner = true;

      if (this.isEditMode) {
        let episode:PatientEpisodePostRequest = this.createPatientEpisodePostFromForm();

        let patch = compare(this.initialEditObject!, episode)

        this.api.patchPatientAndEpisode(
          (resp: ValidationResult) => { //Success
              this.addOrUpdateEpisodeErrors = []

              this.snackBar.open("Episode successfully updated!", "Dismiss", {duration: 3000});
              this.router.navigate(['/patientEpisodeManager']);

            this.spinner = false;
          },
          (err) => { //Error
            if (err.status == 400)
            {
              if (err?.error?.warnings)
              {
                this.overrideDuplicateWarning = true //if user presses submit again, tell the server to ignore the warning and add/update episode anyway
                this.warnings = err.error.warnings
              }

              //Disable button for 3 seconds to avoid them accidentally clicking a second time before reading the message
              this.submitButtonDisabled = true
              setTimeout( () => { this.submitButtonDisabled = false }, 3000 )
            }
            else
              this.snackBar.open("Episode could not be submitted due to error.", "Dismiss");

            this.spinner = false;
          }, parseInt(this.route.snapshot.paramMap.get('episodeId') as string), patch
        );
      }
      else {
        this.api.postPatientAndEpisode(
          (resp: ValidationResult) => { //Success

              this.warnings = []

              this.snackBar.open("Episode successfully submitted!", "Dismiss", {duration: 3000});

              formDirective.resetForm();
              this.form.reset();
              this.form.get("payer")?.setValue("Medicare FFS")

              if (this.practices.length == 1) {
                this.form.get("practice")?.setValue(this.practices[0].Practice_ID)
              }

            this.spinner = false;
          },
          (err) => { //Error
            if (err.status == 400)
            {
              if (err?.error?.warnings)
              {
                this.overrideDuplicateWarning = true //if user presses submit again, tell the server to ignore the warning and add/update episode anyway
                this.warnings = err.error.warnings
              }

              //Disable button for 3 seconds to avoid them accidentally clicking a second time before reading the message
              this.submitButtonDisabled = true
              setTimeout( () => { this.submitButtonDisabled = false }, 3000 )
            }
            else
              this.snackBar.open("Episode could not be submitted due to error.", "Dismiss");

            this.spinner = false;
          }, this.createPatientEpisodePostFromForm()
        );
      }

    }
    else {
      this.hasSubmitBeenClicked = true;
    }
  }


  sanitizeMedicareIdInput() {
    let medicareIdControl:AbstractControl|null = this.form.get("medicareId")

    medicareIdControl?.setValue( SanitizeMedicareIdInput(medicareIdControl.value) )
  }

  openCancelEpisodeModal(){
      const dialogRef = this.dialog.open(CancelEpisodeModalComponent, {
        width: "540px",
        disableClose: true,
        data: parseInt(this.route.snapshot.paramMap.get('episodeId') as string),
        panelClass: 'popupModal',
        autoFocus: false
      });
  }
}
