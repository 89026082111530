<div style="position: relative;">
    <app-loading-spinner *ngIf="loadSpinner"></app-loading-spinner>
    <button mat-icon-button class="modal-close-button" style="position: absolute;" (click)="close()">
        <mat-icon>close</mat-icon>
    </button> 

    <div (drop)="dropHandler($event);" (dragover)="dragOverHandler($event);" style="display: flex; align-items: center; flex-direction: column; font: normal normal bold 20px/30px Roboto;">
        <mat-icon style="color: var(--accent-menuButtonInactive); font-size: 100px; width: 100px; height: 100px">cloud_upload</mat-icon>
        <div>
            <div style="padding-bottom: 20px;">DRAG AND DROP CLAIM (PDF) HERE</div>
            <div style="padding-bottom: 41px">Patient: {{episode.PatLastName}}, {{episode.PatFirstName}}<br>Surgeon: {{episode.SurgeonLastName}}</div>
        </div>
        <div style="padding-bottom: 41px">or</div>
        <div *ngIf="showError" style="color: red;">{{errorMessage}}</div>
        <div style="display: flex; align-items: center; padding-bottom: 32px;">
            <mat-form-field class="form-field-no-bottom pdf-modal-file-input" style="width: 371px; font-size: 11px;">
                <input #fileInput matInput disabled=true [value]="this.fileToUpload?.name">
            </mat-form-field>
            <input #fileInputHidden type="file" hidden="true" (change)="change($event)" accept=".pdf">
            <button mat-raised-button color="accent" style="width: 150px" (click)="fileInputHidden.click()">Select File</button>
        </div>
        <div style="display: flex; width: 400px; justify-content: space-between;">
            <button mat-button style="width: 122px" (click)="close()">Cancel</button>
            <button mat-raised-button [disabled]="!this.fileToUpload || this.fileToUpload.name == ''" color="accent" style="width: 122px" (click)="submit()">Submit</button>
        </div>
    </div>
</div>