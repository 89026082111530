import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { EpisodeDetails, EpisodeDetailsResponseDTO } from 'src/app/NewDomain/EpisodeDetails';
import { FileDeleteConfirmationComponent } from 'src/app/dialog-modals/file-delete-confirmation/file-delete-confirmation.component';

@Component({
    selector: 'zero-dollar-remit-viewer',
    templateUrl: './zero-dollar-remit-viewer.component.html',
    styleUrls: ['./zero-dollar-remit-viewer.component.css']
})

export class ZeroDollarRemitFileComponent implements OnInit {

    episodeId: any;
    zeroDollarRemitId: any;
    zeroDollarRemitFileData: any;
    episodeDetails: any;
    user: any;
    pdfDocumentData: PDFDocumentSecClaim = this.createEmptyDocument();
    wholePageLoad: number = 0;
    episodeLoadSpinner: number = 1;
    loadSpinner = 0;
    fileDeleteReason = "";

    constructor(public route: ActivatedRoute, private router: Router, private api:ApiService, public snackBar: MatSnackBar,
        public domSanitizer:DomSanitizer, public dialog: MatDialog) {

    }

    ngOnInit(): void {
        this.episodeId = this.route.snapshot.paramMap.get('episodeId');
        console.log(this.episodeId);

        this.api.getZeroDollarRemitForEpisodeId(this.episodeId, (resp) => {
            console.log(resp);
            this.zeroDollarRemitFileData = resp;
            this.api.downloadZeroDollarRemitFile(this.episodeId, (resStream) => {
                console.log(resStream);
                this.pdfDocumentData.localPDFUrl = URL.createObjectURL(resStream);
                this.pdfDocumentData.safePDFUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdfDocumentData.localPDFUrl);
                this.pdfDocumentData.loadSpinnerPDF = false;
                this.pdfDocumentData.downloadFileName = "Zero_Dollar_Remit_" + this.zeroDollarRemitFileData[0].Episode_ZeroDollarRemit_Join_ID;
                this.wholePageLoad--;
                this.episodeLoadSpinner--;
                this.loadSpinner--;
            },
            (err) => {
                console.log(err);
                this.pdfDocumentData.errorMessagePDF = err.message;
                this.pdfDocumentData.loadSpinnerPDF = false;
                this.wholePageLoad--;
                this.episodeLoadSpinner--;
                this.loadSpinner--;
            });
        },
        (err) => {
            console.log(err);
            this.snackBar.open("File data not found!", "Dismiss", {duration: 3000});
            this.wholePageLoad--;
            this.episodeLoadSpinner--;
            this.loadSpinner--;
        });

        this.api.getEpisodeDetails(
            this.episodeId,
            (epiResp: EpisodeDetailsResponseDTO) => {
              this.episodeDetails = epiResp.episodeDetails;
            },
            (error: any) => {
                console.log(error);
            }
        );

        this.api.getUserADSelf()
        .subscribe({
          next: user => {
            this.user = user;
          },
          error: err => {
            console.log(err);
          }
        });
    }

    deleteDocFile() {
        const dialogRef = this.dialog.open(FileDeleteConfirmationComponent, {
            width: "500px",
            disableClose: true,
            data: {},
            panelClass: 'popupModal',
            autoFocus: false
          });
      
          dialogRef.afterClosed().subscribe((action) => {
            console.log(action);
            if(action) {
                this.api.deleteZeroDollarRemitFile(this.zeroDollarRemitFileData[0].Episode_ZeroDollarRemit_Join_ID, this.fileDeleteReason, () => {
                    this.router.navigate(['.'], { relativeTo: this.route.parent });
                },
                (err) => {
                    console.log(err);
                });
            }
            else {
                console.log('FILE NOT DELETED');
            }
          });
    }

    createEmptyDocument():PDFDocumentSecClaim {
        return {
          localPDFUrl: "", 
          safePDFUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(""), 
          loadSpinnerPDF: false,
          errorMessagePDF: "",
          downloadFileName: "file"
        }
    }
}

export interface PDFDocumentSecClaim {
    localPDFUrl: string, 
    safePDFUrl: SafeResourceUrl, 
    loadSpinnerPDF: boolean,
    errorMessagePDF: string,
    downloadFileName: string
}