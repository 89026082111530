<div class="page-wrapper">
    <div class="page-title">Dashboard</div>
    <mat-divider class="page-divider"></mat-divider>
    <div style="position: relative">
        <div *ngIf="!isValidator" style="margin-top: 140px;" fxLayout="row" fxLayoutAlign="center">
            <div style="width: 815px;" fxLayout="row" fxLayoutAlign="space-between">
                <a routerLink="/addNewEpisode"  *ngIf="isValidator" style="display: flex; flex-direction: column;">
                    <mat-icon style="margin-left: 65px;" class="icon">addchart</mat-icon>
                    <div class="text">Add New Episode</div>
                </a>
                <a routerLink="/patientEpisodeManager" style="display: flex; flex-direction: column">
                    <mat-icon style="margin-left: 120px;" class="icon">assignment_ind</mat-icon>
                    <div class="text">Patient Episode Manager</div>
                </a>
            </div>
        </div>
        <div *ngIf="isValidator">
            <div style="margin-top: 1%; margin-left: 3%; margin-bottom: -2%;">
                <mat-form-field class="filterDropdowns form-field-no-bottom filterSpacer">
                    <mat-label style="font-weight: 600;">Practice</mat-label>
                    <mat-select [(ngModel)]="practiceId" (selectionChange)="practiceChange($event.value)">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let practice of this.practices" [value]="practice.Practice_ID">
                            {{practice.Practice_Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div style="margin: 20px;" *ngIf="isValidator">
            <div style="padding: 30px;">
                <div style="display: inline-block; border: 1px solid gray; padding: 30px; margin-right: 20px; width: 30vw; height: 24vw;">
                    <div style="text-align: center;">Episode Status Count</div>
                    <div style="margin-top: 20px;">
                        <table style="width: 100%;">
                            <tr class="text-align-left">
                                <th class="padding-5" style="background-color: #CBE8CD; width: 60%;">
                                    <span>Status</span>
                                </th>
                                <th class="padding-5" style="background-color: #CBE8CD; width: 20%;">
                                    <span>Count</span>
                                </th>
                                <th class="padding-5" style="background-color: #CBE8CD; width: 20%;">
                                    <span>%</span>
                                </th>
                            </tr>
                            <tr class="text-align-left cursor-pointer" (click)="StatusSelect('readyForClaim', 4)">
                                <th class="font-light" style="width: 60%;">Ready for Claim</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.ReadyForClaimRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentReadyClaim}}</th>
                            </tr>
                            <tr class="text-align-left cursor-pointer" (click)="StatusSelect('denied', 12)">
                                <th class="font-light" style="width: 60%;">Denied Claim 
                                    <span style="font-size: 12px;">(Bundle at Risk: {{dashboardStatusDetails?.DeniedFilesRecords?.BundleAtRiskRecord}}, Bundle not at Risk: {{dashboardStatusDetails?.DeniedFilesRecords?.BundleNotAtRiskRecord}})</span></th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.DeniedFilesRecords?.TotalDenialRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentDeniedFiles}}</th>
                            </tr>
                            <tr class="text-align-left font-light cursor-pointer" (click)="StatusSelect('processingClaim', 5)">
                                <th class="font-light" style="width: 60%;">Processing Claim</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.ProcessingClaimRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentProssClaim}}</th>
                            </tr>
                            <tr class="text-align-left font-light cursor-pointer" (click)="StatusSelect('eraPaymentReceived', 6)">
                                <th class="font-light" style="width: 60%;">ERA Recieved (28 days)</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.EraReceivedRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentERARc}}</th>
                            </tr>
                            <tr class="text-align-left font-light cursor-pointer" (click)="StatusSelect('surgeryScheduled', 11)">
                                <th class="font-light" style="width: 60%;">Surgery Scheduled</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.SurgeryScheduledRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentSurgerySch}}</th>
                            </tr>
                            <tr class="text-align-left font-light cursor-pointer" (click)="StatusSelect('submissionFailed', 2)">
                                <th class="font-light" style="width: 60%;">Submission Failed (28 Days)</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.SubmissionFailedRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentSubmissionFailed}}</th>
                            </tr>
                            <tr class="text-align-left font-light cursor-pointer" (click)="StatusSelect('notEligible', 1)">
                                <th class="font-light" style="width: 60%;">Not Eligible (28 Days)</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.NotEligibleRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentNotEligible}}</th>
                            </tr>
                            <tr class="text-align-left font-light cursor-pointer" (click)="StatusSelect('onHold', 10)">
                                <th class="font-light" style="width: 60%;">On Hold</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.OnHoldRecords}}</th>
                                <th class="font-light" style="width: 20%;">{{dashboardStatusDetails?.PercentOnHold}}</th>
                            </tr>
                            <tr class="text-align-left">
                                <th class="padding-5" style="width: 60%;">Total</th>
                                <th class="padding-5" style="width: 20%;">{{dashboardStatusDetails?.TotalCount}}</th>
                                <th class="padding-5" style="width: 20%;">100</th>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display: inline-block; border: 1px solid gray; padding: 30px; width: 30vw; height: 24vw;">
                    <div style="text-align: center;">Episode Status</div>
                    <div>
                        <div *ngIf="displayPieChart" style="width: 90%; height: 90%; margin-left: 6%; margin-top: -5%;">
                            <canvas baseChart
                                [datasets]="pieChartData"
                                [labels]="pieChartLabels"
                                [type]="pieChartType"
                                [options]="pieChartOptions"
                            ></canvas>
                        </div>
                    </div>
                </div>
            </div>           
        </div>
    </div>
</div>