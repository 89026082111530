import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AddNewEpisodePageComponent } from './add-new-episode-page/add-new-episode-page.component'
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component'
import { FilesPageComponent, filesRoute } from './files-page/files-page.component';
import { PatientEpisodeManagerPageComponent, patientEpisodeManagerRoute } from './patient-episode-manager-page/patient-episode-manager-page.component';
import { ValidateClaimFileComponent } from './patient-episode-manager-page/validate-claim-file/validate-claim-file.component';
import { SupportPageComponent } from './support-page/support-page.component';
import { EpisodeDetailsComponent } from './patient-episode-manager-page/episode-details/episode-details.component';
import { AdministrationPageComponent } from './administration-page/administration-page.component';
import { EraViewerForEpisodeComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-episode/era-viewer-for-episode.component';
import { EraViewerForAchComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-ach/era-viewer-for-ach.component';
import { EraViewerForEraComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-era/era-viewer-for-era.component';
import { ValidatorGuard } from './shared/validator.guard';
import { EraViewerForDenialComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-denial/era-viewer-for-denial.component';
import { DenialViewerComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-denial/denial-viewer.component';
import { AdditionalDocViewerModalComponent } from './patient-episode-manager-page/era-viewer/additional-doc-viewer/additional-doc-viewer-modal.component';
import { SecondaryClaimViewerComponent } from './patient-episode-manager-page/era-viewer/era-viewer-for-secondary-claim/era-viewer-for-secondary-claim.component';
import { ZeroDollarRemitFileComponent } from './patient-episode-manager-page/era-viewer/zero-dollar-remit-viewer/zero-dollar-remit-viewer.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardPageComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'addNewEpisode',
    component: AddNewEpisodePageComponent,
    canActivate: [
      MsalGuard,
      ValidatorGuard
    ]
  },
  {
    path: filesRoute,
    component: FilesPageComponent,
    children: [
      {
        path: 'validateClaim/:episodeId',
        component: ValidateClaimFileComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ERAViewerForEpisode/:episodeId',
        component: EraViewerForEpisodeComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ERAViewerForACH/:achId',
        component: EraViewerForAchComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ERAViewerForERA/:eraId',
        component: EraViewerForEraComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ERAViewerForDenial/:denialId',
        component: EraViewerForDenialComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ViewerForAdditionalDoc/:addDocId',
        component: AdditionalDocViewerModalComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ViewerForSecondaryClaim/:episodeId',
        component: SecondaryClaimViewerComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ZeroDollarRemitFileViewer/:episodeId',
        component: ZeroDollarRemitFileComponent,
        canActivate: [
          MsalGuard
        ]
      }
    ],
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: patientEpisodeManagerRoute,
    component: PatientEpisodeManagerPageComponent,
    children: [
      {
        path: 'validateClaim/:episodeId',
        component: ValidateClaimFileComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ERAViewerForEpisode/:episodeId',
        component: EraViewerForEpisodeComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'editEpisode/:episodeId',
        component: AddNewEpisodePageComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'episodeDetails/:episodeId',
        component: EpisodeDetailsComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ERAViewerForDenial/:episodeId',
        component: EraViewerForDenialComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ViewerForAdditionalDoc/:addDocId',
        component: AdditionalDocViewerModalComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ViewerForSecondaryClaim/:episodeId',
        component: SecondaryClaimViewerComponent,
        canActivate: [
          MsalGuard
        ]
      },
      {
        path: 'ZeroDollarRemitFileViewer/:episodeId',
        component: ZeroDollarRemitFileComponent,
        canActivate: [
          MsalGuard
        ]
      }
    ],
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'support',
    component: SupportPageComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'administration',
    component: AdministrationPageComponent,
    canActivate: [
      MsalGuard
    ]
  },
  { path: '**', redirectTo: '/dashboard' }  // Wildcard route for a 404 page
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: !isIframe ? 'enabled' : 'disabled' }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
