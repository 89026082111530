import {Pipe, PipeTransform} from "@angular/core";
import { EpisodeStatus } from "src/app/NewDomain/EpisodeStatus";

@Pipe({
name: 'visibleEpisodeStatus'
})
export class VisibleEpisodeStatusPipe implements PipeTransform{
  transform(episodeStatuses: EpisodeStatus[], ...args: any[]): EpisodeStatus[] {
    return episodeStatuses ? episodeStatuses.filter(episodeStatus => episodeStatus.EpisodeStatus_isVisible) : [];
  }

}
