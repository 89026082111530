<div class="page-wrapper" style="height: 100%; max-width: calc(100vw - 289px);">
    <div style="display: flex; height: 100%; position: relative">
        <app-loading-spinner *ngIf="wholePageLoad > 0"></app-loading-spinner>

        <div style="display:flex; flex-direction: column;" [ngClass]="wholePageLoad ? 'loadingTrue' : 'loadingFalse'">

            <div class="page-title">Secondary Claim Viewer</div>
            <mat-divider class="page-divider" style="margin-bottom: 15.5px;"></mat-divider>

            <div style="display: flex; flex-direction: column; position: relative;">
                <app-loading-spinner *ngIf="episodeLoadSpinner > 0"></app-loading-spinner>

                <div>           
                    <div class="section-title" style="margin-top: 15px;">Related Episodes</div>
                        <div *ngIf="episodeDetails">
                            {{episodeDetails.PhysLastName}}, {{episodeDetails.PhysFirstName}}<br>
                            {{episodeDetails.DateOfService | date:"MM/dd/yy":"UTC"}}<br>
                            {{episodeDetails.PatLastName}}, {{episodeDetails.PatFirstName}}, {{episodeDetails.PatDOB | date:"MM/dd/yy":"UTC"}}<br>
                            {{episodeDetails.MedicareID}}
                        </div>
                </div>
            </div>

            <div style="display: flex; flex-direction: column; position: relative; margin-top: 50px;">
                <div>           
                        <div *ngIf="secondaryClaimFileData && secondaryClaimFileData.length > 0">
                            <div><span style="font-weight: bold">File Name</span>: {{pdfDocumentData.downloadFileName}} </div>
                            <div><span style="font-weight: bold">Added Date</span>: {{secondaryClaimFileData[0].AddedDate | date:"MM/dd/yy":"UTC"}} </div>
                            <div><span style="font-weight: bold">Added By</span>: {{secondaryClaimFileData[0].AddedByName}} </div>
                            <div><span style="font-weight: bold">Note</span>: {{secondaryClaimFileData[0].Notes}} </div>
                        </div>
                </div>
            </div>

        </div>

        <div style="display:flex; flex-direction: column; width: 100%">

            <div style="display: flex; flex: 1; position: relative; margin-top: 48px;">
                    
                <div style="position: relative; flex: 1; display: flex; flex-direction: column">
                    <app-loading-spinner *ngIf="pdfDocumentData?.loadSpinnerPDF"></app-loading-spinner>
        
                    <div *ngIf="!pdfDocumentData?.errorMessagePDF; else pdfError" style="width: 100%; flex: 1">
                        <embed [src]="pdfDocumentData?.safePDFUrl" type='application/pdf' style="width: 100%; height: 100%" />
                    </div>
        
                    <div style="display: flex; margin-top: 20px;">
                        <div style="flex: 1">
                            <app-back-button></app-back-button>
                        </div>
        
                        <div *ngIf="pdfDocumentData.localPDFUrl != ''" style="flex: 1; display: flex; justify-content: center;">
                            <a [href]="pdfDocumentData?.safePDFUrl" [download]="pdfDocumentData.downloadFileName" style="color: inherit; position: relative;  width: 48px;">
                                <mat-icon style="font-size: 48px; width: 48px; margin-bottom: 25px">file_download</mat-icon>
                            </a>
                        </div>

                        <div style="flex: 1">
                            <div style="margin-left: 15%;">
                                <mat-checkbox [checked]="secondaryClaimFileData[0].IsReviewed" style="margin-right: 10px" (change)="esReviewedChecked($event)"></mat-checkbox>
                                <span>Reviewed</span>
                            </div>
                            <div *ngIf="this.user.ValidatorFlag" style="margin-left: 15%; margin-top: 5px;">
                                <span>Delete File</span>
                                <div>
                                    <span>Reason: </span>
                                    <mat-form-field class="form-field-no-bottom pdf-modal-file-input" style=" font-size: 11px;">
                                        <mat-label><i>Type delete reason here</i></mat-label>
                                        <input matInput [(ngModel)]="fileDeleteReason"/>
                                    </mat-form-field>
                                    <button mat-raised-button color="accent" style="width: 70px; line-height: 25px; top: -3px; margin-left: 10px;" [disabled]="fileDeleteReason == ''" (click)="deleteDocFile()">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <ng-template #pdfError>
                        <div style="flex: 1" height="100%" width="100%">
                            Could not download PDF<br><br>
        
                            Server Error: {{pdfDocumentData?.errorMessagePDF}}
                        </div>
                    </ng-template>
                </div>
            </div>

            <ng-template #error><app-server-error [errorMessage]="errorMessage"></app-server-error></ng-template>

        </div>


    </div>
  
</div>